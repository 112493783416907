// import axios from 'axios';
// import { AuthInterceptor } from "./AuthInterceptor";

// const Base = 'https://dev.app.barista.tribalworldwide.gt/admin';
// const BaseLogin = 'https://dev.app.barista.tribalworldwide.gt/admin/registration/v1';

const Base = process.env.REACT_APP_BASE;
const BaseLogin = process.env.REACT_APP_BASE_LOGIN;


const fetchSinTocken = async (endpoint, data) => {
  const url = `${BaseLogin}/${endpoint}`;
  //let raw = data;
  let raw = JSON.stringify(data);
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },

      body: raw,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": 'OPTIONS, PUT, DELETE, GET, POST',
      "Access-Control-Request-Method": '*'
    });
    return await resp.json();
  } catch (err) {
    return err.json();
  }
};

const baseUrlSchedules = `${ Base }/schedules/v1`;
const fetchSchedules = (endpoint, data, method = 'GET', token) => {
  const url = `${baseUrlSchedules}/${endpoint}`;
  //let raw = data;
  let raw = JSON.stringify(data);
  return fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${localStorage.getItem('sessionId')}`,
    },
    body: raw,
    referrerPolicy: "no-referrer",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": 'POST, PUT, DELETE, GET, OPTIONS',
    "Access-Control-Request-Method": '*'
  });
};

const baseUrlCategories = `${ Base }/admin`;
const fetchCategories = (endpoint, data, token) => {
  const url = `${baseUrlCategories}/${endpoint}`;
  let raw = data;
  // let raw = JSON.stringify(data);
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'authorization': `Bearer ${localStorage.getItem('sessionId')}`,
    },
    body: raw,
    referrerPolicy: "no-referrer",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": 'POST, PUT, DELETE, GET, OPTIONS',
    "Access-Control-Request-Method": '*'
  });
};

const baseUrlServices = `${ Base }`;

const fetchService = async (endpoint, data, token) => {
  const url = `${baseUrlServices}${endpoint}`;
  // Data debe venir como texto con JSON.stringify(data)
  const raw = data;
  try {
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem('sessionId')}`,
      },
      body: raw,
      referrerPolicy: "no-referrer",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": 'POST, PUT, DELETE, GET, OPTIONS',
      "Access-Control-Request-Method": '*'
    });
    return await resp.json();
  } catch (err) {
    return err.json();
  }

};

export { fetchSinTocken, fetchSchedules, fetchCategories, fetchService };
