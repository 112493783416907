import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';

export const DeleteModal = (props) => {
  // Loading state for buttons
  // const { loading } = useSelector((state) => state.ui);
  // console.log(loading);

  const {
    id,
    show,
    setShow,
    title,
    resume,
    handleDelete,
    withContent,
    isLoading,
  } = props;

  const onClose = () => {
    setShow(false);
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      className="basic-modal modal--admin"
      size="mini"
    >
      <Modal.Header>
        <h3>{title}</h3>
      </Modal.Header>
      <Modal.Content>
        <p>{resume}</p>
      </Modal.Content>
      <Modal.Actions>
        {withContent ? (
          <button className="cta cta--small cta--secondary" onClick={onClose}>
            Entendido
          </button>
        ) : (
          <>
            <button className="cta cta--secondary-plain m-0" onClick={onClose}>
              Cancelar
            </button>
            <Button
              loading={isLoading}
              onClick={() => handleDelete(id)}
              className="cta cta--secondary m-0"
            >
              Eliminar
            </Button>
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};
