import { revalidateKey } from './key';
import { types } from '../types/types';
import { toast } from 'react-toastify';
import { fetchService } from '../helpers/fetch';
import { prepareUsers } from '../helpers/prepareUsers';
import { prepareRoles } from '../helpers/prepareRoles';
import { finishLoading, startLoading, full_loading } from './ui';

// Obtener todos los usuarios
export const startGetAllUsers = (raw, token) => {
  return async (dispatch) => {
    // dispatch(full_loading(true));

    const body = await fetchService('/user-admin/v1/all', raw, token)
      .then((res) => {
        const users = prepareUsers(res.responseContent);
        dispatch(getAllUsers(users));
      })
      .catch((err) => {
        console.log(err);
      });
      // dispatch(full_loading(false));
  };
};

export const getAllUsers = (users) => {
  return {
    type: types.usersAll,
    payload: users,
  };
};

// Crear usuario
export const createUser = (userData, token, user) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/user-admin/v1/create', userData, token, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(reduxRequestUser(res));
        dispatch(reduxCreateUser(user?.requestContent?.userAdmin));
        
        res?.info?.type === 'success' 
        ? toast.success(res?.info?.message || 'Usuario creado.')
        : toast.error(res?.info?.message || 'Usuario no creado.');
      })
      .catch((err) => {
        toast.error(err.info.message || 'No se pudo crear el usuario.');
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(reduxCreateUser(err));
        dispatch(finishLoading());
      });
  };
};

export const reduxCreateUser = (user) => {
  return {
    type: types.userCreated,
    payload: user,
  };
};

// Actualizar usuario
export const updateUser = (userData, token, user) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/user-admin/v1/update', userData, token, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(reduxRequestUser(res));
        dispatch(reduxUpdateUser(user?.requestContent?.userAdmin));

        res?.info?.type === 'success' 
        ? toast.success(res?.info?.message || 'Usuario actualizado.')
        : toast.error(res?.info?.message || 'Usuario no actualizado.');
      })
      .catch((err) => {
        toast.error(err?.info?.message || 'No se pudo actualizar el usuario.');
        dispatch(revalidateKey(err?.info?.sessionId));
        dispatch(reduxUpdateUser(err));
        dispatch(finishLoading());
      });
  };
};

export const reduxUpdateUser = (user) => {
  return {
    type: types.userUpdated,
    payload: user,
  };
};

// Eliminar usuario
export const deleteUser = (userData, token, user) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/user-admin/v1/delete', userData, token, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(reduxRequestUser(res));
        dispatch(reduxDeleteUser(user));
        
        res?.info?.type === 'success' 
        ? toast.success(res?.info?.message || 'Usuario eliminado.')
        : toast.error(res?.info?.message || 'Usuario no eliminado.');
      })
      .catch((err) => {
        toast.error(err.info.message || 'No se pudo eliminar el usuario.');
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(reduxDeleteUser(err));
        dispatch(finishLoading());
      });
  };
};

const reduxDeleteUser = (user) => {
  return {
    type: types.userDeleted,
    payload: user,
  };
};

export const reduxRequestUser = (res) => {
  return {
    type: types.userRequest,
    payload: res,
  };
};

// Obtener roles
export const startGetAllRoles = (raw, token) => {
  return async (dispatch) => {
    const body = await fetchService('/user-admin/v1/roles/all', raw, token)
      .then((res) => {
        const roles = prepareRoles(res.responseContent);
        dispatch(getAllRoles(roles));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAllRoles = (roles) => {
  return {
    type: types.rolesAll,
    payload: roles,
  };
};