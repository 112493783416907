export const types = {
  // UI
  full_loading: '[UI] Variable to show the full loading',
  uiStartLoading: '[UI] Start loading',
  uiFinishLoading: '[UI] Finish loading',

  // Login
  authLogin: '[auth] Login',
  authLogout: '[auth] Logout',
  authChecking: '[auth] Checking login state',
  authCheckingFinish: '[auth] Checking finish login state',
  authStartLogin: '[auth] Start login',

  // SessionId
  uiKey: '[UI] Key',

  // Pass
  passForgot: '[pass] Forgot Password',

  // Schedules
  scheduleAll: '[schedule] Get all schedule',
  scheduleCreate: '[schedule] Create schedule',
  scheduleUpdate: '[schedule] Update schedule',
  scheduleDelete: '[schedule] Delete schedule',

  scheduleAll: '[schedule] Get all schedule',
  scheduleCreated: '[schedule] Create schedule',
  scheduleUpdated: '[schedule] Update schedule',
  scheduleDeleted: '[schedule] Delete schedule',
  scheduleRequest: '[schedule] Request Create, update, schedule',

  // Categories
  categoryAll: '[category] Get all categories',
  categoryCreate: '[category] Create new category',
  categoryCreateSuccess: '[category] Success New Create category',
  categoryCreateFailed: '[category] Success New Create Error',
  categoryById: '[category] Category by ID',
  categoryDelete: '[category] Delete category',
  categoryUpdated: '[categoryUpdated] update store',
  categoryRequest: '[Request] Create, update, delete, category',

  // Group category
  groupCategoryAll: '[groupCat] Get all categories',
  groupCategoryCreate: '[groupCat] Create new group',
  groupCategoryById: '[groupCat] Get group by ID',
  groupCategoryUpdate: '[groupCat] Update group by ID',
  groupCategoryDelete: '[groupCat] Delete group by ID',

  // Condiments
  condimentAll: '[condiment] Get all condiements',
  condimentById: '[condiment] condiment by ID',
  condimentCreated: '[condiment] Create new condiment',
  condimentUpdated: '[condiment] Update condiment',
  condimentDeleted: '[condiment] Delete condiment',
  condimentRequest: '[condiment] Request Create, update, delete condiment',

  /********************** INICIO **********************/
  // Products
  productAll: '[product] Get all products', // Eliminar
  productsAll: '[product] get all products',
  productByCategories: '[product] get product by categories',
  productById: '[product] get product by id',
  productCreated: '[product] create product',
  productUpdated: '[product] update product',
  productDeleted: '[product] delete product',
  productRequest: '[product] Request Create, update, product',

  // ProductSizes
  productSizesAll: '[productSizes] get all product sizes',
  productSizeById: '[productSize] get product size by ID',
  productSizeCreated: '[productSize] create product size',
  productSizeUpdated: '[productSize] update product size',
  productSizeDeleted: '[productSize] delete product size',
  productSizeRequest: '[productSize] Request Create, update, product size',

  // SubProductGroup
  groupProductAll: '[groupProd] Get all products', // Eliminar
  subProductGroupsAll: '[subProductGroup] Get all sub-product groups',
  subProductGroupById: '[subProductGroup] Get sub-product groups by ID',
  subProductGroupCreated: '[subProductGroup] New sub-product groups',
  subProductGroupUpdated: '[subProductGroup] Update sub-product groups',
  subProductGroupDeleted: '[subProductGroup] Delete sub-product groups',
  subProductGroupRequest: '[subProductGroup] Request Create, update, delete sub-product groups',

  // VersionGroup (Products)
  versionGroupsAll: '[versionGroup] Get all version groups of products',
  versionGroupById: '[versionGroup] Get version group of product by ID',
  versionGroupCreated: '[versionGroup] New version group of products',
  versionGroupUpdated: '[versionGroup] Update version group of products',
  versionGroupDeleted: '[versionGroup] Delete version group of products',
  versionGroupRequest: '[versionGroup] Request Create, update, delete version group of products',

  /********************** FIN **********************/

  // Group Condiments
  groupCondimentAll: '[groupCondiments] Get all group condiments',
  groupCondimentById: '[groupCondiments] Get group condiments by ID',
  groupCondimentCreated: '[groupCondiments] Create new group condiments',
  groupCondimentUpdated: '[groupCondiments] Update group condiments by ID',
  groupCondimentDeleted: '[groupCondiments] Delete group condiment by ID',
  groupCondimentRequest: '[groupCondiments] Request Create, update, delete group condiments',
  
  // Users
  usersAll: '[user] get all users',
  userById: '[user] Get user by ID',
  userCreated: '[user] new user',
  userUpdated: '[user] update user',
  userDeleted: '[user] Delete user',
  userRequest: '[user] Request Create, update, delete user',

  // Roles
  rolesAll: '[groupRolesAll] get all roles',

  // Store
  storeRequest: '[Request] Create, update, delete, distacne/store/storeType',
  // Distance
  distance: '[distance] get distance',
  distanceUpdated: '[distanceUpdated] ',
  // Store
  storesAll: '[storesAll] get all stores ',
  storeById: '[storeById] get by id',
  productCreated: '[storeCreated] create store',
  storeUpdated: '[storeUpdated] update store',
  storeDeleted: '[storeDeleted] delete store',
  // StoreType
  storeTypesAll: '[storeTypesAll] get all storesType',
  storeTypeById: '[storeTypeById] get by id',
  storeTypeCreated: '[storeTypeCreated] create storeType',
  storeTypeUpdated: '[storeTypeUpdated] update storeType',
  storeTypeDeleted: '[storeTypeDeleted] delete storeType',
  // StoreSchedules
  storeSchedulesAll: '[storeSchedulesAll] get all storeSchedulesAll',
  storeSchedulById: '[storeSchedulById] get by id',
  storeSchedulCreated: '[storeSchedulCreated] create storeSchedulCreated',
  storeSchedulUpdated: '[storeSchedulUpdated] update storeSchedulUpdated',
  storeSchedulDeleted: '[storeSchedulDeleted] delete storeSchedulDeleted',
};