import { revalidateKey } from './key';
import { types } from '../types/types';
import { toast } from 'react-toastify';
import { fetchService } from '../helpers/fetch';
import { finishLoading, startLoading } from './ui';
import { prepareCondiments } from '../helpers/prepareCondiments';

// Get all condiments list
export const startGetAllCondiments = (data, token) => {
  return async (dispatch) => {
    const body = fetchService('/condiments/v1/all', data, token)
      .then((res) => {
        const condiments = prepareCondiments(res.responseContent);
        dispatch(getAllCondiments(condiments));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAllCondiments = (condiments) => {
  return {
    type: types.condimentAll,
    payload: condiments,
  };
};

// Create condiment
export const createNewCondiment = (data, token) => {
  return async (dispatch) => {
    // console.log(data);
    dispatch(startLoading());
    const body = fetchService('/condiments/v1/create', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(createCondiment(res));
        dispatch(requestCondiment(res));
        res?.info?.type == 'success' 
        ? toast.success(res?.info?.message || 'Condimento creado con exito.')
        : toast.error(res?.info?.message || 'Ocurrio un error al crear el condimento.');
      })
      .catch((err) => {
        dispatch(finishLoading());
        dispatch(requestCondiment(err));
        dispatch(revalidateKey(err.info.sessionId));
        // dispatch(condimentFailed(category.creation));
        toast.error('Ocurrio un error al crear el condimento.');
      });
  };
};

export const createCondiment = (condiment) => {
  return {
    type: types.condimentCreated,
    payload: condiment,
  };
};

// Get condiment by id

export const condimentById = (data, token) => {
  return async (dispatch) => {
    const body = fetchService('/condiments/v1/id', data, token)
      .then((res) => {
        dispatch(revalidateKey(res.info.sessionId));
        dispatch(getCondimentById(res.responseContent));
      })
      .catch((err) => {
        dispatch(revalidateKey(err.info.sessionId));
      });
  };
};

export const getCondimentById = (condiment) => {
  return {
    type: types.condimentById,
    payload: condiment,
  };
};

// Update condiment

export const startUpdateCondiment = (data, token) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/condiments/v1/update', data, token)
      .then((res) => {
        // console.log(res);
        dispatch(finishLoading());
        dispatch(revalidateKey(res.info.sessionId));
        dispatch(updatedCondiment(res));
        dispatch(requestCondiment(res));
        res?.info?.type == 'success' 
        ? toast.success(res?.info?.message || 'Condimento actualizado con exito.')
        : toast.error(res?.info?.message || 'Ocurrio un error al actualizar el condimento.');
      })
      .catch((err) => {
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(requestCondiment(err));
        dispatch(finishLoading());
      });
  };
};

export const updatedCondiment = (condiment) => {
  return {
    type: types.condimentUpdated,
    payload: condiment,
  };
};

// Delete condiment

export const startDeleteCondiment = (data, token, condiment) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/condiments/v1/delete', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(res.info.sessionId));
        dispatch(deleteCondiment(condiment));
        dispatch(requestCondiment(res));
        res?.info?.type == 'success' 
        ? toast.success('Condimento eliminado')
        : toast.error(res?.info?.message || 'Ocurrio un error al eliminar el condimento.');
      })
      .catch((err) => {
        toast.error(err?.info?.message || 'Ocurrio un error al eliminar el condimento.');
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(finishLoading());
      });
  };
};

export const deleteCondiment = (condiment) => {
  return {
    type: types.condimentDeleted,
    payload: condiment,
  };
};

export const requestCondiment = (condiment) => {
  return {
    type: types.condimentRequest,
    payload: condiment,
  };
};