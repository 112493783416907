import { revalidateKey } from './key';
import { types } from '../types/types';
import { toast } from 'react-toastify';
import { fetchService } from '../helpers/fetch';
import { finishLoading, startLoading } from './ui';
import { prepareStores } from '../helpers/prepareStores';

/*********************** GET ***********************/
// Obtener stores
export const storeGetAll = (data, token) => {
  return async (dispatch) => {
    const body = await fetchService('/stores/v1/all', data, token)
      .then((res) => {
        const stores = prepareStores(res.responseContent);
        dispatch(reduxStoreGetAll(stores));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.err(err);
      });
  };
};

export const reduxStoreGetAll = (stores) => {
  return {
    type: types.storesAll,
    payload: stores,
  };
};

// Obtener stores types
export const storeTypeGetAll = (data, token) => {
  return async (dispatch) => {
    const body = await fetchService('/storeTypes/v1/all', data, token)
      .then((res) => {
        const storeType = res.responseContent;
        dispatch(reduxStoreTypeGetAll(storeType));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.err(err);
      });
  };
};

export const reduxStoreTypeGetAll = (storeType) => {
  return {
    type: types.storeTypesAll,
    payload: storeType,
  };
};

// Obtener stores schedules
export const storeSchedulesGetAll = (data, token) => {
  return async (dispatch) => {
    const body = await fetchService('/storeSchedules/v1/all', data, token)
      .then((res) => {
        const storeSchedules = res.responseContent;
        dispatch(reduxStoreSchedulesGetAll(storeSchedules));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.err(err);
      });
  };
};

export const reduxStoreSchedulesGetAll = (storeSchedules) => {
  return {
    type: types.storeSchedulesAll,
    payload: storeSchedules,
  };
};

/*********************** CREATE ***********************/
// Crear store type
export const createStore = (storeData, token, store) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = await fetchService('/stores/v1/create', storeData, store, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(reduxRequestStore(res));
        dispatch(reduxCreateStore(store?.requestContent?.store));
        toast.success(res?.info?.message || 'Ubicación creada.');

      })
      .catch((err) => {
        toast.error(err?.info?.message || 'No se pudo crear la ubicación.');
        dispatch(revalidateKey(err?.info?.sessionId));
        dispatch(reduxCreateStore(err));
        dispatch(finishLoading());
      });
  };
}

export const reduxCreateStore = (store) => {
  return {
    type: types.productCreated,
    payload: store,
  };
};

export const createStoreType = (storeTypeData, token, storeType) => {
  return (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/storeTypes/v1/create', storeTypeData, storeType, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(reduxRequestStore(res));
        dispatch(reduxCreateStoreType(storeType?.requestContent?.storeType));
        toast.success(res?.info?.message || 'Ubicación creada.');

      })
      .catch((err) => {
        toast.error(err?.info?.message || 'No se pudo crear la ubicación.');
        dispatch(revalidateKey(err?.info?.sessionId));
        dispatch(reduxCreateStoreType(err));
        dispatch(finishLoading());
      });
  };
}

export const reduxCreateStoreType = (storeType) => {
  return {
    type: types.storeTypeCreated,
    payload: storeType,
  };
};

/*********************** UPDATE ***********************/
// Actualizar store
export const updateStore = (storeData, token, store) => {
  return (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/stores/v1/update', storeData, token, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(reduxRequestStore(res));
        dispatch(reduxUpdateStore(store?.requestContent?.store));
        toast.success(res?.info?.message || 'Ubicación actualizada.');

      })
      .catch((err) => {
        toast.error(err?.info?.message || 'No se pudo actualizar la ubicación.');
        dispatch(revalidateKey(err?.info?.sessionId));
        dispatch(reduxUpdateStore(err));
        dispatch(finishLoading());
      });
  };
};

export const reduxUpdateStore = (store) => {
  return {
    type: types.storeUpdated,
    payload: store,
  };
};

// Actualizar store type
export const updateStoreType = (storeTypeData, token, storeType) => {
  return (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/storeTypes/v1/update', storeTypeData, storeType, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(reduxRequestStore(res));
        dispatch(reduxUpdateStoreType(storeType?.requestContent?.storeType));
        toast.success(res?.info?.message || 'Ubicación actualizada.');

      })
      .catch((err) => {
        toast.error(err?.info?.message || 'No se pudo actualizar la ubicación.');
        dispatch(revalidateKey(err?.info?.sessionId));
        dispatch(reduxUpdateStoreType(err));
        dispatch(finishLoading());
      });
  };
}

export const reduxUpdateStoreType = (storeType) => {
  return {
    type: types.storeTypeUpdated,
    payload: storeType,
  };
};

/*********************** DELETE ***********************/
// Eliminar store type
export const deleteStore = (storeData, token, store) => {
  return (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/stores/v1/delete', storeData, store, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(reduxRequestStore(res));
        dispatch(reduxDeleteStore(store));
        toast.success(res?.info?.message || 'Ubicación eliminada.');

      })
      .catch((err) => {
        toast.error(err?.info?.message || 'No se pudo eliminar la ubicación.');
        dispatch(revalidateKey(err?.info?.sessionId));
        dispatch(reduxDeleteStore(err));
        dispatch(finishLoading());
      });
  };
}

export const reduxDeleteStore = (store) => {
  return {
    type: types.storeDeleted,
    payload: store,
  };
};

// Eliminar store type
export const deleteStoreType = (storeTypeData, token, storeType) => {
  return (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/storeTypes/v1/delete', storeTypeData, storeType, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(reduxRequestStore(res));
        dispatch(reduxDeleteStoreType(storeType));
        toast.success(res?.info?.message || 'Ubicación eliminada.');

      })
      .catch((err) => {
        toast.error(err?.info?.message || 'No se pudo eliminar la ubicación.');
        dispatch(revalidateKey(err?.info?.sessionId));
        dispatch(reduxDeleteStoreType(err));
        dispatch(finishLoading());
      });
  };
}

export const reduxDeleteStoreType = (storeType) => {
  return {
    type: types.storeTypeDeleted,
    payload: storeType,
  };
};

/*********************** REQUEST ***********************/
export const reduxRequestStore = (res) => {
  return {
    type: types.storeRequest,
    payload: res,
  };
};