import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

export const CategoryByGroup = ({
  cat,
  index,
  image,
  order,
  name,
  status,
  products,
  productsCount,
  categoryID,
  //categoryID,
  condimentGroupID,
  scheduleID,
  schedules,
  schedule,
}) => {
  return (
    <Draggable key={'' + categoryID} draggableId={'' + condimentGroupID} index={index}>
      {(provided) => (
        <div
          className="category-item"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="category-item__drag">
            <span className="icon-drag"></span>
          </div>
          <div className="category-item__left">
            <img src={image} width="" height="" alt=''/>
          </div>
          <div className="category-item__right">
            <span className="category-item__position">
              Posición #{index + 1}
            </span>
            <h3>{name}</h3>
            <p>
              <span>Estado: {status ? 'Activo' : 'Inactivo'}</span> |{' '}
              <span>
                  Horario: <span>{schedule.formattedSchedule}</span> 
              </span>
            </p>
            <span>{productsCount ? productsCount : '0'} productos</span>
            <Link to={`/categorias/editar/${categoryID}`}>
              <span className="icon-edit"></span>
            </Link>
          </div>
        </div>
      )}
    </Draggable>
  );
};
