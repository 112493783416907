import { toast } from 'react-toastify';
import { types } from '../types/types';
import { fetchProducts, fetchService } from '../helpers/fetch';
import { revalidateKey } from './key';
import { finishLoading, startLoading } from './ui';

import { prepareProducts } from '../helpers/prepareProducts';
import { prepareProductsSizesByGroup } from '../helpers/prepareProductsSizes';
import { prepareProductsGroups } from '../helpers/prepareProductsGroups';

export const setRedux = (type, data) => {
  return {
    payload: data,
    type: types[type]
  };
};

/*********************** GET ***********************/
// Obtener productos
export const productGetAll = (data, token) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/products/v1/all', data, token)
      .then((res) => {
        const products = prepareProducts(res.responseContent);
        dispatch(setRedux('productsAll', products));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

// Obtener productos agrupados por categoria
export const productGetByCategories = (data, token) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/categories/v1/all', data, token)
      .then((res) => {
        const productByCategories = res.responseContent;
        dispatch(setRedux('productByCategories', productByCategories));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

// Obtener tamaños de productos
export const productSizeGetAll = (data, token) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/productSizes/v1/all', data, token)
      .then((res) => {
        const productSize = prepareProductsSizesByGroup(res.responseContent);
        dispatch(setRedux('productSizesAll', productSize));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

// Obtener grupos de versiones
export const versionGroupGetAll = (data, token) => {
  return (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/substitution-groups/v1/all', data, token)
      .then((res) => {
        const versionGroup = res.responseContent;
        dispatch(setRedux('versionGroupsAll', versionGroup));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

// Obtener grupo de sub-productos
export const subProductGroupGetAll = (data, token) => {
  return (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/menu-items-groups/v1/all', data, token)
      .then((res) => {
        const subProductGroup = prepareProductsGroups(res.responseContent);
        dispatch(setRedux('subProductGroupsAll', subProductGroup));
        dispatch(finishLoading());
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

// Eliminar tamaño de producto
export const productSizeDelete = (data, token, productSize) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/productSizes/v1/delete', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(setRedux('productSizeRequest', res));
        dispatch(setRedux('productSizeDeleted', productSize));
        res?.info?.type == 'success'
          ? toast.success(res?.info?.message || 'Tamaño de producto eliminado.')
          : toast.error(res?.info?.message || 'No se elimino el tamaño de producto!');
      })
      .catch((err) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(setRedux('productSizeRequest', err));
        toast.error('No se elimino el tamaño de producto!');
      });
  };
};

// Eliminar grupo de versiones
export const versionGroupDelete = (data, token, versionGroup) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/substitution-groups/v1/delete', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(setRedux('versionGroupRequest', res));
        dispatch(setRedux('versionGroupDeleted', versionGroup));
        res?.info?.type == 'success'
          ? toast.success(res?.info?.message || 'Grupo de versiones eliminado.')
          : toast.error(res?.info?.message || 'No se elimino el grupo de versiones!');
      })
      .catch((err) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(setRedux('versionGroupRequest', err));
        toast.error(err?.info?.message || 'No se elimino el grupo de versiones!');
      });
  };
};

// Crear tamaño de producto
export const productSizeCreate = (data, token, productSize) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/productSizes/v1/create', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(setRedux('productSizeRequest', res));
        dispatch(setRedux('productSizeCreated', productSize));
        res?.info?.type == 'success'
          ? toast.success(res?.info?.message || 'Tamaño de producto creado.')
          : toast.error(res?.info?.message || 'No se creo el tamaño de producto!');
      })
      .catch((err) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(setRedux('productSizeRequest', err));
        toast.error('No se creo el tamaño de producto!');
      });
  };
};

// Crear grupo de versiones
export const versionGroupCreate = (data, token, versionGroup) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/substitution-groups/v1/create', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(setRedux('versionGroupRequest', res));
        dispatch(setRedux('versionGroupCreated', versionGroup));
        res?.info?.type == 'success'
          ? toast.success(res?.info?.message || 'Grupo de versiones creado.')
          : toast.error(res?.info?.message || 'No se creo el grupo de versiones!');
      })
      .catch((err) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(setRedux('versionGroupRequest', err));
        toast.error('No se creo el grupo de versiones!');
      });
  };
};

// Actualizar tamaño de producto
export const productSizeUpdate = (data, token, productSize) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/productSizes/v1/update', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(setRedux('productSizeRequest', res));
        dispatch(setRedux('productSizeUpdated', productSize));
        res?.info?.type == 'success'
          ? toast.success(res?.info?.message || 'Tamaño de producto actualizado.')
          : toast.error(res?.info?.message || 'No se actualizo el tamaño de producto!');
      })
      .catch((err) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(setRedux('productSizeRequest', err));
        toast.error('No se actualizo el tamaño de producto!');
      });
  };
};

// Actualizar grupo de versiones
export const versionGroupUpdate = (data, token, versionGroup) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/substitution-groups/v1/update', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(setRedux('versionGroupRequest', res));
        dispatch(setRedux('versionGroupUpdated', versionGroup));
        res?.info?.type == 'success'
          ? toast.success(res?.info?.message || 'Grupo de versiones actualizado.')
          : toast.error(res?.info?.message || 'No se actualizo el grupo de versiones!');
      })
      .catch((err) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(setRedux('versionGroupRequest', err));
        toast.error(err?.info?.message || 'No se actualizo el grupo de versiones!');
      });
  };
};

// NEW METHOD
const endpoint = {
  product: '/products/v1/',
  categories: '/categories/v1/',
  productSize: '/productSizes/v1/',
  versionGroup: '/substitution-groups/v1/',
  subProductGroup: '/menu-items-groups/v1/',
}

export const transformData = (type, data) => {
  switch (type) {
    case 'product': return prepareProducts(data);
      break;
    case 'categories': return data;
      break;
    case 'product': return prepareProducts(data);
      break;
    case 'productSize': return prepareProductsSizesByGroup(data);
      break;
    case 'versionGroup': return data;
      break;
    case 'subProductGroup': return prepareProductsGroups(data);
      break;
  }
}

export const requestAll = (type, body, subBody) => {
  const transformType = {
    product: 'productsAll',
    categories: 'productByCategories',
    productSize: 'productSizesAll',
    versionGroup: 'versionGroupsAll',
    subProductGroup: 'subProductGroupsAll',
  };

  return requestDispatch({
    endpoint: `${endpoint[type]}all`,
    requestName: transformType[type],
    altMsjError: '¡La solicitud de consultar la lista de datos fallo!',
  },
    body,
    subBody
  );
};

export const requestCreate = (type, body, subBody) => {
  return requestDispatch({
    endpoint: `${endpoint[type]}create`,
    requestName: type,
    requestType: 'Created',
    altMsjError: '¡La solicitud de actualizar la información fallo!',
    altMsjSuccess: '¡La solicitud para actualizar la información fue exitosa!',
  },
    body,
    subBody
  );
};

export const requestUpdate = (type, body, subBody) => {
  return requestDispatch({
    endpoint: `${endpoint[type]}update`,
    requestName: type,
    requestType: 'Updated',
    altMsjError: '¡La solicitud de actualizar la información fallo!',
    altMsjSuccess: '¡La solicitud para actualizar la información fue exitosa!',
  },
    body,
    subBody
  );
};

export const requestDelete = (type, body, subBody) => {
  return requestDispatch({
    endpoint: `${endpoint[type]}delete`,
    requestName: type,
    requestType: 'Deleted',
    altMsjError: '¡La solicitud de eliminar fallo!',
    altMsjSuccess: '¡La solicitud para eliminar fue exitosa!',
  },
    body,
    subBody
  );
};

export const requestDispatch = (data, body, subBody) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const request = fetchService(data.endpoint, body) //token
      .then((res) => {
        dispatch(finishLoading());
        dispatch(setRedux(`${data.requestName}Request`, res));
        dispatch(setRedux(`${data.requestName}${data.requestType}`, subBody));
        res?.info?.type == 'success'
          ? toast.success(res?.info?.message || data.altMsjSuccess)
          : toast.error(res?.info?.message || data.altMsjError);
      })
      .catch((err) => {
        dispatch(finishLoading());
        toast.error(err?.info?.message || data.altMsjError);
        dispatch(setRedux(`${data.requestName}Request`, err));
      });
  };
};

export const requestDispatchAll = (data, body) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const request = fetchService(data.endpoint, body)
      .then(async (res) => {
        dispatch(finishLoading());
        const responseContent = await transformData(data.requestName, res.responseContent);
        dispatch(setRedux(`${data.requestName}`, responseContent));
        res?.info?.type != 'success' && toast.error(res?.info?.message || data.altMsjError);
      })
      .catch((err) => {
        dispatch(finishLoading());
        toast.error(err?.info?.message || data.altMsjError);
        dispatch(setRedux(`${data.requestName}Request`, err));
      });
  };
};

/**************************** old ****************************/
// Update Product

export const startUpdateProduct = (data, token, Product) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/products/v1/update', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(res.info.sessionId));
        dispatch(updateProduct(Product));
        toast.success('Producto actualizado');
      })
      .catch((err) => {
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(finishLoading());
      });
  };
};

export const updateProduct = (Product) => {
  return {
    type: types.productUpdate,
    payload: Product,
  };
};

// Update Product

export const startDeleteProduct = (data, token, Product) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/products/v1/delete', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(res.info.sessionId));
        dispatch(deleteProduct(Product));
        toast.success('Producto eliminado');
      })
      .catch((err) => {
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(finishLoading());
      });
  };
};

export const deleteProduct = (Product) => {
  return {
    type: types.productDelete,
    payload: Product,
  };
};
