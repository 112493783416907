import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { singleFetchService } from '../../../helpers/functions';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { Button, Form, Input, Dropdown } from 'semantic-ui-react';
import { ProductActiveItem } from './components/ProductActiveItem';
import { ProductSelectItem } from './components/ProductSelectItem';
import { BackRelative } from '../../../components/Back/BackRelative';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import ImageUploading from 'react-images-uploading';
import { InputMultipleFile } from './components/InputMultipleFile';
import { versionGroupCreate, versionGroupUpdate, versionGroupDelete } from '../../../actions/product';

// import { Form, Input, Button, Checkbox, Message, Dropdown, Icon, Radio } from 'semantic-ui-react';

const initialData = {
  menuItemID: 0,
  subProduct: false,
  status: true,
  name: {
    value: '',
    error: false
  },
  nameWeb: {
    value: '',
    error: false
  },
  description: {
    value: '',
    error: false
  },
  microsID: {
    value: '',
    error: false
  },
  symphonyID: {
    value: '',
    error: false
  },
  price: {
    value: 0,
    error: false
  },
  calories: {
    value: 0,
    error: false
  },
  sizeID: 0,
  schedule: {
    scheduleID: 0
  },
  storeTypes: [],
  condimentGroups: [],
  menuItemsGroups: [],
  // examinar la estructura correcta
  image1: '',
  image2: '',
  image3: '',
  image4: '',
  imageList: [],
  // no necesarios
  arIos: '',
  arAndroid: '',

  // examinar la estructura para multiseleccion, aun no existe
  categoryID: 0,
  category: [],
  // "microsID": "100",
  // "symphonyID": "100",
  // "name": "MenuItemForTestingOnly",
  // "description": "MenuItemForTestingOnly",
  // "price": 20,
  // "status": false,
  // "image1": "{{Base64Image}}",
  // "image2": "",
  // "image3": "",
  // "image4": "",
  // "nameWeb": "",
  // "sizeID": 4,
  // "arAndroid": "",
  // "arIos": "",
  // "calories": 20,
  // "subProduct": false,
  // "schedule": {
  //   "scheduleID": 4
  // },
  // "condimentGroups": [],
  // "menuItemsGroups": [],
  // "storeTypes": []
};

export const FormProduct = () => {
  /***  Selector  ***/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let key = useSelector((state) => state.key.key);
  const { loading } = useSelector((state) => state.ui);
  const productRequest = useSelector((state) => state.product.versionGroupRequest);

  /*** Variables ***/
  const location = useLocation();
  const [keyword, setKeyword] = useState('');
  const [form, setForm] = useState(initialData);
  const [validForm, setValidForm] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [listSubProductActivated, setListSubProductActivated] = useState([]);
  const [listCondimentsGroupActivated, setListCondimentsGroupActivated] = useState([]);
  const [subProductList, setSubProductList] = useState([]);
  const [productListAux, setProductListAux] = useState([]);
  const [productSizeList, setProductSizeList] = useState([]);
  const [condimentGroupList, setCondimentGroupList] = useState([]);
  const [condimentGroupListAux, setCondimentGroupListAux] = useState([]);

  const [storesTypeList, setStoresTypeList] = useState([]);

  const productID = (location.pathname.match(/\d+/) || [])[0] || undefined;

  // Modal states
  const [showModal, setShowModal] = useState(false);
  const [resumeModal, setResumeModal] = useState('No podrás revertir esta acción.');
  const [titleModal, setTitleModal] = useState('¿Estás seguro que deseas eliminar este grupo de versiones?');

  /*** Funciones ***/
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });
  };

  const handleValidateNUmber = (e) => {
    e.preventDefault();

    const validator = (
      parseFloat(e.target.value) <= -1 || parseFloat(form[e.target.name].value) <= -1 || e.target.value == ''
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handlerNumber = (e) => {
    e.preventDefault();

    const value = e.target.value != '' ? parseFloat(e.target.value) : 0;
    setForm({
      ...form,
      [e.target.name]: {
        value: value,
        ...form[e.target.name].error
      }
    });
  };

  const handlerBoolean = (e) => {
    setForm({ ...form, [e.target.name]: !form[e.target.name] });
  };

  const handlerStoreType = async (e) => {
    const storeTypeID = Number(e.target.value);
    const findIndex = form.storeTypes.findIndex(x => x.storeTypeID == storeTypeID);

    if (findIndex == -1) {
      const storeTypes = await [...form.storeTypes, { storeTypeID: storeTypeID }];
      setForm({ ...form, storeTypes: storeTypes });
    }
    else {
      const storeTypes = await [...form.storeTypes, { storeTypeID: storeTypeID }];
      setForm({ ...form, storeTypes: storeTypes.splice(1, findIndex) });
    }
  };

  const handlerStoreTypeSelect = (storeTypeID) => {
    const findIndex = form.storeTypes.findIndex(x => x.storeTypeID == storeTypeID);
    if (findIndex == -1) {
      return false;
    } else {
      return true;
    }
  };

  const handleChangeProductSize = (e, id) => {
    setForm({
      ...form,
      storeID: id
    });
  }

  const handleChangeCategory = (e, id) => {
    setForm({
      ...form,
      categoryID: id,
      // category: [...form.category, id]
    });
  }

  // Buscar un producto
  const handleSearch = (e) => {
    const value = e.target.value;

    if (value !== '') {
      const results = productListAux.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });
      setSubProductList(results);
    } else {
      setSubProductList(productListAux);
    }
    setKeyword(value);
  }

  // Agregar un producto de la lista
  const handleActiveProduct = async (newProds) => {
    const existe = await listCondimentsGroupActivated.find(
      (x) => x.menuItemID === newProds.menuItemID
    );

    if (existe === undefined) {
      setListCondimentsGroupActivated((prevProds) => {
        return [newProds, ...prevProds];
      });
    }
  };

  // Cambiar el orden de un producto agregado
  const handleOnDrag = (result) => {
    const items = Array.from(listCondimentsGroupActivated);
    const [reorderedItems] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItems);
    items.map((item, index) => items[index].order = index);
    setListCondimentsGroupActivated(items);
  };

  // Obtener todos los tamaños de productos
  const getAllProductSizes = () => {
    const body = singleFetchService('/productSizes/v1/all', "{}", key)
      .then(async (response) => {
        const res = response.responseContent;
        setProductSizeList(res);
      })
      .catch((err) => console.error(err));
    return body;
  };

  const getAllStoreTypes = () => {
    const body = singleFetchService('/storeTypes/v1/all', "{}", key)
      .then(async (response) => {
        const res = response.responseContent;
        setStoresTypeList(res);
      })
      .catch((err) => console.error(err));
    return body;
  };

  const getAllCategories = () => {
    const body = singleFetchService('/categories/v1/all', "{}", key)
      .then(async (response) => {
        const res = response.responseContent;
        setCategoryList(res);
      })
      .catch((err) => console.error(err));
    return body;
  };

  // Obtener todos los productos
  const getAllCondimentGroups = () => {
    // const body = singleFetchService('/condiments/v1/all', "{}", key)
    const body = singleFetchService('/condiments-groups/v1/all', "{}", key)
      .then(async (response) => {
        const res = response.responseContent;
        setCondimentGroupList(res);
        setCondimentGroupListAux(res);
        console.log('getAllCondimentGroups', res);
      })
      .catch((err) => console.error(err));
    return body;
  };

  // Obtener todos los productos
  const getAllSubProducts = () => {
    // const body = singleFetchService('/products/v1/all', "{}", key)
    const body = singleFetchService('/menu-items-groups/v1/all', "{}", key)
      .then(async (response) => {
        const res = response.responseContent;
        setSubProductList(res);
        setProductListAux(res);
        console.log('getAllSubProducts', res);
      })
      .catch((err) => console.error(err));
    return body;
  };

  // Obtener un grupo de versiones
  const getOneProduct = () => {
    const data = JSON.stringify({
      requestContent: {
        product: { menuItemID: +productID }
      }
    });

    const body = singleFetchService('/products/v1/id', data, key)
      .then(async (response) => {
        const res = response.responseContent;
        console.log(res);
        setForm({
          arIos: res?.arIos,
          sizeID: res?.sizeID,
          schedule: res?.schedule,  // {scheduleID: 0},
          arAndroid: res?.arAndroid,
          menuItemID: res?.menuItemID,
          status: res?.status || false,
          subProduct: res?.subProduct || false,
          name: { value: res?.name, error: false },
          nameWeb: { value: res?.nameWeb, error: false },
          price: { value: res?.price || 0, error: false },
          microsID: { value: res?.microsID, error: false },
          calories: { value: res?.price || 0, error: false },
          description: { value: res?.description, error: false },
          symphonyID: { value: res?.symphonyID, error: false },
          //revisar
          image1: res?.image1,
          image2: res?.image2,
          image3: res?.image3,
          image4: res?.image4,
          category: res?.categories || [],
          storeTypes: res?.storeTypes || [],
          menuItemsGroups: res?.menuItemsGroups || [], //no viene
          condimentGroups: res?.condimentGroups || [],
        });
        setListSubProductActivated(res?.menuItemsGroups || []);
        setListCondimentsGroupActivated(res?.condimentGroups || []);
      })
      .catch((err) => console.error(err));
    return body;
  };

  // Enviar formulario 
  const onSubmit = (e) => {
    e.preventDefault();

    console.log('form', form);
    // const substitutionGroups = {
    //   substitutionGroupID: +productID || undefined,
    //   type: productID ? 'updated' : 'created',
    //   status: form.status,
    //   name: form.name.value,
    //   description: form.description.value,
    //   menuItems: listActivated
    // };

    // const dataCondiment = JSON.stringify({
    //   requestContent: { substitutionGroups }
    // });

    // if (!productID) {
    //   dispatch(versionGroupCreate(dataCondiment, key, substitutionGroups));
    // } else {
    //   dispatch(versionGroupUpdate(dataCondiment, key, substitutionGroups));
    // }
  };

  // Eliminar
  const deleteVersionGroup = (id) => {
    const substitutionGroups = {
      name: form.name.value,
      substitutionGroupID: +productID
    }

    const data = JSON.stringify({
      requestContent: { substitutionGroups: substitutionGroups }
    });
    dispatch(versionGroupDelete(data, key, substitutionGroups));
  };

  /***  useEffects  ***/
  // Crear o Editar
  useEffect(() => {
    getAllSubProducts();
    getAllCategories();
    getAllStoreTypes();
    getAllProductSizes();
    getAllCondimentGroups();
    if (productID != undefined) getOneProduct();
  }, [productID]);

  // Set state when form is invalid
  useEffect(() => {
    if (form.name.value.length < 2 || listCondimentsGroupActivated.length <= 1) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [listCondimentsGroupActivated, form]);

  // Re-direccionar
  useEffect(() => {
    if (productRequest?.info?.type === 'success') navigate('/productos');
  }, [productRequest]);

  return (
    <>
      <div className="container pbottom50">
        <BackRelative url="/productos" />
        <div className="medium-wrap">
          <h1 className="text-center m-0">
            {productID ? 'Editar producto' : 'Crear producto'}
          </h1>
          <Form onSubmit={onSubmit} className="general-form">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="text-left mb-2">
                  <p className="subtitle">¿Esto es un subproducto?</p>
                  <p className='condiment-specs'>
                    Marca este checkbox si se trata de un subproducto, es decir un producto que no se vende solo.
                  </p>
                </div>
                <input
                  id='subProduct'
                  name='subProduct'
                  className='barista-checkbox'
                  type="checkbox"
                  onChange={handlerBoolean}
                  checked={form.subProduct == true}
                />
                <span style={{ display: 'inline-block', margin: '5px 0px 0px 5px' }}>Este producto es un subproducto.</span>
              </div>
              <div className="col-12 mb-3">
                <p className="subtitle">Información general</p>
              </div>
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.name.error ? 'errorField' : ''}>
                    Nombre a mostrar en la app
                    <Input
                      type="text"
                      name="name"
                      minLength="2"
                      maxLength="25"
                      required={true}
                      placeholder="Nombre para clientes"
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.name.value}
                    />
                    <span className="countCharacters dblock text-right font15">
                      {form.name.value?.length}/25
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.nameWeb.error ? 'errorField' : ''}>
                    Nombre en administrador
                    <Input
                      type="text"
                      name="nameWeb"
                      minLength="2"
                      maxLength="25"
                      required={true}
                      placeholder="Nombre para uso interno"
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.nameWeb.value}
                    />
                    <span className="countCharacters dblock text-right font15">
                      {form.nameWeb.value?.length}/25
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.symphonyID.error ? 'errorField' : ''}>
                    Symphony
                    <Input
                      type="text"
                      name="symphonyID"
                      minLength="1"
                      maxLength="25"
                      required={true}
                      placeholder="Symphony"
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.symphonyID.value}
                    />
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="mbottom30">
                  <label>
                    Tamaño de producto
                    <Dropdown
                      fluid
                      simple
                      selection
                      name="sizeID"
                      className='mt-2'
                      value={form.sizeID}
                      options={
                        [] = productSizeList?.map(
                          (productSize, index) => {
                            return ({
                              key: index,
                              value: productSize.sizeID,
                              text: productSize.description
                            })
                          })
                      }
                      placeholder="Seleccionar tamaño..."
                      onChange={(e, { value }) => handleChangeProductSize(e, value)}
                    />
                  </label>
                </div>
              </div>
              <div className="mbottom30 col-12">
                <label className={form.description.error ? 'errorField' : ''}>
                  Descripción
                  <textarea
                    rows="20"
                    name="description"
                    placeholder="Proporciona una descripción breve..."
                    minLength="5"
                    maxLength="300"
                    required={true}
                    onBlur={handleValidate}
                    onChange={handleChange}
                    value={form.description.value}
                  ></textarea>
                  <span className="countCharacters dblock text-right font15">
                    {form.description.value?.length}/300
                  </span>
                </label>
              </div>
              <div className="col-4">
                <div className="mbottom30">
                  <label className="has-tooltip-inline pb-1">Precio</label>
                  <div className={form.price.error ? 'unit-of-measurement__input_error' : 'unit-of-measurement__input'} >
                    <label >
                      GTQ
                      <input
                        className="unit-left"
                        name="price"
                        type="number"
                        placeholder="00"
                        min={0}
                        onChange={handlerNumber}
                        onBlur={handleValidateNUmber}
                        value={form.price.value}
                        required
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-8">
                <div className="mbottom30">
                  <label>
                    Categoría a la que pertenece (Opcional)
                    <Dropdown
                      fluid
                      selection
                      // multiple
                      className='mt-2'
                      value={form.categoryID}
                      options={
                        [] = categoryList?.map(
                          (productSize, index) => {
                            return ({
                              key: index,
                              text: productSize.name,
                              value: productSize.categoryID
                            })
                          })
                      }
                      placeholder="Seleccionar categoría..."
                      onChange={(e, { value }) => handleChangeCategory(e, value)}
                    />
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="mbottom30">
                  <label className="has-tooltip-inline pb-1">Calorías (Opcional)</label>
                  <div className={form.calories.error ? 'unit-of-measurement__input_error' : 'unit-of-measurement__input'} >
                    <label >
                      <input
                        className="unit-right"
                        name="calories"
                        type="number"
                        placeholder="00"
                        min={0}
                        value={form.calories.value}
                        onChange={handlerNumber}
                        onBlur={handleValidateNUmber}
                      />
                      cal
                    </label>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <InputMultipleFile
                  title={'Imágenes'}
                  maxNumber={4}
                  imageList={form.imageList}
                />
              </div>
              <div className="col-3 mb-2">
                <div className="radio-group">
                  <label className="has-tooltip-inline">
                    Estado <Tooltip text="Indica si estará disponible o no como disponible en la app." />
                  </label>
                  <ul className="margin0 padding0">
                    <li>
                      <div className="radio relative">
                        <input
                          id="radio1"
                          type="radio"
                          name="status"
                          value="1"
                          onChange={handlerBoolean}
                          checked={form.status == true}
                        />
                        <label htmlFor="radio1" className="radio-label"></label>
                        <label htmlFor="radio1">Activo</label>
                      </div>
                    </li>
                    <li>
                      <div className="radio relative">
                        <input
                          id="radio2"
                          type="radio"
                          name="status"
                          value="0"
                          onChange={handlerBoolean}
                          checked={form.status == false}
                        />
                        <label htmlFor="radio2" className="radio-label"></label>
                        <label htmlFor="radio2">Inactivo</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-9 mbottom30">
                <div className="radio-group">
                  <label className="dblock pbottom15">Disponibilidad</label>
                  <ul className="margin0 padding0 list-column">
                    {storesTypeList.map((storesType, index) => (
                      <li key={index}>
                        <div className="radio relative">
                          <input
                            id='storeTypes'
                            type="checkbox"
                            className='barista-checkbox'
                            value={storesType?.typeID}
                            onChange={handlerStoreType}
                            // checked={form.subProduct == true}
                            checked={handlerStoreTypeSelect(storesType?.typeID)}
                          />
                          <span style={{ display: 'inline-block', margin: '5px 0px 0px 5px', width: '83%' }}>{storesType?.typeID} - {storesType?.typeName}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* condimentos */}
              <div className="col-12 select-drag">
                <div className="text-left">
                  <p className="subtitle">Selección de condimentos</p>
                  <p className='condiment-specs mb-3'>
                    Esto aplica si el producto tiene ingredientes que se pueden personalizar. Ejemplo: Un café en donde se puede elegir el tipo de leche, azúcar y temperatura.
                  </p>
                </div>
                <div className="select-drag__wrap">
                  <div className="select-drag__left">
                    <h4>Existentes</h4>
                    <div className='w-100 px-2 mb-2 mt-0'>
                      <div className="ui left icon input search-in-subcategory text-center m-auto w-100 mt-0">
                        <i aria-hidden="true" className="search icon" style={{ top: '0px' }}></i>
                        <input type="text" placeholder="Buscar" style={{ marginTop: '0px' }} onChange={handleSearch} value={keyword} />
                      </div>
                    </div>
                    <div className="select-drag__list" >
                      {condimentGroupList?.map((condimentGroup, index) => (
                        // {productList?.map((product, index) => (
                        <ProductSelectItem
                          key={condimentGroup?.menuItemID}
                          name={condimentGroup?.name}
                          listActivated={listCondimentsGroupActivated}
                          menuItemID={condimentGroup?.menuItemID}
                          handleActiveProduct={handleActiveProduct}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="select-drag__right">
                    <h4>Seleccionados</h4>
                    <div className="select-drag__list-drag">
                      {listCondimentsGroupActivated.length > 0 ? (
                        <DragDropContext onDragEnd={handleOnDrag}>
                          <Droppable droppableId="list-subcondiments">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <ProductActiveItem
                                  listActivated={listCondimentsGroupActivated}
                                  setListActivated={setListCondimentsGroupActivated}
                                  handleActiveProduct={handleActiveProduct}
                                />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : (
                        <p className="text-center font14">
                          Si aplica, agrega grupos de condimentos a este producto.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* sub productos */}
              <div className="col-12 select-drag" style={{ opacity: 0.5 }}>
                <div className="text-left">
                  <p className="subtitle">Selección de subproductos</p>
                  <p className='condiment-specs mb-3'>
                    Esto aplica para productos que tienen otro tipo de productos de los que el usuario puede seleccionar. Ejemplo: Un desayuno en donde el usuario puede elegir entre un café, agua pura o jugo de naranja.
                  </p>
                </div>
                <div className="select-drag__wrap">
                  <div className="select-drag__left">
                    <h4>Existentes</h4>
                    <div className='w-100 px-2 mb-2 mt-0'>
                      <div className="ui left icon input search-in-subcategory text-center m-auto w-100 mt-0">
                        <i aria-hidden="true" className="search icon" style={{ top: '0px' }}></i>
                        <input type="text" placeholder="Buscar" style={{ marginTop: '0px' }} onChange={handleSearch} value={keyword} />
                      </div>
                    </div>
                    <div className="select-drag__list" >
                      {subProductList?.map((product, index) => (
                        <ProductSelectItem
                          key={product.menuItemID}
                          name={product.name}
                          listActivated={listCondimentsGroupActivated}
                          menuItemID={product.menuItemID}
                          handleActiveProduct={handleActiveProduct}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="select-drag__right">
                    <h4>Seleccionados</h4>
                    <div className="select-drag__list-drag">
                      {listCondimentsGroupActivated.length > 0 ? (
                        <DragDropContext onDragEnd={handleOnDrag}>
                          <Droppable droppableId="list-subcondiments">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <ProductActiveItem
                                  listActivated={listCondimentsGroupActivated}
                                  setListActivated={setListCondimentsGroupActivated}
                                  handleActiveProduct={handleActiveProduct}
                                />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : (
                        <p className="text-center font14">
                          Si aplica, agrega los subproductos asignados a este producto.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!productID ?
              <div className="text-center">
                <Button
                  loading={loading}
                  disabled={!validForm}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                >
                  Guardar grupo
                </Button>
                <Button className='cta cta--secondary'>Guardar test</Button>
              </div>
              :
              <div className="flex flex--between">
                <Button
                  type="button"
                  className="cta cta--secondary-plain"
                  onClick={() => setShowModal(true)}
                >
                  Eliminar grupo
                </Button>
                <Button
                  loading={loading}
                  disabled={!validForm}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                >
                  Guardar cambios
                </Button>
              </div>
            }
          </Form>
        </div>
      </div>
      <DeleteModal
        show={showModal}
        id={productID}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        handleDelete={deleteVersionGroup}
        withContent={false}
        isLoading={loading}
      />
    </>
  );
}
