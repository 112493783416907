import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { types } from '../../../types/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Button, Input, Radio, Form } from 'semantic-ui-react';
import { singleFetchService } from '../../../helpers/functions';
import { storeGetAll, storeTypeGetAll, updateStore } from '../../../actions/store';
import { EmptyState } from '../../../components/EmptyState/EmptyState';

export const Stores = () => {
  /***  Selector  ***/
  const dispatch = useDispatch();
  const { key } = useSelector((state) => state.key);
  const originalDistance = useSelector((state) => state.store.distance);
  const storeListRedux = useSelector((state) => state.store.storesAll);
  const storeTypeListRedux = useSelector((state) => state.store.storeTypesAll);

  /***  Variables  ***/
  const itemsPerPage = 10;
  const [tabIndex, setTabIndex] = useState(2);
  const [distance, setDistance] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [validForm, setValidForm] = useState(false);
  const [currentStore, setCurrentStore] = useState([]);
  const [storeList, setStoresList] = useState(storeListRedux);
  const [storeTypeList, setStoreTypeList] = useState(storeTypeListRedux);

  /***  Funciones  ***/
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleDistanceChange = (e) => {
    setDistance(e.target.value);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const generalDistanceSettings = JSON.stringify({
      requestContent: {
        generalDistanceSettings: {
          distance: distance
        }
      }
    });

    const body = singleFetchService('/stores/v1/general', generalDistanceSettings, key)
      .then((response) => {
        if (response?.info?.type === 'success') {
          setTabIndex(2);
          toast.success(response?.info?.message || 'Distancia máxima para delivery actualizada');
        } else {
          setDistance(originalDistance);
          toast.success(response?.info?.message || 'Distancia máxima para delivery actualizada');
        }
      })
      .catch((err) => {
        setDistance(originalDistance);
        toast.error(err.info.message || 'No se pudo actualizar.');
      });
  };

  const handleStatusChange = async (e, index) => {
    let status;
    switch (currentStore[index].status) {
      case 'A': status = 'I';
        break;
      case 'I': status = 'A';
        break;
    };

    const storeData = {
      requestContent: {
        store: {
          status: status,
          storeID: Number(currentStore[index].storeID),
        }
      }
    }

    dispatch(await updateStore(JSON.stringify(storeData), key, storeData));
    const ref_stores = [...currentStore];
    ref_stores[index].status = status;
    setCurrentStore(ref_stores);
  };

  // Busqueda
  const [inputFilter, setInputFilter] = useState('');

  const handleFilter = (e) => {
    if (e.target.value === '') {
      switch (tabIndex) {
        case 1: setStoreTypeList(storeTypeListRedux);
          break;
        default:
          break;
      }
    };
    setInputFilter(e.target.value);
  };

  const onSearchProduct = (e) => {
    e.preventDefault();
    if (inputFilter !== '') {
      const result = storeTypeListRedux.filter((x) =>
        x.typeName.toLowerCase().includes(inputFilter.toLowerCase())
      );
      setStoreTypeList(result);
    }
  };

  /***  useEffects ***/
  useEffect(() => {
    setStoresList(storeListRedux);
    setStoreTypeList(storeTypeListRedux);
    // setDistance(originalDistance);
  }, [storeListRedux, storeTypeListRedux, originalDistance]);

  useEffect(() => {
    dispatch(storeGetAll('{}', key));
    dispatch(storeTypeGetAll('{}', key));
    dispatch({ type: types.storeRequest, payload: {} });
  }, [dispatch]);

  useEffect(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setCurrentStore(storeList.slice(startIndex, endIndex));
  }, [currentPage, storeList]);

  return (
    <>
      {storeTypeListRedux.length > 1 ? (
        <>
          <h1 className='text-center m-0 pbottom30'>Ubicaciones</h1>
          <div className="full-wrap">
            <Tabs selectedIndex={tabIndex} onSelect={(index) => { setTabIndex(index); setInputFilter('') }}>
              <TabList>
                <Tab>Configuración general</Tab>
                <Tab>Tipos de sucursales</Tab>
                <Tab>Ubicaciones</Tab>
              </TabList>
              {/* Configuración general */}
              <TabPanel>
                {/* <div className="container pbottom50">
                  <div className="medium-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    <div className="text-left mb-5">
                      <p className="subtitle">Distancia máxima para delivery</p>
                      <p className='condiment-specs'>
                        Aquí puedes configurar el radio de distancia que cada tienda puede atender para el delivery.
                        Este cambio aplica para todas las tiendas.
                      </p>
                    </div>
                    <Form onSubmit={onSubmit} className="general-form">

                      <div className="row">
                        <div className="col-5">
                          <div className="mbottom30">
                            <label className="has-tooltip-inline pb-1">Distancia (en km)</label>
                            <div className="unit-of-measurement__input" >
                              <label >
                                <input
                                  className="unit-right"
                                  name="units"
                                  type="number"
                                  placeholder="00"
                                  value={distance}
                                  onBlur={(e) => (Number(e.target.value) > 0 && e.target.value != '')
                                    ? setValidForm(true) : setValidForm(false)
                                  }
                                  onChange={handleDistanceChange}
                                  required
                                />
                                km
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 text-center">
                        <Button className="cta" disabled={!validForm}>
                          Guardar cambios
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div> */}
              </TabPanel>
              {/* Tipos de sucursales */}
              <TabPanel className="standard-wrap">
                {!storeTypeListRedux || storeTypeListRedux.length === 0 ? (
                  <EmptyState
                    title="Tipos de sucursales"
                    text={`No tipos de sucursales creadas. Comienza creando un tipo de sucursal para poder asignarle un tipo a cada ubicación.`}
                    titleButton="+ Crear tamaño"
                    // linkButton="/productos/product-size/form/new"
                    linkButton="/"
                  />
                ) : (
                  <>
                    <div className="top-filters">
                      <div className="top-filters__left">
                        <form onSubmit={onSearchProduct}>
                          <Input
                            list="categories"
                            value={inputFilter}
                            onChange={handleFilter}
                            className="search-input"
                            placeholder="Buscar sucursal..."
                          />
                          <Button >Buscar</Button>
                        </form>
                      </div>
                      <div className="top-filters__right top-filters__right--flex-end">
                        <Link
                          className="cta cta--widthAuto margin0"
                          to="/"
                        >
                          {/* to="/ubicaciones/storeType/form/new" */}
                          + Crear sucursal
                        </Link>
                      </div>
                    </div>

                    {storeTypeList.length > 0 ? (
                      <>
                        <div className="categories-list categories-list--general">
                          {storeTypeList.map((storeType, index) => (
                            <div
                              key={index}
                              className="category-item category-item--general"
                            >
                              <div className="category-item__right category-item__right--top-edit">
                                <h3>{storeType?.typeName}</h3>
                                <p className="condiment-specs">
                                  {storeType?.description}
                                </p>
                                <Link to={`/`}>
                                  {/* to={`/ubicaciones/storeType/form/${storeType.typeID}`} */}
                                  <span className="icon-edit"></span>
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <EmptyState
                        title=""
                        linkButton=""
                        titleButton=""
                        text="No hay resultados para esta búsqueda. Intenta buscar otro término."
                      />
                    )}
                  </>
                )}
              </TabPanel>

              {/* Ubicaciones */}
              <TabPanel selectedIndex={0} onSelect={() => { }}>
                {/* {stores.length <= 0 ? (
                  <EmptyState
                    title="Ubicaciones"
                    text={`¡Ya tienes tipos de sucursales creadas! Ahora puedes crear ubicaciones aquí.`}
                    titleButton="+ Crear ubicación"
                    linkButton="/ubicaciones/store/form/new"
                  />
                ) : (
                  <>
                    <div className="top-filters">
                      <div className="top-filters__left top-filters__left--small">
                        <form>
                          <Input
                            className="search-input"
                            list="categories"
                            placeholder="Buscar una ubicación......"
                          />
                          <datalist id="categories">
                            <option value="Bebidas">Bebidas</option>
                            <option value="Paninis">Paninis</option>
                            <option value="Ensaladas">Ensaladas</option>
                          </datalist>
                          <Button>Buscar</Button>
                        </form>
                      </div>
                      <div className="top-filters__right top-filters__right--large">
                        <Link className="cta cta--secondary-plain cta--tiny margin0" to="/ubicaciones/storeSchedules/form" >
                          Editar horarios
                        </Link>
                        <Link className="cta cta--widthAuto margin0" to="/ubicaciones/store/form/new" >
                          + Crear ubicación
                        </Link>
                      </div>
                    </div>
                    <div className="accordion-module">
                      <div className="wrap-rows wrap-rows--header  wrap-rows--6columns">
                        <div className="column column-1">Tienda</div>
                        <div className="column column-2">Dirección</div>
                        <div className="column column-3">Contacto</div>
                        <div className="column column-4">Tipo</div>
                        <div className="column column-5">Estatus</div>
                        <div className="column column-6">Acciones</div>
                      </div>
                      {currentStore.map((store, index) => (
                        <div
                          key={index}
                          className="wrap-rows wrap-rows--6columns wrap-rows--locations"
                        >
                          <div className="column column-1">
                            {store?.storeName} <span className="dblock" style={{ 'color': '#939188' }}>{store?.storeCode}</span>
                          </div>
                          <div className="column column-2">
                            {store?.address}
                          </div>
                          <div className="column column-3">
                            {store?.phone1}
                            <br />
                            {store?.phone2}
                            <br />
                            {store?.manager}
                          </div>
                          <div className="column column-4">{store?.typeName}</div>
                          <div className="column column-5">
                            <Radio toggle value={store?.status} checked={store?.status === 'A'} onChange={(e) => handleStatusChange(e, index)} />
                          </div>
                          <div className="column column-6">
                            <Link to={`/ubicaciones/store/form/${store.storeID}`} className="link-orange">
                              <span className="icon-edit"></span> Editar
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <ReactPaginate
                  nextLabel=">"
                  breakLabel="..."
                  previousLabel="<"
                  className="pagination"
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  renderOnZeroPageCount={null}
                  pageCount={Math.ceil(stores.length / itemsPerPage)}
                /> */}
              </TabPanel>
            </Tabs>
          </div>
        </>
      ) : (
        <EmptyState
          title="Ubicaciones"
          text={`No hay ubicaciones creadas. Comienza creando un tipo de sucursal para poder asignarle un tipo a cada ubicación.`}
          titleButton="+ Crear tipo de sucursal"
          linkButton="/condimentos/storeType/form/new"
        />
      )}
    </>
  );
};
