import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'semantic-ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { singleFetchService } from '../../../helpers/functions';
import { BackRelative } from '../../../components/Back/BackRelative';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import { productSizeCreate, productSizeUpdate, productSizeDelete } from '../../../actions/product';

const initialData = {
  sizeID: 0,
  sizeAdm: {
    value: 0,
    error: false
  },
  sizeAdmText: {
    value: '',
    error: false
  },
  sizeApp: {
    value: '',
    error: false
  },
  description: {
    value: '',
    error: false
  },
  sizeTypeId: 0,
  formTouched: false
};

export const FormProductsSize = () => {
  /***  Selector  ***/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let key = useSelector((state) => state.key.key);
  const { loading } = useSelector((state) => state.ui);
  const productSizeRequest = useSelector((state) => state.product.productSizeRequest);

  /*** Variables ***/
  const location = useLocation();
  const [form, setForm] = useState(initialData);
  const [validForm, setValidForm] = useState(false);
  const productSizeID = (location.pathname.match(/\d+/) || [])[0] || undefined;

  // Modal states
  const [showModal, setShowModal] = useState(false);
  const [resumeModal, setResumeModal] = useState('No podrás revertir esta acción.');
  const [titleModal, setTitleModal] = useState('¿Estás seguro que deseas eliminar este tamaño de producto?');

  /*** Funciones ***/
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleValidateSizeAdm = (e) => {
    e.preventDefault();

    const validator = (
      e.target.value == null || parseFloat(e.target.value) <= -1 || e.target.value == ''
    ) ? true : false;

    setForm({
      ...form, formTouched: true,
      sizeAdm: {
        error: validator,
        value: form?.sizeAdm?.value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      formTouched: true,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });
  };

  const handlerSizeAdm = (e) => {
    e.preventDefault();

    const value = e.target.value != '' ? parseFloat(e.target.value) : 0;
    setForm({
      ...form,
      sizeAdm: {
        value: value,
        ...form[e.target.name].error
      }
    });
  };

  const handlerSizeType = (e) => {
    setForm({ ...form, formTouched: true, sizeTypeId: Number(e.target.value) });
  };

  // Obtener un condimento
  const getOneProductSize = () => {
    const data = JSON.stringify({
      requestContent: {
        productSize: { sizeID: +productSizeID }
      }
    });

    const body = singleFetchService('/productSizes/v1/id', data, key)
      .then(async (response) => {
        const res = response.responseContent;
        console.log(res);
        setForm({
          sizeID: +res?.sizeID,
          sizeTypeId: +res?.sizeType?.typeID,
          sizeApp: { value: res?.sizeApp || '', error: false },
          sizeAdm: { value: res?.sizeAdm || '', error: false },
          description: { value: res?.description || '', error: false },
          sizeAdmText: { value: res?.sizeAdm || '', error: false },
          sizeAdm: { value: parseFloat(res?.sizeAdm) || 0, error: false },
        });
      })
      .catch((err) => console.error(err));
    return body;
  };

  // Enviar formulario 
  const onSubmit = (e) => {
    e.preventDefault();

    const productSize = {
      sizeID: Number(productSizeID) || undefined,
      type: productSizeID ? 'updated' : 'created',
      sizeApp: form.sizeApp.value,
      description: form.description.value,
      sizeAdm: form.sizeTypeId == 0 ? form.sizeAdm.value.toString() : form.sizeAdmText.value,
      sizeType: {
        typeID: +form.sizeTypeId
      }
    };

    const dataCondiment = JSON.stringify({
      requestContent: { productSize }
    });

    if (!productSizeID) {
      dispatch(productSizeCreate(dataCondiment, key, productSize));
    } else {
      dispatch(productSizeUpdate(dataCondiment, key, productSize));
    }
  };

  const deleteProductSize = (id) => {
    const productSize = {
      sizeID: +id,
      name: form.description.value,
      nameWeb: form.sizeTypeId == 0 ? form.sizeAdm.value : form.sizeAdmText.value
    }

    const data = JSON.stringify({
      requestContent: { productSize: productSize }
    });
    dispatch(productSizeDelete(data, key, productSize));
  };

  /***  useEffects  ***/
  // Crear o Editar
  useEffect(() => {
    if (productSizeID != undefined) getOneProductSize();
  }, [productSizeID]);

  // Set state when form is invalid
  useEffect(() => {
    if (
      form.description.value.length < 2 ||
      (form.sizeTypeId == 0 && form.sizeAdm.value <= 0) ||
      (form.sizeTypeId == 1 && form.sizeAdmText.value.length < 2)
    ) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [form]);

  // Re-direccionar
  useEffect(() => {
    if (productSizeRequest?.info?.type === 'success') navigate('/productos');
  }, [productSizeRequest]);

  return (
    <>
      <div className="container pbottom50">
        <BackRelative url="/productos" />
        <div className="medium-wrap">
          <h1 className="text-center m-0 pbottom30">
            {productSizeID ? 'Editar tamaño' : 'Crear tamaño'}
          </h1>
          <Form onSubmit={onSubmit} className="general-form">
            <div className="row">
              <div className="col-12 mb-4">
                <div className="radio-group">
                  <label className="has-tooltip-inline">Tipo de tamaño</label>
                  <ul className="margin0 padding0">
                    <li>
                      <div className="radio relative">
                        <input
                          id="radio1"
                          type="radio"
                          name="sizeType"
                          value="0"
                          onChange={handlerSizeType}
                          checked={Number(form.sizeTypeId) === 0}
                        />
                        <label htmlFor="radio1" className="radio-label"></label>
                        <label htmlFor="radio1">Para bebidas</label>
                      </div>
                    </li>
                    <li>
                      <div className="radio relative">
                        <input
                          id="radio2"
                          type="radio"
                          name="sizeType"
                          value="1"
                          onChange={handlerSizeType}
                          checked={Number(form.sizeTypeId) === 1}
                        />
                        <label htmlFor="radio2" className="radio-label"></label>
                        <label htmlFor="radio2">Para comidas</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-8">
                <div className="mbottom30">
                  <label className={form.description.error ? 'errorField' : ''}>
                    Nombre del tamaño
                    <Input
                      type="text"
                      name="description"
                      minLength="2"
                      maxLength="25"
                      required={true}
                      placeholder="Nombre del tamaño"
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.description.value}
                    />
                    <span className="countCharacters dblock text-right font15">
                      {form.description.value?.length}/25
                    </span>
                  </label>
                </div>
              </div>

              <div className="col-4">
                {Number(form.sizeTypeId) === 0 ? (
                  <div className="mbottom30">
                    <label className="has-tooltip-inline pb-1">Tamaño en onzas</label>
                    <div className={form.sizeAdm.error ? 'unit-of-measurement__input_error' : 'unit-of-measurement__input'} >
                      <label >
                        <input
                          className="unit-right"
                          name="sizeAdm"
                          type="number"
                          placeholder="00"
                          min={0}
                          value={form.sizeAdm.value}
                          onChange={handlerSizeAdm}
                          onBlur={handleValidateSizeAdm}
                          required
                        />
                        oz
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className="mbottom30">
                    <label className={form.sizeAdmText.error ? 'errorField' : ''}>
                      Tamaño
                      <Input
                        type="text"
                        name="sizeAdmText"
                        minLength="2"
                        maxLength="25"
                        required={true}
                        placeholder="Tamaño"
                        onBlur={handleValidate}
                        onChange={handleChange}
                        value={form.sizeAdmText.value}
                      />
                      <span className="countCharacters dblock text-right font15">
                        {form.sizeAdmText.value?.length}/25
                      </span>
                    </label>
                  </div>
                )}
              </div>
            </div>
            {!productSizeID ?
              <div className="text-center">
                <Button
                  loading={loading}
                  disabled={!validForm}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                >
                  Guardar tamaño
                </Button>
              </div>
              :
              <div className="flex flex--between">
                <Button
                  type="button"
                  className="cta cta--secondary-plain"
                  onClick={() => setShowModal(true)}
                >
                  Eliminar tamaño
                </Button>
                <Button
                  loading={loading}
                  disabled={!validForm || !form.formTouched}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                >
                  Guardar cambios
                </Button>
              </div>
            }
          </Form>
        </div>
      </div>
      <DeleteModal
        show={showModal}
        id={productSizeID}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        handleDelete={deleteProductSize}
        withContent={false}
        isLoading={loading}
      />
    </>
  );
}
