import React from 'react';

export const ProductSelectItem = ({
  // key,
  menuItemID,
  name,
  handleActiveProduct,
  listActivated,
}) => {
  return (
    <div div key={menuItemID} className="select-drag__item-select">
      {name}{' '}
      <button
        type="button"
        onClick={() => {
          handleActiveProduct({
            name,
            menuItemID
          });
        }}
      >
        +
      </button>
    </div>
  );
};
