import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { types } from '../../../types/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VersionGroup } from './components/VersionGroup';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { updateCategory } from '../../../actions/category';
import { Accordion, Button, Icon, Input } from 'semantic-ui-react';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { ProductByCategories } from './components/ProductByCategories';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { productGetByCategories, productSizeGetAll, versionGroupGetAll, subProductGroupGetAll, versionGroupUpdate, requestUpdate } from '../../../actions/product';

export const Products = () => {
  /***  Selector  ***/
  const dispatch = useDispatch();
  const { key } = useSelector((state) => state.key);

  /***  Variables  ***/
  const [tabIndex, setTabIndex] = useState(0);

  // Productos by Category
  const productByCategoriesListRedux = useSelector((state) => state.product.productByCategories);
  const [productByCategoriesList, setProductByCategoriesList] = useState([]);
  // console.log('productByCategoriesList', productByCategoriesList);

  // Tamaño de Productos -> productSizes
  const productSizeListRedux = useSelector((state) => state.product.productSizesAll);
  const [productSizeList, setProductSizeList] = useState([]);

  // Grupos de versiones -> substitution-groups
  const versionGroupListRedux = useSelector((state) => state.product.versionGroupsAll);
  const [versionGroupList, setVersionGroupList] = useState([]);

  // Grupo de Sub-Productos -> productGroups
  const subProductGroupListRedux = useSelector((state) => state.product.subProductGroupsAll);
  const [subProductGroupList, setSubProductGroupList] = useState([]);

  /***  Funciones  ***/
  // Busqueda
  const [inputFilter, setInputFilter] = useState('');
  const onSearchProduct = (e) => {
    // Agregar categoria con solo los productos coincidente
    e.preventDefault();
    if (inputFilter !== '') {
      let resultsCategory = [];
      productByCategoriesListRedux.forEach((category, index) => {
        let resultsProduct = [];
        category.products.forEach(product => {
          if (
            product.name.toLowerCase().includes(inputFilter.toLowerCase()) ||
            product.nameWeb.toLowerCase().includes(inputFilter.toLowerCase()) ||
            product.symphonyID.toLowerCase().includes(inputFilter.toLowerCase())
          ) {
            resultsProduct = [...resultsProduct, product];
          }
        });
        const existsCategory = resultsCategory.find(x => x.categoryID === productByCategoriesListRedux[index].categoryID);
        if (!existsCategory && resultsProduct.length > 0) {
          resultsCategory = [...resultsCategory, {
            categoryID: category?.categoryID,
            name: category?.name,
            description: category?.description,
            image: category?.image,
            status: category?.status,
            doublePoints: category?.doublePoints,
            schedule: category?.schedule,
            productsCount: resultsProduct.length,
            products: resultsProduct
          }];
        }
      });
      console.log(resultsCategory);
      setProductByCategoriesList(resultsCategory);
    }
  };

  const onSearchCategory = (e) => {
    // Agregar categoria con algun producto coincidente
    e.preventDefault();
    if (inputFilter !== '') {
      let resultsCategory = [];
      productByCategoriesListRedux.forEach((category, index) => {
        let resultsProduct = [];
        category.products.forEach(product => {
          if (
            product.name.toLowerCase().includes(inputFilter.toLowerCase()) ||
            product.nameWeb.toLowerCase().includes(inputFilter.toLowerCase()) ||
            product.symphonyID.toLowerCase().includes(inputFilter.toLowerCase())
          ) {
            const exists = resultsCategory.find(x => x.categoryID === productByCategoriesListRedux[index].categoryID);
            if (!exists) resultsCategory = [...resultsCategory, productByCategoriesListRedux[index]];
          }
        });
      });
      setProductByCategoriesList(resultsCategory);
    }
  };

  const onSearchProductSize = (e) => {
    e.preventDefault();
    if (inputFilter !== '') {
      let results = [];
      productSizeListRedux.forEach((productSize, index) => {
        const data = productSizeListRedux[index].data.filter((x) =>
          x.description.toLowerCase().includes(inputFilter.toLowerCase())
        );

        results = [
          ...results,
          { type: productSize?.type, name: productSize?.name, data: data }
        ];
      });
      setProductSizeList(results);
    }
  };

  const onSearchVersionGroup = (e) => {
    e.preventDefault();
    if (inputFilter !== '') {
      let results = [];
      versionGroupListRedux.forEach((versionGroup, index) => {
        versionGroup.menuItems.forEach(menuItems => {
          if (menuItems.name.toLowerCase().includes(inputFilter.toLowerCase())) {
            const exists = results.find(x => x.substitutionGroupID === versionGroupListRedux[index].substitutionGroupID);
            if (!exists) results = [...results, versionGroupListRedux[index]];
          }
        });
      });
      setVersionGroupList(results);
    }
  };

  const onSearchSubProductGroup = (e) => {
    e.preventDefault();
    if (inputFilter !== '') {
      let results = [];
      subProductGroupListRedux.forEach((subProductGroup, index) => {
        subProductGroup.menuItems.forEach(menuItems => {
          if (menuItems.name.toLowerCase().includes(inputFilter.toLowerCase())) {
            const exists = results.find(x => x.subMenuItemGroupID === subProductGroupListRedux[index].subMenuItemGroupID);
            if (!exists) results = [...results, subProductGroupListRedux[index]];
          }
        });
      });
      setSubProductGroupList(results);
    }
  };

  const handleFilter = (e) => {
    if (e.target.value === '') {
      switch (tabIndex) {
        case 0: setProductByCategoriesList(productByCategoriesListRedux);
          break;
        case 1: setProductSizeList(productSizeListRedux);
          break;
        case 2: setVersionGroupList(versionGroupListRedux);
          break;
        case 3: setSubProductGroupList(subProductGroupListRedux);
          break;
        default:
          break;
      }
    };
    setInputFilter(e.target.value);
  };

  // Accordion
  const [activeAccordion, setActiveAccordion] = useState(0);

  const handleAccordion = (index) => {
    const newIndex = activeAccordion === index ? -1 : index;
    setActiveAccordion(newIndex);
  };

  const handleOnDragProductByCategories = (result) => {
    if (inputFilter == '') {
      let category = productByCategoriesListRedux.find((category) =>
        category?.categoryID === +result?.destination?.droppableId
      );
      const items = category?.products;

      if (category?.products.length > 0 && items.length > 0) {
        const [reorderedItems] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItems);

        items.map((item, index) => {
          items[index].order = index;
        });

        const Category = {
          categoryID: +category?.categoryID,
          name: category?.name,
          description: category?.description,
          image: category?.image,
          status: Number(category?.status),
          doublePoints: category?.doublePoints,
          schedule: category?.schedule,
          products: items
        };

        const dataCategoryUpdate = JSON.stringify({
          requestContent: {
            Category: Category
          }
        });

        dispatch(updateCategory(dataCategoryUpdate, key, Category));
      } else {
        console.error("No hay productos en esta categoria.");
      }
    } else {
      toast.error('Acción no permitida cuando esta activa la busqueda.');
    }
  };

  const handleOnDragVersionGroup = (result) => {
    let versionGroup = versionGroupListRedux.find((versionGroup) =>
      versionGroup?.substitutionGroupID === +result?.destination?.droppableId
    );
    const items = versionGroup?.menuItems;

    if (versionGroup?.menuItems.length > 0 && items.length > 0) {
      const [reorderedItems] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItems);

      items.map((item, index) => {
        items[index].order = index;
      });

      const substitutionGroups = {
        substitutionGroupID: +versionGroup?.substitutionGroupID,
        status: versionGroup?.status,
        name: versionGroup?.name,
        description: versionGroup?.description,
        menuItems: items
      };

      const dataCondiment = JSON.stringify({
        requestContent: { substitutionGroups }
      });

      dispatch(versionGroupUpdate(dataCondiment, key, substitutionGroups));
    } else {
      console.error("No hay productos en este grupo de versiones.");
    }
  };

  const handleOnDragSubProductGroup = (result) => {
    let subProductGroup = subProductGroupListRedux.find((subProductGroup) =>
      subProductGroup?.subMenuItemGroupID == +result?.destination?.droppableId
    );
    const items = subProductGroup?.menuItems;

    if (subProductGroup?.menuItems.length > 0 && items.length > 0) {
      const [reorderedItems] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItems);

      items.map((item, index) => {
        items[index].order = index;
      });

      const menuItemsGroup = {
        type: 'updated',
        subMenuItemGroupID: +subProductGroup?.subMenuItemGroupID,
        status: subProductGroup?.status,
        name: subProductGroup?.name,
        description: subProductGroup?.description,
        menuItems: items
      };

      const dataMenuItemsGroup = JSON.stringify({
        requestContent: { menuItemsGroup }
      });

      dispatch(requestUpdate('subProductGroup', dataMenuItemsGroup, menuItemsGroup));
    } else {
      console.error("No hay productos en este grupo de subproductos.");
    }
  };

  /***  useEffects ***/
  // Get all data
  useEffect(() => {
    dispatch(productSizeGetAll('{}', key));
    dispatch(versionGroupGetAll('{}', key));
    dispatch(subProductGroupGetAll('{}', key));
    dispatch(productGetByCategories('{}', key));
    dispatch({ type: types.productRequest, payload: {} });
    dispatch({ type: types.productSizeRequest, payload: {} });
    dispatch({ type: types.versionGroupRequest, payload: {} });
    dispatch({ type: types.subProductGroupRequest, payload: {} });
  }, [dispatch]);

  useEffect(() => {
    setProductSizeList(productSizeListRedux);
    setVersionGroupList(versionGroupListRedux);
    setSubProductGroupList(subProductGroupListRedux);
    setProductByCategoriesList(productByCategoriesListRedux);
  }, [productByCategoriesListRedux, productSizeListRedux, versionGroupListRedux, subProductGroupListRedux]);

  return (
    <>
      <h1 className="text-center m-0 pbottom30">Productos</h1>
      <div className="standard-wrap">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => { setTabIndex(index); setInputFilter('') }}>
          <TabList>
            <Tab>Productos</Tab>
            <Tab>Tamaños</Tab>
            <Tab>Grupos de versiones</Tab>
            <Tab>Grupos de subproductos</Tab>
          </TabList>

          {/* Productos */}
          <TabPanel>
            {!productByCategoriesListRedux || productByCategoriesListRedux.length === 0 ? (
              <EmptyState
                title="Productos"
                text={`No hay productos creados. Primero debes proceder a crear un tamaño para poder crear productos.`}
                titleButton="+ Crear tamaño"
                linkButton="/productos/product-size/form/new"
              />
            ) : (
              <>
                <div className="top-filters">
                  <div className="top-filters__left top-filters__left--small">
                    <form onSubmit={onSearchProduct}>
                      <Input
                        list="products"
                        value={inputFilter}
                        onChange={handleFilter}
                        className="search-input"
                        placeholder="Buscar productos..."
                      />
                      <Button>Buscar</Button>
                    </form>
                  </div>
                  <div className="top-filters__right top-filters__right--large top-filters__right--flex-end">
                    <Link className="cta cta--widthAuto margin0" to="/productos/product/form/new">
                      + Crear Producto
                    </Link>
                    {/* <Link className="cta cta--widthAuto margin0" to="/productos/editar/1">Viejo</Link> */}
                  </div>
                </div>
                <div className="accordion-module">
                  {productByCategoriesList.length > 0 ? (
                    <>
                      {productByCategoriesList.map((category, index) => (
                        <Accordion key={index}>
                          <Accordion.Title
                            active={activeAccordion === index}
                            onClick={() => handleAccordion(index)}
                          >
                            <div>
                              {`${category.name} `}
                              <label style={{ color: '#5C5C5C', opacity: 0.8 }}>
                                {category.productsCount === undefined ? '(0' : `(${category.productsCount}`}
                                {category.productsCount == 1 ? ' producto)' : ' productos)'
                                }
                              </label>
                            </div>
                            <span>
                              {activeAccordion === index ? 'Ver menos' : 'Ver más'}
                              <Icon name="dropdown" />
                            </span>
                          </Accordion.Title>
                          <Accordion.Content active={activeAccordion === index} >
                            {category?.products && category?.products?.length > 0 ? (
                              <div className="categories-list">
                                <DragDropContext onDragEnd={handleOnDragProductByCategories} >
                                  <Droppable droppableId={'' + category.categoryID} >
                                    {(provided) => (
                                      <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {category.products.map(
                                          (product, index_2) => {
                                            return (
                                              <ProductByCategories
                                                key={index_2}
                                                index={index_2}
                                                name={product.name}
                                                nameWeb={product.nameWeb}
                                                price={product.price}
                                                image={product.image1}
                                                status={product.status}
                                                productID={product.menuItemID}
                                                symphonyID={product.symphonyID}
                                              />
                                            );
                                          }
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </div>
                            ) : (
                              <div className='text-center'>No hay productos bajo esta categoria.</div>
                            )}
                          </Accordion.Content>
                        </Accordion>
                      ))
                      }
                    </>
                  ) : (
                    <EmptyState
                      title="Productos"
                      linkButton=""
                      titleButton=""
                      text="No hay resultados para esta búsqueda. Intenta buscar otro término."
                    />
                  )}
                </div>
              </>
            )}
          </TabPanel>

          {/* Tamaños */}
          <TabPanel>
            {!productSizeListRedux || productSizeListRedux.length === 0 ? (
              <EmptyState
                title="Tamaños"
                text="No hay tamaños creados. Crea tamaños para poder asignárselos a tus productos cuando lo necesites."
                titleButton="+ Crear tamaño"
                linkButton="/productos/product-size/form/new" />
            ) : (
              <>
                <div className="top-filters">
                  <div className="top-filters__left top-filters__left--small">
                    <form onSubmit={onSearchProductSize}>
                      <Input
                        list="productSizes"
                        value={inputFilter}
                        onChange={handleFilter}
                        className="search-input"
                        placeholder="Buscar tamaños..."
                      />
                      <Button>Buscar</Button>
                    </form>
                  </div>
                  <div className="top-filters__right top-filters__right--large top-filters__right--flex-end">
                    <Link className="cta cta--widthAuto margin0" to="/productos/product-size/form/new">
                      + Crear tamaño
                    </Link>
                  </div>
                </div>
                <div className="accordion-module">
                  {productSizeList[0]?.data.length > 0 || productSizeList[1]?.data.length > 0 ? (
                    <>
                      {productSizeList.map((productSize, index) => (
                        <Accordion key={index}>
                          <Accordion.Title
                            active={activeAccordion === index}
                            onClick={() => handleAccordion(index)}
                          >
                            <div>
                              {`${productSize.name} `}
                              <label style={{ color: '#5C5C5C', opacity: 0.8 }}>
                                ({productSize?.data?.length} tamaños)
                              </label>
                            </div>
                            <span>
                              {activeAccordion === index ? 'Ver menos' : 'Ver más'}
                              <Icon name="dropdown" />
                            </span>
                          </Accordion.Title>
                          <Accordion.Content active={activeAccordion === index}>
                            {productSize?.data && productSize?.data?.length > 0 ? (
                              <div className="accordion-module" style={{ paddingTop: '0px' }}>
                                <div className="categories-list categories-list--general" style={{ paddingTop: '0px' }}>
                                  {productSize?.data.map((_productSize, index) => (
                                    <div
                                      key={index}
                                      style={{ width: '293px' }}
                                      className="category-item category-item--general"
                                    >
                                      <div className="category-item__right category-item__right">
                                        <h3>{_productSize?.description}</h3>
                                        <p className="condiment-specs mb-5">
                                          {_productSize?.sizeAdm} {_productSize?.sizeType?.typeID === 0 && 'onzas'}
                                        </p>
                                        <Link to={`/productos/product-size/form/${_productSize?.sizeID}`}>
                                          <span className="icon-edit"></span>
                                        </Link>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <div className='text-center'>No hay productos bajo esta categoria.</div>
                            )}
                          </Accordion.Content>
                        </Accordion>
                      ))
                      }
                    </>
                  ) : (
                    <EmptyState
                      title="Productos"
                      linkButton=""
                      titleButton=""
                      text="No hay resultados para esta búsqueda. Intenta buscar otro término."
                    />
                  )}
                </div>
              </>
            )}
          </TabPanel>

          {/* Grupos de versiones */}
          <TabPanel>
            {!versionGroupListRedux || versionGroupListRedux.length === 0 ? (
              <EmptyState
                title="Grupos de versiones"
                text="¡No hay grupos de versiones creados! Puedes crearlos aquí."
                titleButton="+ Crear grupo de versiones"
                linkButton="/productos/group-version/form/new"
              />
            ) : (
              <>
                <div className="top-filters">
                  <div className="top-filters__left top-filters__left--small">
                    <form onSubmit={onSearchVersionGroup}>
                      <Input
                        list="products"
                        value={inputFilter}
                        onChange={handleFilter}
                        className="search-input"
                        placeholder="Buscar grupos de versiones..."
                      />
                      <Button>Buscar</Button>
                    </form>
                  </div>
                  <div className="top-filters__right top-filters__right--large top-filters__right--flex-end">
                    <Link className="cta cta--widthAuto margin0" to="/productos/group-version/form/new">
                      + Crear grupo de versiones
                    </Link>
                  </div>
                </div>
                <div className="accordion-module">
                  {versionGroupList.length > 0 ? (
                    <>
                      {versionGroupList.map((versionGroup, index) => (
                        <Accordion key={index}>
                          <Accordion.Title
                            active={activeAccordion === index}
                            onClick={() => handleAccordion(index)}
                          >
                            <div>
                              {`${versionGroup.name} `}
                              <label style={{ color: '#5C5C5C', opacity: 0.8 }}>
                                ({versionGroup.menuItems.length} productos)
                              </label>
                            </div>
                            <span>
                              {activeAccordion === index ? 'Ver menos' : 'Ver más'}
                              <Icon name="dropdown" />
                            </span>
                          </Accordion.Title>
                          <Accordion.Content active={activeAccordion === index} >
                            {versionGroup?.menuItems && versionGroup?.menuItems?.length > 0 ? (
                              <div className="categories-list">
                                <DragDropContext onDragEnd={handleOnDragVersionGroup} >
                                  <Droppable droppableId={'' + versionGroup.substitutionGroupID} >
                                    {(provided) => (
                                      <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {versionGroup.menuItems.map(
                                          (menuItems, index_2) => {
                                            return (
                                              <VersionGroup
                                                key={index_2}
                                                index={index_2}
                                                name={menuItems.name}
                                                price={menuItems.price}
                                                image={menuItems?.image1}
                                                status={menuItems.status}
                                                productID={menuItems.menuItemID}
                                                symphonyID={menuItems.simphonyID}
                                                description={menuItems.description}
                                              />
                                            );
                                          }
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </div>
                            ) : (
                              <div className='text-center'>No hay productos bajo esta categoria.</div>
                            )}

                            <div className="text-right">
                              <Link
                                to={`/productos/group-version/form/${versionGroup.substitutionGroupID}`}
                                className="cta cta--tiny cta--h30 cta--secondary-light margin0"
                              >
                                <span className="icon-edit mright5"></span>
                                Editar grupo
                              </Link>
                            </div>
                          </Accordion.Content>
                        </Accordion>
                      ))
                      }
                    </>
                  ) : (
                    <EmptyState
                      title="Productos"
                      linkButton=""
                      titleButton=""
                      text="No hay resultados para esta búsqueda. Intenta buscar otro término."
                    />
                  )}
                </div>
              </>
            )}
          </TabPanel>

          {/* Grupos de subproductos */}
          <TabPanel>
            {!subProductGroupListRedux || subProductGroupListRedux.length === 0 ? (
              <EmptyState
                title="Grupos de versiones"
                text="¡No hay grupos de versiones creados! Puedes crearlos aquí."
                titleButton="+ Crear grupo de versiones"
                linkButton="/productos/crear-grupo-versiones"
              />
            ) : (
              <>
                <div className="top-filters">
                  <div className="top-filters__left top-filters__left--small">
                    <form onSubmit={onSearchSubProductGroup}>
                      <Input
                        list="products"
                        value={inputFilter}
                        onChange={handleFilter}
                        className="search-input"
                        placeholder="Buscar grupos de subproductos..."
                      />
                      <Button>Buscar</Button>
                    </form>
                  </div>
                  <div className="top-filters__right top-filters__right--large top-filters__right--flex-end">
                    <Link className="cta cta--widthAuto margin0" to="/productos/subproduct-group/form/new">
                      + Crear grupo de subproductos
                    </Link>
                  </div>
                </div>
                <div className="accordion-module">
                  {subProductGroupList.length > 0 ? (
                    <>
                      {subProductGroupList.map((subProductGroup, index) => (
                        <Accordion key={index}>
                          <Accordion.Title
                            active={activeAccordion === index}
                            onClick={() => handleAccordion(index)}
                          >
                            <div>
                              {`${subProductGroup.name} `}
                              <label style={{ color: '#5C5C5C', opacity: 0.8 }}>
                                ({subProductGroup.menuItems.length} productos)
                              </label>
                            </div>
                            <span>
                              {activeAccordion === index ? 'Ver menos' : 'Ver más'}
                              <Icon name="dropdown" />
                            </span>
                          </Accordion.Title>
                          <Accordion.Content active={activeAccordion === index} >
                            {subProductGroup?.menuItems && subProductGroup?.menuItems?.length > 0 ? (
                              <div className="categories-list">
                                <DragDropContext onDragEnd={handleOnDragSubProductGroup} >
                                  <Droppable droppableId={'' + subProductGroup.subMenuItemGroupID} >
                                    {(provided) => (
                                      <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {subProductGroup.menuItems.map(
                                          (menuItems, index_2) => {
                                            return (
                                              <VersionGroup
                                                key={index_2}
                                                index={index_2}
                                                name={menuItems.name}
                                                price={menuItems.price}
                                                image={menuItems?.image1}
                                                status={menuItems.status}
                                                productID={menuItems.menuItemID}
                                                symphonyID={menuItems.simphonyID}
                                                description={menuItems.description}
                                              />
                                            );
                                          }
                                        )}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </div>
                            ) : (
                              <div className='text-center'>No hay productos bajo esta categoria.</div>
                            )}
                            <div className="text-right">
                              <Link
                                to={`/productos/subproduct-group/form/${subProductGroup.subMenuItemGroupID}`}
                                className="cta cta--tiny cta--h30 cta--secondary-light margin0"
                              >
                                <span className="icon-edit mright5"></span>
                                Editar grupo
                              </Link>
                            </div>
                          </Accordion.Content>
                        </Accordion>
                      ))
                      }
                    </>
                  ) : (
                    <EmptyState
                      title="Productos"
                      linkButton=""
                      titleButton=""
                      text="No hay resultados para esta búsqueda. Intenta buscar otro término."
                    />
                  )}
                </div>
              </>
            )}
          </TabPanel>
        </Tabs>
      </div>
    </>
  )
};
