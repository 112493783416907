import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { types } from '../../../types/types';
import React, { useEffect, useState } from 'react';
import { full_loading } from '../../../actions/ui';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Radio } from 'semantic-ui-react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { startGetAllUsers } from '../../../actions/admins';
import { reduxUpdateUser } from '../../../actions/admins';
import { singleFetchService } from '../../../helpers/functions';
import { EmptyState } from '../../../components/EmptyState/EmptyState';

export const ListUsers = () => {
  const itemsPerPage = 12;
  const dispatch = useDispatch();
  const { key } = useSelector((state) => state.key);
  const { users } = useSelector((state) => state.users);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentUsers, setCurrentUsers] = useState([]);
  const loading = useSelector((state) => state.ui.full_loading);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setCurrentUsers(users.slice(startIndex, endIndex));
  }, [currentPage, users]);

  useEffect(() => {
    const data = JSON.stringify({});
    dispatch(startGetAllUsers(data, key));
    dispatch({ type: types.userRequest, payload: {} });
  }, [dispatch, key]);

  const handleSearch = (e) => {
    e.preventDefault();

    const userData = {
      requestContent: {
        filter: e.target[0].value
      }
    }

    dispatch(startGetAllUsers(JSON.stringify(userData), key));
  };

  const handleSearchReturn = (e) => {
    e.preventDefault();

    if (e.target.value.length === 0) {
      dispatch(startGetAllUsers('{}', key));
    }
  };

  const handleStatusChange = async (e, index) => {
    dispatch(full_loading(true));
    const status = !currentUsers[index].status;

    const userData = {
      requestContent: {
        userAdmin: {
          status: status,
          id: Number(currentUsers[index].UserID),
        }
      }
    }

    const body = await singleFetchService('/user-admin/v1/status/change', JSON.stringify(userData), key)
      .then((response) => {
        const ref_user = [...currentUsers];
        if (response?.info?.type === 'success') {
          ref_user[index].status = status;
          toast.success(response?.info?.message || 'Usuario actualizado');
        } else {
          ref_user[index].status = !status;
          toast.error(response?.info?.message || 'Usuario no actualizado');
        }
        setCurrentUsers(ref_user);
        dispatch(reduxUpdateUser(response));
        dispatch(full_loading(false));
      })
      .catch((err) => {
        console.error(err)
        dispatch(full_loading(false));
        dispatch(reduxUpdateUser(userData));
        toast.error(err?.info?.message || 'Usuario no actualizado');
      });
  }

  return (
    <>
      {currentUsers && currentUsers.length > 0 ? (
        <div className="full-wrap">
          <h1 className="text-center m-0 pbottom30">Usuarios</h1>
          <Tabs selectedIndex={0} onSelect={() => { }}>
            <TabPanel>
              <div className="top-filters">
                <div className="top-filters__left top-filters__left--small">
                  <form onSubmit={handleSearch}>
                    <Input
                      list="categories"
                      className="search-input"
                      placeholder="Buscar un usuario..."
                      onChange={handleSearchReturn}
                    />
                    <Button>Buscar</Button>
                    {/* <Button onClick={handleSearch}>Buscar</Button> */}
                  </form>
                </div>
                <div className="top-filters__right top-filters__right--large top-filters__right--flex-end">
                  <Link className="cta cta--widthAuto margin0" to="/usuarios/form/new">
                    + Crear Usuario
                  </Link>
                </div>
              </div>
              <div className="accordion-module">
                <div className="wrap-rows wrap-rows--header  wrap-rows--6columns">
                  {/* Columnas */}
                  <div className="column column-1">Nombres</div>
                  <div className="column column-2">Apellidos</div>
                  <div className="column column-3">Correo electrónico</div>
                  <div className="column column-4">Rol</div>
                  <div className="column column-5">Estatus</div>
                  <div className="column column-6">Acciones</div>
                </div>
                {/* Renderizar los usuarios de la página actual */}
                {currentUsers.map((user, index) => (
                  <div key={index} className="wrap-rows wrap-rows--6columns wrap-rows--locations">
                    {/* Contenido de cada usuario */}
                    <div className="column column-1">{user.name}</div>
                    <div className="column column-2">{user.surname}</div>
                    <div className="column column-3">{user.email}</div>
                    <div className="column column-4">{user.rol?.name}</div>
                    <div className="column column-5">
                      <Radio toggle value={user.status} checked={user.status === true} onChange={(e) => handleStatusChange(e, index)} disabled={loading} />
                    </div>
                    <div className="column column-6">
                      <Link to={`/usuarios/form/${user.UserID}`} className="link-orange">
                        <span className="icon-edit"></span> Editar
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>
          </Tabs>
          {/* Componente de paginación */}
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            previousLabel="<"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(users.length / itemsPerPage)}
            renderOnZeroPageCount={null}
            className="pagination"
          />
        </div>
      ) : (
        <EmptyState
          title="Usuarios"
          text="No hay Usuarios creados. Comienza creando uno."
          titleButton="+ Crear Usuario"
          linkButton="/usuarios/form/new"
        />
      )}
    </>
  );
};