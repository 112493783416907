import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { CondimentSingle } from './CondimentSingle';

export const ListCondiments = ({ condimentsList }) => {
  // Pagination

  const itemsPerPage = 10;
  const [currentCondiments, setCurrentCondiments] = useState(condimentsList);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentCondiments(condimentsList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(condimentsList.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, condimentsList]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % condimentsList.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <CondimentSingle condimentsList={currentCondiments} />
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        previousLabel="<"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        className="pagination"
      />
    </>
  );
};
