import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { QrReader } from 'react-qr-reader';
import { full_loading } from '../../../actions/ui';
import { Form, Input, Button, Modal, Message } from 'semantic-ui-react';

const sendQRPaymentData = async (qrPaymentData, token) => {
  // Reemplaza con la URL correcta
  const Base = process.env.REACT_APP_BASE;
  const apiUrl = `${Base}/qr/v1/pay`;
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(qrPaymentData),
    });

    const data = await response.json();

    if (response.ok) {
      toast.success('Pago realizado con Éxito', data);
      return data;
    } else {
      console.error(data);
      toast.error(data?.info?.message || 'Pago realizado con Éxito');
      return data
      // throw new Error(data?.info?.message || 'Error desconocido');
    }
  } catch (error) {
    console.log(error);
    toast.error('Error en la solicitud');
    // toast.error(error?.message || 'Error en la solicitud');
    throw new Error(error);
  }
};

const CobrarConQR = () => {
  const dispatch = useDispatch();
  const [orderNumber, setOrderNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [scannedResult, setScannedResult] = useState('');
  const [selectedCamera, setSelectedCamera] = useState('user');
  const [orderNumberError, setOrderNumberError] = useState('');

  const handleOrderNumberChange = (e) => {
    const value = e.target.value.replace(/[^\w\s]/gi, '').toUpperCase();
    setOrderNumber(value);
    validateInputs(value, amount);

    if (value.length < 3 || value.length > 20) {
      setOrderNumberError('El número de orden debe tener entre 3 y 20 caracteres.');
    } else {
      setOrderNumberError('');
    }
  };

  const [amountError, setAmountError] = useState('');

  const handleAmountChange = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, '');
    const decimalCount = (value.match(/\./g) || []).length;

    if (decimalCount > 1) {
      value = value.slice(0, -1);
    }

    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      value = `${integerPart.slice(0, 5)}.${decimalPart.slice(0, 2) || ''}`;
    } else {
      value = value.slice(0, 5);
    }

    // Verificar el valor mínimo de Q1.00
    const parsedValue = parseFloat(value);
    if (parsedValue < 1) {
      setAmountError('El monto mínimo a Cobrar es Q1.00');
    } else {
      setAmountError(''); // Si el monto es válido, elimina el mensaje de error
    }

    setAmount(value);
    validateInputs(orderNumber, value);
  };


  const validateInputs = (order, amt) => {
    setIsValid(order.trim().length > 0 && amt.trim().length > 0);
  };

  const handleContinue = () => {
    console.log('Orden: ', orderNumber, 'Monto a pagar: ', amount);
    setModalOpen(true);
  };

  const handleScan = async (data) => {
    // Acceso a la camara.
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
      stream.getTracks().forEach((track) => track.stop());

      if (data) {
        dispatch(full_loading(true));
        setScannedResult(data);
        setModalOpen(false);
        try {
          const parsedAmount = parseFloat(amount);

          // Monto no válido
          if (isNaN(parsedAmount)) {
            toast.error('El monto ingresado no es válido');
            return;
          }

          const qrPaymentData = {
            requestContent: {
              qrPayment: {
                adminID: 35,
                storeID: 3,
                orderID: orderNumber,
                voucherCode: data.text, // Utiliza el texto del resultado del escaneo
                totalAmount: parsedAmount
              }
            }
          };
          const token = localStorage.getItem('sessionId');
          const response = await sendQRPaymentData(qrPaymentData, token);
          dispatch(full_loading(false));
          toast.success(response);
          clearModel();
        } catch (error) {
          dispatch(full_loading(false));
          clearModel();
        }
      }
    } catch (error) {
      toast.error('No se puede acceder a la cámara. Asegúrate de tener una cámara instalada y los permisos necesarios', { autoClose: 5000, });
      setModalOpen(false);
      return;
    }
  };

  const clearModel = () => {
    setAmount('');
    setOrderNumber('');
  }

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div className="container pbottom50">
      <div className="medium-wrap" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1 className="text-center m-0 pbottom30">Cobrar con QR</h1>
        <Form onSubmit={handleContinue} className="general-form">
          <div className="mbottom30">
            <label>
              Número de orden
              <Input
                type="text"
                name="orderNumber"
                placeholder="Solamente ingresa los dígitos"
                onChange={handleOrderNumberChange}
                value={orderNumber}
                className="wdinput-qrpay"
                required
              />
            </label>
            {orderNumberError && (
              <Message negative className="wdinput-qrpay">
                {orderNumberError}
              </Message>
            )}
          </div>
          <div className="mbottom30">
            <label>
              Monto a pagar
              <Input
                type="text"
                name="amount"
                placeholder="Solamente ingresa los dígitos"
                onChange={handleAmountChange}
                value={amount}
                className="wdinput-qrpay"
                required
              />
              {/* Dentro de tu componente */}
              {amountError && (
                <Message negative className="wdinput-qrpay">
                  {amountError}
                </Message>
              )}
            </label>
          </div>
          <div className="text-center">
            <Button className="cta" disabled={!isValid || !orderNumber || !amount || amountError || orderNumberError} >
              Continuar
            </Button>
          </div>
        </Form>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <Modal.Header className="text-center header-modal-bk">Escanea el código QR</Modal.Header>
          <Modal.Content className="header-modal-bk">
            <QrReader
              onResult={handleScan}
              onError={handleError}
              style={{ width: '50%' }}
              willReadFrequently={false}
              facingMode={selectedCamera}
            />
          </Modal.Content>
        </Modal>
      </div>
    </div>
  );
};

export default CobrarConQR;
