import { types } from '../types/types';

const initialState = {
  create: {
    name: '',
    description: '',
    categories: [],
  },
  list: null,
};

export const groupCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.groupCategoryAll:
      return {
        ...state,
        list: [...action.payload],
      };
    case types.groupCategoryCreate:
      return {
        ...state,
        create: {
          ...action.payload,
        },
      };
    case types.groupCategoryById:
      return {
        ...state,
        create: {
          ...action.payload,
        },
      };
    case types.groupCategoryUpdate:
      return {
        ...state,
        create: {
          ...action.payload,
        },
      };
    case types.groupCategoryDelete:
      return {
        ...state,
        create: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
