import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

export const Loading = () => {
  return (
    <div className="loading">
      <Segment>
        <Dimmer active className="barista-loading">
          <Loader />
        </Dimmer>
      </Segment>
    </div>
  );
};
