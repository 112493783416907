/*import { types } from '../types/types';

const initialState = {
  key: localStorage.getItem('sessionId') || initialState.key,
};

export const keyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.uiKey:
      return {
        key: action.payload,
      };
    default:
      return state;
  }
};*/


import { types } from '../types/types';

const storedSessionId = localStorage.getItem('sessionId');

const initialState = {
  key: storedSessionId || null,
};

export const keyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.uiKey:
      return {
        key: action.payload,
      };
    default:
      return state;
  }
};
