// Return categoies format

export const prepareCondimentGroups = (groups = []) => {
  return groups.map((e) => ({
    condimentGroupID: e.condimentGroupId,
    name: e.name,
    description: e.description,
    minimun_selection: e.minimumSelection,
    maximun_selection: e.maximumSelection,
    status: e.status,
    nameWeb: e.nameWeb,
    condiments: e.condiments,
    products: e.condimentsCount,
  }));
};
