// Return categoies format

export const prepareGroups = (groups = []) => {
  return groups.map((e) => ({
    categoriesGroupID: e.categoriesGroupID,
    name: e.name,
    description: e.description,
    categories: e.categories,
  }));
};
