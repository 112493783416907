// Return categoies format

export const prepareUsers = (users = []) => {
  return users.map((e) => ({
    UserID: e?.id,
    name: e?.name,
    email: e?.email,
    surname: e?.surname,
    store: e?.store,
    status:e?.status,
    storeID:e?.storeID,
    rol: e.id ? {
      name: e.rol.name,
      description: e.rol.description,
    } : null,
  }));
}
