import { types } from '../types/types';

const initialState = {
  users: [],
  loading: false,
  error: null,

  request: {},
  created: {},
  updated: {},
  deleted: {},
  userAll: [],
  userById: {}
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.usersAll:
      return {
        ...state,
        users: [...action.payload],
      };
    case types.userAll:
      return {
        ...state,
        userAll: [...action.payload],
      };
    case types.userCreated:
      return {
        ...state,
        created: {
          ...action.payload,
        },
      };
    case types.userUpdated:
      return {
        ...state,
        updated: {
          ...action.payload,
        },
      };
    case types.userDeleted:
      return {
        ...state,
        deleted: {
          ...action.payload,
        },
      };

    case types.userRequest:
      return {
        ...state,
        request: {
          ...action.payload,
        },
      };
    case types.userById:
      return {
        ...state,
        userById: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
