import React from 'react';
import { Link } from 'react-router-dom';

export const CondimentSingle = ({ condimentsList }) => {
  return (
    <>
      {condimentsList.map((condiments, index) => {
        return (
          <div className="category-item category-item--condiment" key={index}>
            <div className="category-item__right">
              <h3>
                {condiments?.nameWeb?.length === 0
                  ? 'Sin nombre admin'
                  : condiments.nameWeb}{' '}
                <span>({condiments.name})</span>
              </h3>
              <p className="condiment-specs">
                <span>
                  Micros #{condiments.microsID} | Symphony #
                  {condiments.symphonyID}
                </span>
                <span>Q {condiments.price}</span>
                <span>Estado: {condiments.status ? 'Activo' : 'Inactivo'}</span>
              </p>
              <Link to={`/condimentos/form/${condiments.condimentID}`}>
                <span className="icon-edit"></span>
              </Link>
            </div>
          </div>
        );
      })}
    </>
  );
};
