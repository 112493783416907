import React from 'react';

export const CondimentSelectItem = ({
  subCondimentID,
  name,
  handleActiveCategory,
  listActivated,
}) => {
  return (
    <div className="select-drag__item-select">
      {name}{' '}
      <button
        type="button"
        onClick={() => {
          handleActiveCategory({
            subCondimentID,
            name,
          });
          // handlerCategoryAdded();
        }}
        disabled={Object.keys(listActivated).length > 0 ? true : false}
      >
        +
      </button>
    </div>
  );
};
