import validator from 'validator';
import ImageUploading from 'react-images-uploading';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { singleFetchService } from '../../../helpers/functions';
import { Button, Form, Input } from 'semantic-ui-react';
import { BackRelative } from '../../../components/Back/BackRelative';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import { createStoreType, updateStoreType, deleteStoreType } from '../../../actions/store';

const initialFormState = {
  name: '',
  desc: '',
};

const initialData = {
  status: 'A',
  typeName: {
    value: '',
    error: false
  },
  description: {
    value: '',
    error: false
  },
  image: {
    url: '',
    name: '',
    base64: '',
    error: false
  }
}

export const FormBranchOffice = () => {
  /***  Selector  ***/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let key = useSelector((state) => state.key.key);
  const { loading } = useSelector((state) => state.ui);
  const branchOfficeRequest = useSelector((state) => state.store.storeRequest);

  /***  Variables ***/
  // Formulario
  const [form, setForm] = useState(initialData);
  const [imageList, setImageList] = useState([]);
  const [validForm, setValidForm] = useState(false);

  // Obtener parametro GET (condimentGroup)
  const location = useLocation();
  const storeTypeID = (location.pathname.match(/\d+/) || [])[0] || undefined;

  // Modal states
  const [showModal, setShowModal] = useState(false);
  const resumeModal = 'No podrás revertir esta acción.';
  const titleModal = '¿Estás seguro que deseas eliminar este tipo de sucursal?';

  // Funciones de Formulario
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });
  };

  const onChangeImage = (imageList) => {
    form.image = {
      error: false,
      url: imageList[0].data_url,
      name: imageList[0].file.name,
      base64: imageList[0].data_url,
    }
    setImageList(imageList);
  };

  // Obtener un tipo de tienda
  const getOneStoreType = () => {
    const data = JSON.stringify({
      requestContent: {
        storeType: { typeID: +storeTypeID }
      }
    });

    const body = singleFetchService('/storeTypes/v1/id', data, key)
      .then((response) => {
        const res = response.responseContent;

        setForm({
          typeName: { value: res?.typeName || '', error: false },
          description: { value: res?.description || '', error: false },
          status: res?.status,
          image: {
            url: res?.image?.url || '',
            name: res?.image?.name || '',
            base64: res?.image?.base64 || '',
            error: false
          }
        });
      })
      .catch((err) => console.log(err));
    return body;
  };

  // Guardar tipo de tienda
  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.typeName.value.length <= 2 || form.description.value.length <= 7) {
      setValidForm(false);
      return;
    } else {
      const storeTypeData = {
        requestContent: {
          storeType: {
            typeID: Number(storeTypeID) || undefined,
            type: !storeTypeID ? 'create' : 'update',
            status: form.status,
            typeName: form.typeName.value,
            description: form.description.value,
            image: {
              base64: form.image.base64
            }
          }
        }
      };

      if (!storeTypeID) {
        delete storeTypeData.requestContent.storeType.typeID;
        dispatch(createStoreType(JSON.stringify(storeTypeData), key, storeTypeData));
      } else {
        dispatch(updateStoreType(JSON.stringify(storeTypeData), key, storeTypeData));
      }
    }
  };

  // Eliminar grupo
  const deleteGroup = (id) => {
    const storeType = {
      status: form.status,
      typeID: +storeTypeID,
      typeName: form.typeName.value,
      description: form.description.value
    }

    const data = JSON.stringify({
      requestContent: { storeType: storeType }
    });

    dispatch(deleteStoreType(data, key, storeType));
  };

  /***  useEffects ***/
  useEffect(async () => {
    if (storeTypeID != undefined) getOneStoreType();
  }, [storeTypeID]);

  useEffect(() => {
    if (form.typeName.value.length <= 1 || form.description.value.length <= 1) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [form]);

  // Re-direccionar
  useEffect(() => {
    if (branchOfficeRequest?.info?.type === 'success') navigate('/ubicaciones');
  }, [branchOfficeRequest]);

  /*****************  OLD ******************/
  /*
  const handleInputChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'name' || e.target.name === 'desc') {
      if (validator.isEmpty(e.target.value)) {
        setBlankInput(true);
      } else {
        setBlankInput(false);
      }
    }

    setInputsForm({
      ...inputsForm,
      [e.target.name]: e.target.value,
    });
    // debugger;
  };
  */

  return (
    <>
      <div className="container">
        <BackRelative url="/ubicaciones" />
        <div className="large-wrap">
          <h1 className="text-center m-0 pbottom30">
            {!storeTypeID ? 'Crear sucursal' : 'Editar sucursal'}
          </h1>
          <Form onSubmit={handleSubmit} className="general-form">
            <div className="mbottom30">
              <label className={form.typeName.error ? 'errorField' : ''}>
                Nombre de sucursal
                <Input
                  type="text"
                  name="typeName"
                  placeholder="Nombra este tipo de sucursal"
                  minLength="1"
                  maxLength="35"
                  required={true}
                  onBlur={handleValidate}
                  onChange={handleChange}
                  value={form.typeName.value}
                />
                <span className="countCharacters dblock text-right font15">
                  {form?.typeName?.value.length}/35
                </span>
              </label>
            </div>
            <div className="mbottom30">
              <label className={form.description.error ? 'errorField' : ''}>
                Descripción
                <textarea
                  rows="30"
                  name="description"
                  placeholder="Proporciona una descripción breve..."
                  minLength="1"
                  maxLength="135"
                  required={true}
                  onBlur={handleValidate}
                  onChange={handleChange}
                  value={form.description.value}
                ></textarea>
                <span className="countCharacters dblock text-right font15">
                  {form?.description?.value.length}/135
                </span>
              </label>
            </div>
            <div className="mbottom30">
              <ImageUploading
                // multiple
                name='image'
                maxNumber={1}
                value={imageList}
                onChange={onChangeImage}
                dataURLKey="data_url"
                acceptType={['jpg', 'png', 'jpeg']}
              >
                {({ imageList, onImageUpload, onImageUpdate, isDragging, dragProps }) => (
                  <div className="upload__image-wrapper">
                    <label className="dblock pbottom15 has-tooltip-inline">
                      Imagen
                      <Tooltip text="Medida 331x176px" />
                    </label>
                    <button
                      type="button"
                      className={`cta cta--secondary-light cta--tiny margin0 ${imageList.length > 0 ? 'dnone' : null}`}
                      style={isDragging ? { color: '#333' } : null}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Agregar imagen
                    </button>
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item ptop15">
                        <img src={image.data_url} alt="" width="350" />
                        <div className="image-item__btn-wrapper ptop10">
                          <button type="button" className="link-button" onClick={() => onImageUpdate(index)}>
                            <span className="icon-edit"></span>
                            Cambiar imagen
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </div>
            {!storeTypeID ?
              <div className="text-center">
                <Button
                  loading={loading}
                  disabled={!validForm || imageList.length <= 0}
                  className={`cta cta--secondary ${!validForm || imageList.length <= 0 ? 'disabled' : ''}`}
                >
                  Guardar sucursal
                </Button>
              </div>
              :
              <div className="flex flex--center">
                <Button
                  type="button"
                  className="cta cta--secondary-plain mx-3"
                  onClick={() => setShowModal(true)}
                >
                  Eliminar ubicación
                </Button>
                <Button
                  loading={loading}
                  disabled={!validForm || imageList.length <= 0}
                  className={`cta cta--secondary ${!validForm || imageList.length <= 0 ? 'disabled' : ''}`}
                >
                  Guardar cambios
                </Button>
              </div>
            }
          </Form>
        </div>
      </div>
      <DeleteModal
        show={showModal}
        id={storeTypeID}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        handleDelete={deleteGroup}
        withContent={false}
        isLoading={loading}
      />
    </>
  );
};
