import { revalidateKey } from './key';
import { types } from '../types/types';
import { toast } from 'react-toastify';
import { full_loading, finishLoading, startLoading } from './ui';
import { prepareSchedules } from '../helpers/prepareSchedules';
import { fetchSchedules, fetchService } from '../helpers/fetch';

const data = {
  endpoint: '/productSchedules/v1/',
  altMsjError: '¡La solicitud fallo!',
  altMsjSuccess: '¡La solicitud fue exitosa!',
}

export const setRedux = (type, data) => {
  return {
    payload: data,
    type: types[type]
  };
};

export const schedulesGetAll = (requestType, body) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const request = fetchService(`${data.endpoint}${requestType}`, body)
      .then(async (res) => {
        dispatch(finishLoading());
        const responseContent = await prepareSchedules(res.responseContent);
        dispatch(setRedux('scheduleAll', responseContent));
        res?.info?.type != 'success' && toast.error(res?.info?.message || data.altMsjError);
      })
      .catch((err) => {
        dispatch(finishLoading());
        toast.error(err?.info?.message || data.altMsjError);  
        dispatch(setRedux(`${data.requestName}Request`, err));
      });
  };
};

export const requestDispatch = (requestRoute, requestType, body, subBody) => {
  return async (dispatch) => {
    // dispatch(startLoading());
    dispatch(full_loading(true));
    const request = fetchService(`${data.endpoint}${requestRoute}`, body)
      .then((res) => {
        // dispatch(finishLoading());
        dispatch(full_loading(false));
        dispatch(setRedux(`scheduleRequest`, res));
        dispatch(setRedux(`schedule${requestType}`, subBody));
        res?.info?.type == 'success'
          ? toast.success(res?.info?.message || data.altMsjSuccess)
          : toast.error(res?.info?.message || data.altMsjError);
      })
      .catch((err) => {
        // dispatch(finishLoading());
        dispatch(full_loading(false));
        dispatch(setRedux(`scheduleRequest`, err));
        toast.error(err?.info?.message || data.altMsjError);
      });
  };
};