import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

export const CategoryActiveItem = ({
  listCategoriesActivated,
  setListCategoriesActivated,
  isCategoryAdded,
  setIsCategoryAdded,
}) => {
  const handlerRemoveCategory = (index) => {
    const list = listCategoriesActivated;
    const remove = list.filter((cat) => cat.categoryID !== index);
    setListCategoriesActivated(remove);
  };

  const handlerCategoryAdded = () => {
    setIsCategoryAdded(!isCategoryAdded);
  };

  return listCategoriesActivated.map((cat, index) => (
    <Draggable
      key={'' + cat.categoryID}
      draggableId={'' + cat.categoryID}
      index={index}
    >
      {(provided) => (
        <div
          className="select-drag__item-drag"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <span className="icon-drag mright5"></span>
          {index + 1}. {cat.name}
          <button
            type="button"
            onClick={() => {
              handlerRemoveCategory(cat.categoryID);
              //   handlerCategoryAdded();
            }}
          >
            -
          </button>
        </div>
      )}
    </Draggable>
  ));
};
