import React, { useEffect, useState } from 'react';
//import ReactDOM from 'react-dom';
import { Button, Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import ImageUploading from 'react-images-uploading';
//import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//import validator from 'validator';

import { BackRelative } from '../../../components/Back/BackRelative';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import {
  ProductById,
  startDeleteProduct,
  startUpdateProduct,
} from '../../../actions/product';
import { singleFetchService } from '../../../helpers/functions';
import { LoaderData } from '../../../components/Loader/LoaderData';
// import { allSchedules } from '../../../actions/schedule';

export const EditProduct = () => {
  const dispatch = useDispatch();

  // Get key
  let key = useSelector((state) => state.key.key);
  const { loading } = useSelector((state) => state.ui);
  const { schedules } = useSelector((state) => state.schedule);
  // console.log(key);

  const navigate = useNavigate();
  const location = useLocation();

  const [loadingData, setLoadingData] = useState(true);

  // Get Product id
  const catId = location.pathname.match(/\d+/)[0];

  if (!catId) {
    navigate('/productos');
  }

  // // Get info Product by id
  const [stateForm, setStateForm] = useState({});
  const [images, setImages] = useState([{}]);

  useEffect(() => {
    responseDada();
  }, []);

  var data = JSON.stringify({
    requestContent: {
      Product: {
        ProductID: +catId,
      },
    },
  });
  const responseDada = () => {
    const body = singleFetchService('/categories/v1/id', data, key)
      .then((res) => {
        setStateForm(res.responseContent);
        setImages([{ data_url: res.responseContent.image }]);
        setLoadingData(false);
      })
      .catch((err) => console.log(err));
    return body;
  };
  const {
    ProductID,
    name,
    description,
    status,
    schedule,
    order,
    image,
    products,
  } = stateForm;

  const [validForm, setValidForm] = useState(false);
  useEffect(() => {
    if (
      name?.length === 0 ||
      description?.length === 0 ||
      schedule === '' ||
      status === '' ||
      image?.length < 0
    ) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [name, description, schedule, status]);

  // Images
  // Input file image
  const maxNumber = 1;
  const onChange = (imageList) => {
    // data for submit
    setImages(imageList);
  };

  // List schedules
  const arraySchedule = [];
  const listSchedule = schedules?.map((item) => {
    arraySchedule.push({
      key: item.id,
      value: item.id,
      text: item.name,
    });
  });
  // console.log(schedules);

  const handlerName = (e) => {
    e.preventDefault();
    setStateForm({ ...stateForm, name: e.target.value });
  };

  const handlerDesc = (e) => {
    e.preventDefault();
    setStateForm({ ...stateForm, description: e.target.value });
  };

  const handlerStatus = (e) => {
    e.preventDefault();
    setStateForm({ ...stateForm, status: e.target.value });
  };

  // console.log(inputsForm);
  const onSubmit = (e) => {
    e.preventDefault();
    var data = JSON.stringify({
      requestContent: {
        Product: {
          ProductID: +catId,
          name: name,
          description: description,
          status: +status,
          schedule: schedule,
          order: order,
          image: images[0].data_url,
          products: products,
        },
      },
    });

    // console.log(data.requestContent.Product);

    const Product = {
      ProductID: +catId,
      name: name,
      description: description,
      status: +status,
      schedule: schedule,
      order: order,
      image: images[0].data_url,
      products: products,
    };

    // console.log(data);

    dispatch(startUpdateProduct(data, key, Product));
  };

  //   Modal states
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [resumeModal, setResumeModal] = useState('');
  const [idSchedule, setIdSchedule] = useState(false);

  const handlerModal = (type) => {
    switch (type) {
      case 'delete':
        setTitleModal('¿Estás seguro que deseas eliminar esta categoría?');
        setResumeModal('No podrás revertir esta acción.');
        setShowModal(true);
        setIdSchedule();
        break;
      default:
        setTitleModal(null);
        setResumeModal(null);
        setShowModal(false);
        break;
    }
  };

  const handlerDelete = (Product) => {
    var data = JSON.stringify({
      requestContent: {
        Product: {
          ProductID: +Product,
        },
      },
    });
    dispatch(startDeleteProduct(data, key, Product));
    // console.log(data);
    // setShowModal(false);
    navigate('/categorias');
  };

  return (
    <>
      <div className="container pbottom50">
        <BackRelative url="/categorias" />
        <div className="medium-wrap">
          {loadingData ? (
            <LoaderData />
          ) : (
            <>
              <h1 className="text-center m-0 pbottom30">Editar categoría</h1>
              <Form onSubmit={onSubmit} className="general-form">
                <div className="mbottom30">
                  <label>
                    Nombre de categoría
                    <Input
                      type="text"
                      name="name"
                      placeholder="Nombra la categoría"
                      maxLength="25"
                      value={name}
                      onChange={handlerName}
                      required
                    />
                    <span className="countCharacters dblock text-right font15">
                      {name?.length}/25
                    </span>
                  </label>
                </div>
                <div className="mbottom30">
                  <label>
                    Descripción
                    <textarea
                      name="description"
                      rows="30"
                      placeholder="Proporciona una descripción breve..."
                      maxLength="150"
                      value={description}
                      onChange={handlerDesc}
                      required
                    ></textarea>
                    <span className="countCharacters dblock text-right font15">
                      {description?.length}/150
                    </span>
                  </label>
                </div>
                <div className="row m-0">
                  <div className="col-4 pleft0">
                    <div className="radio-group">
                      <label>Estado</label>
                      <ul className="margin0 padding0">
                        <li>
                          <div className="radio relative">
                            <input
                              id="radio1"
                              type="radio"
                              name="status"
                              value="1"
                              onChange={handlerStatus}
                              checked={status === 1 ? true : false}
                            />
                            <label
                              htmlFor="radio1"
                              className="radio-label"
                            ></label>
                            <label htmlFor="radio1">Activo</label>
                          </div>
                        </li>
                        <li>
                          <div className="radio relative">
                            <input
                              id="radio2"
                              type="radio"
                              name="status"
                              value="0"
                              onChange={handlerStatus}
                              checked={status === 0 ? true : false}
                            />
                            <label
                              htmlFor="radio2"
                              className="radio-label"
                            ></label>
                            <label htmlFor="radio2">Inactivo</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-8 pright0">
                    <label>
                      Horario habilitado
                      <Dropdown
                        name="ProductSchedule"
                        className="mtop10"
                        placeholder="Seleccionar horario..."
                        // fluid
                        value={schedule}
                        options={arraySchedule}
                        lazyLoad
                        onChange={(e, data) => {
                          // console.log(data.value);
                          setStateForm({ ...stateForm, schedule: data.value });
                        }}
                        selection
                      />
                    </label>
                  </div>
                </div>
                <div className="mbottom30">
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={['jpg', 'png', 'jpeg']}
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper ptop40">
                        <label className="dblock pbottom15">Imagen</label>
                        <button
                          type="button"
                          className={`cta cta--secondary-light cta--tiny margin0 ${
                            imageList.length > 0 ? 'dnone' : null
                          }`}
                          style={isDragging ? { color: '#333' } : null}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          Agregar imagen
                        </button>
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item ptop15">
                            <img src={image.data_url} alt="" width="350" />
                            <div className="image-item__btn-wrapper ptop10">
                              <button
                                type="button"
                                className="link-button"
                                onClick={() => onImageUpdate(index)}
                              >
                                <Icon name="edit" size="small" />
                                Cambiar imagen
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div className="flex flex--between">
                  <button
                    type="button"
                    className="cta cta--secondary-plain"
                    onClick={() => handlerModal('delete')}
                  >
                    Eliminar
                  </button>
                  <Button
                    id="buttonSubmit"
                    className={`cta cta--secondary ${
                      validForm ? '' : 'disabled'
                    }`}
                    loading={loading}
                  >
                    Guardar categoría
                  </Button>
                </div>
              </Form>
            </>
          )}
        </div>
      </div>
      <DeleteModal
        show={showModal}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        idSchedule={ProductID}
        handleDelete={handlerDelete}
      />
    </>
  );
};
