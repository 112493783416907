import React from 'react';

export const Tooltip = (props) => {
  return (
    <>
      <button type="button" className="help-tooltip" data-tooltips={props.text}>
        {props?.type == 'exclamation' ? (
          <div className="d-inline-block icon-warning" style={{ transform: 'rotate(180deg)' }}></div>
        ) : (
          <span className="icon-warning"></span>
        )}
      </button>
    </>
  );
};