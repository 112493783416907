export const prepareStores = (stores = []) => {
  return stores.map((e) => ({
    storeID: e?.storeID,
    storeCode: e?.storeCode,
    storeName: e?.storeName,
    description: e?.description,
    typeName: e?.typeName,
    address: e?.address,
    phone1: e?.phone1,
    phone2: e?.phone2,
    manager: e?.manager,
    status: e?.status
  }));
};