import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';

export const LoaderData = () => {
  return (
    <div className="loader-block">
      <Segment>
        <Dimmer active inverted>
          <Loader inverted>Cargando...</Loader>
        </Dimmer>
      </Segment>
    </div>
  );
};
