import React, { useState, useEffect } from 'react';
import { storeGetAll } from '../../../actions/store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { singleFetchService } from '../../../helpers/functions';
import { BackRelative } from '../../../components/Back/BackRelative';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import { createUser, updateUser, deleteUser, startGetAllRoles } from '../../../actions/admins';
import { Form, Input, Button, Checkbox, Message, Dropdown, Icon, Radio } from 'semantic-ui-react';

const initialData = {
  name: {
    value: '',
    error: false
  },
  surname: {
    value: '',
    error: false
  },
  email: {
    value: '',
    error: false
  },
  user_name: {
    value: '',
    error: false
  },
  password: {
    value: '',
    error: false
  },
  confirmPassword: {
    value: '',
    error: false
  },
  storeID: 0,
  status: true,
  rol: {
    id: 0
  }
}

export const FormUser = () => {
  /***  Selector  ***/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let key = useSelector((state) => state.key.key);
  const { loading } = useSelector((state) => state.ui);
  const rolesList = useSelector((state) => state.roles);
  const userRequest = useSelector((state) => state.users.request);
  const storesList = useSelector((state) => state.store.storesAll);

  /***  Variables ***/
  // Formulario
  const [form, setForm] = useState(initialData);
  const [roles, setRoles] = useState(rolesList);
  const [stores, setStores] = useState(storesList);
  const [validForm, setValidForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  // Obtener parametro GET (condimentGroup)
  const location = useLocation();
  const userID = (location.pathname.match(/\d+/) || [])[0] || undefined;

  // Modal states
  const [showModal, setShowModal] = useState(false);
  const [resumeModal, setResumeModal] = useState('No podrás revertir esta acción.');
  const [titleModal, setTitleModal] = useState('¿Estás seguro que deseas eliminar este usuario?');

  // Funciones de Formulario
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });
  };

  const handlePasswordChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });

    if (e.target.name === 'password') {
      const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{7,})/;
      setPasswordError(!strongRegex.test(e.target.value));
      setConfirmPasswordError(form.confirmPassword.value != e.target.value);
      (userID != undefined && e.target.value === '') && ( setPasswordError(false))
    }
    if (e.target.name === 'confirmPassword') {
      setConfirmPasswordError(form.password.value != e.target.value);
    }

    if(userID != undefined && (form.password.value.length === 0 && form.confirmPassword.value.length === 0)){
      setPasswordError(false);
      setConfirmPasswordError(false);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleStatusChange = (e) => {
    setForm({
      ...form,
      status: !form.status
    });
  };

  const handleRoleChange = (e, id, value, checked) => {
    setForm({
      ...form, rol: { id: id }
    });
    // checked ? setRoleSelected(id) : setRoleSelected();
  }

  const handleStoreChange = (e, id) => {
    setForm({
      ...form,
      storeID: id
    });
  }

  // Obtener un usuario
  const getOneUser = () => {
    const data = JSON.stringify({
      requestContent: {
        userAdmin: { id: +userID }
      }
    });

    const body = singleFetchService('/user-admin/v1/id', data, key)
      .then((response) => {
        const res = response.responseContent;

        setForm({
          status: res?.status,
          storeID: res?.storeID,
          rol: { id: res?.rol?.id },
          password: { value: '' || '', error: false },
          name: { value: res?.name || '', error: false },
          email: { value: res?.email || '', error: false },
          confirmPassword: { value: '' || '', error: false },
          surname: { value: res?.surname || '', error: false },
          user_name: { value: res?.user_name || '', error: false }
        });
        setPasswordError(false);
        setConfirmPasswordError(false);
      })
      .catch((err) => console.log(err));
    return body;
  };

  // Guardar un usuario
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      form.password.value !== form.confirmPassword.value || form.rol.id <= 0 || form.storeID <= 0
    ) {
      setConfirmPasswordError(true);
      return;
    } else {
      const userData = {
        requestContent: {
          userAdmin: {
            id: Number(userID) || undefined,
            type: !userID ? 'create' : 'update',
            status: form.status,
            name: form.name.value,
            email: form.email.value,
            surname: form.surname.value,
            password: form.password.value,
            user_name: form.user_name.value,
            storeID: form.storeID,
            rol: {
              id: form.rol.id
            }
          }
        }
      };

      if (!userID) {
        delete userData.requestContent.userAdmin.id;
        dispatch(createUser(JSON.stringify(userData), key, userData));
      } else {
        userData.requestContent.userAdmin.password.length === 0 && delete userData.requestContent.userAdmin.password;
        dispatch(updateUser(JSON.stringify(userData), key, userData));
      }

    }
  };

  // Eliminar grupo
  const deleteGroup = (id) => {
    const userAdmin = {
      name: form.name.value,
      email: form.email.value,
      user_name: form.user_name.value,
      id: +id
    }

    const data = JSON.stringify({
      requestContent: { userAdmin: userAdmin }
    });

    dispatch(deleteUser(data, key, userAdmin));
  };

  /***  useEffects ***/
  // Crear o Editar
  useEffect(async () => {
    if (userID != undefined) {
      getOneUser();
    }
    dispatch(await storeGetAll('{}', key));
    dispatch(await startGetAllRoles('{}', key));
  }, [userID, dispatch]);

  useEffect(() => {
    if (
      form.rol.id <= 0 ||
      form.storeID <= 0 ||
      form.name.value.length <= 2 ||
      form.email.value.length <= 7 ||
      form.surname.value.length <= 2 ||
      form.user_name.value.length <= 3 ||
      (userID === undefined && ((form.password.value != form.confirmPassword.value) || passwordError === true)) ||
      (userID && ((form.password.value != form.confirmPassword.value ) || passwordError === true))
    ) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [form]);

  useEffect(() => {
    setRoles(rolesList);
    setStores(storesList);
  }, [rolesList, storesList]);

  // Re-direccionar
  useEffect(() => {
    if (userRequest?.info?.type === 'success') navigate('/usuarios');
  }, [userRequest]);

  return (
    <>
      <div className="container pbottom50">
        <BackRelative url="/usuarios" />
        <div className="large-wrap">
          <h1 className="text-center m-0 pbottom30">
            {!userID ? 'Crear usuario' : 'Editar usuario'}
          </h1>
          <Form onSubmit={handleSubmit} className="general-form">
            <div className="row">
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.name.error ? 'errorField' : ''}>
                    Nombres
                    <Input
                      type="text"
                      name="name"
                      placeholder="Nombre"
                      minLength="3"
                      maxLength="20"
                      required={true}
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.name.value}
                    />
                    <span className="countCharacters dblock text-right font15">
                      {form.name.value?.length}/20
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.surname.error ? 'errorField' : ''}>
                    Apellidos
                    <Input
                      type="text"
                      name="surname"
                      placeholder="Apellidos"
                      minLength="3"
                      maxLength="20"
                      required={true}
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.surname.value}
                    />
                    <span className="countCharacters dblock text-right font15">
                      {form.surname.value?.length}/20
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.email.error ? 'errorField' : ''}>
                    Correo electrónico
                    <Input
                      type="email"
                      name="email"
                      placeholder="Correo electrónico"
                      required={true}
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.email.value}
                    />
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.user_name.error ? 'errorField' : ''}>
                    Nombre de usuario
                    <Input
                      type="text"
                      name="user_name"
                      required={true}
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.user_name.value}
                      placeholder="Nombre de usuario"
                    />
                  </label>
                </div>
              </div>
              {(userID === undefined) && (
                <>
                  <div className="col-6">
                    <div className="mbottom30">
                      <label className={(userID === undefined && form.password.error) ? 'errorField' : ''}>
                        Contraseña
                        <Input
                          type={showPassword ? 'text' : 'password'}
                          minLength="8"
                          name="password"
                          required={userID === undefined}
                          onBlur={handleValidate}
                          onChange={handlePasswordChange}
                          value={form.password.value}
                          placeholder="Crea una nueva contraseña"

                          icon={
                            showPassword ? (
                              <Icon
                                name="eye slash outline"
                                link
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            ) : (
                              <Icon
                                name="eye"
                                link
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            )
                          }
                        />
                        {passwordError && (
                          <Message negative>
                            La contraseña debe contener al menos 8 caracteres, una mayúscula, una minúscula y un carácter especial.
                          </Message>
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mbottom30">
                      <label className={(userID === undefined && form.confirmPassword.error) ? 'errorField' : ''}>
                        Confirmar contraseña
                        <Input
                          type={showPassword ? 'text' : 'password'}
                          minLength="8"
                          required={userID === undefined}
                          name="confirmPassword"
                          placeholder="Confirmar contraseña"
                          onBlur={handleValidate}
                          value={form.confirmPassword.value}
                          onChange={handlePasswordChange}
                          icon={
                            showPassword ? (
                              <Icon
                                name="eye slash outline"
                                link
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            ) : (
                              <Icon
                                name="eye"
                                link
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            )
                          }
                        />
                        {confirmPasswordError && (
                          <Message negative>
                            Las contraseñas no coinciden
                          </Message>
                        )}
                      </label>
                    </div>
                  </div>
                </>
              )}
              <div className="col-12">
                <div className="mbottom30">
                  <label>
                    Estatus
                    <Radio
                      toggle
                      className='mt-2'
                      checked={form.status === true}
                      onChange={handleStatusChange}
                    />
                  </label>
                </div>
              </div>
              {roles.roles && roles.roles.length > 0 &&
                <>
                  {roles?.roles?.map(
                    (role, index) => {
                      return (
                        <>
                          <div className="col-4 mt-5">
                            <div className="mbottom30">
                              <Checkbox
                                key={index}
                                id={role.id}
                                value={role.id}
                                label={role.name}
                                checked={form.rol.id === role.id}
                                onClick={(e, data) => handleRoleChange(e, data.id, data.value, data.checked)}
                              />
                            </div>
                            <span>{role.description}</span>
                          </div>
                        </>
                      );
                    })
                  }
                </>
              }
              {stores && stores.length > 0 && (
                <div className="col-6 mt-4">
                  <div className="mbottom30">
                    <label>
                      Tienda asignada
                      <Dropdown
                        fluid
                        selection
                        className='mt-2'
                        value={form.storeID}
                        options={
                          stores?.map(
                            (store, index) => {
                              return ({
                                key: index,
                                text: store.storeName,
                                value: store.storeID
                              })
                            })
                        }
                        placeholder="Seleccionar..."
                        onChange={(e, { value }) => handleStoreChange(e, value)}
                      />
                    </label>
                  </div>
                </div>
              )}
            </div>
            {!userID ?
              <div className="text-center">
                <Button
                  loading={loading}
                  disabled={!validForm}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                >
                  Guardar usuario
                </Button>
              </div>
              :
              <div className="flex flex--center">
                <Button
                  type="button"
                  className="cta cta--secondary-plain mx-3"
                  onClick={() => setShowModal(true)}
                >
                  Eliminar usuario
                </Button>
                <Button
                  loading={loading}
                  disabled={!validForm}
                  className={`cta cta--secondary mx-3 ${validForm ? '' : 'disabled'}`}
                >
                  Guardar cambios
                </Button>
              </div>
            }
          </Form>
        </div>
      </div>
      <DeleteModal
        show={showModal}
        id={userID}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        handleDelete={deleteGroup}
        withContent={false}
        isLoading={loading}
      />
    </>
  );
};

