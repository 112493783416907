import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { startChecking } from '../actions/auth';

import { EmptyState } from '../components/EmptyState/EmptyState';

import { CreateFirstPassword } from '../Pages/PublicPages/CreateFirstPassword/CreateFirstPassword';
import { ForgotPassword } from '../Pages/PublicPages/ForgotPasword/ForgotPassword';
import { Login } from '../Pages/PublicPages/Login/Login';
import { RenewPassword } from '../Pages/PublicPages/RenewPassword/RenewPassword';
import { ResendPassword } from '../Pages/PublicPages/ResendPassword/ResendPassword';
import { DashboardRoutes } from './DashboardRoutes';
import { PublicRoutes } from './PublicRoutes';
import { PrivateRoutes } from './PrivateRoutes';
import { Terms } from '../Pages/PublicPages/Terms/Terms';

export const AppRouter = () => {
  // window.onbeforeunload = (e) => {
  //   return 'Si recarga, se cerrará sesión';
  // };

  const dispatch = useDispatch();
  const { checking } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(startChecking());
  }, [dispatch]);

  if (checking) {
    return <h5>Espere...</h5>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoutes>
              <Login />
            </PublicRoutes>
          }
        />
        <Route
          path="/primera-contrasena"
          element={
            <PublicRoutes>
              <CreateFirstPassword />
            </PublicRoutes>
          }
        />
        <Route
          path="/recuperar-contrasena"
          element={
            <PublicRoutes>
              <ForgotPassword />
            </PublicRoutes>
          }
        />
        <Route
          path="/reenviar-contrasena"
          element={
            <PublicRoutes>
              <ResendPassword />
            </PublicRoutes>
          }
        />
        <Route
          path="/renovar-contrasena"
          element={
            <PublicRoutes>
              <RenewPassword />
            </PublicRoutes>
          }
        />
        <Route path="/terms" element={ <Terms /> } />
        <Route
          path="/empty-state"
          element={
            <EmptyState
              title="Condimentos"
              text="No hay condimentos creados. Comienza creando condimentos individuales para poder crear grupos con ellos."
              titleButton="Crear condimento"
              linkButton="nueva-contrasena"
            />
          }
        />

        <Route
          path="/*"
          element={
            <PrivateRoutes>
              <DashboardRoutes />
            </PrivateRoutes>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
