import { types } from '../types/types';

const initialState = {
  create: {
    name: '',
    description: '',
    product: [],
  },
  list: null,
};

export const groupProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.groupProductAll:
      return {
        ...state,
        list: [...action.payload],
      };
    case types.groupProductCreate:
      return {
        ...state,
        create: {
          ...action.payload,
        },
      };
    case types.groupProductById:
      return {
        ...state,
        create: {
          ...action.payload,
        },
      };
    case types.groupProductUpdate:
      return {
        ...state,
        create: {
          ...action.payload,
        },
      };
    case types.groupProductDelete:
      return {
        ...state,
        create: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
