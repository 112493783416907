import React, { useState } from 'react'
import ImageUploading from 'react-images-uploading';

export const InputMultipleFile = ({ title, maxNumber }) => {
    const [images, setImages] = useState([]);
    const [imagesAux, setImagesAux] = useState([]);

    const onChange = (imageList) => {
        setImages(imageList);
    };

    const onChange2 = (imageList) => {
        setImages(imagesListAux => [...images, ...imageList]);
    };

    const handleRemoveImage = (e, indexRemove) => {
        const imagesListAux = images.filter((x, index) => index != indexRemove);
        setImages(imagesListAux);
    }

    return (
        <div className="mbottom30">
            <label className="has-tooltip-inline pb-1">{title}</label>
            {images.length === 0 ? (
                <div className='box-images'>
                    <i className='box-images-icon'></i>
                    <p>Sube hasta 4 imágenes en .jpg de 240 x 240 px</p>
                    <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        dataURLKey="data_url"
                        maxNumber={maxNumber}
                        acceptType={['jpg', 'png', 'jpeg']}
                    >
                        {({
                            imageList,
                            onImageUpload,
                            onImageUpdate,
                            isDragging,
                            dragProps,
                        }) => (
                            <div className="upload__image-wrapper ">
                                <button
                                    type="button"
                                    className={`cta cta--secondary-light cta--tiny margin0 ${imageList.length > 0 ? 'dnone' : null}`}
                                    style={isDragging ? { color: '#333' } : null}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                >
                                    Subir imágenes
                                </button>
                            </div>
                        )}
                    </ImageUploading>
                </div>
            ) : (
                <div className='row mt-2'>
                    {images.map((image, index) => (
                        <div className='col-auto p-2 bg-gray'>
                            <div className='box-mini-image'>
                                <span>{index}</span>
                                <a className='box-mini-image-btn-remove' onClick={(e) => handleRemoveImage(e, index)}>X</a>
                                <img className='mx-auto' src={image.data_url} alt={image?.file?.name} />
                            </div>
                        </div>
                    ))}
                    {images.length < 4 && (
                        <ImageUploading
                            multiple
                            value={imagesAux}
                            onChange={onChange2}
                            dataURLKey="data_url"
                            maxNumber={4 - images.length}
                            acceptType={['jpg', 'png', 'jpeg']}
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageUpdate,
                                isDragging,
                                dragProps,
                            }) => (
                                <div className='col-auto p-2 bg-gray'>
                                    <div className='box-mini-add-image'>
                                        <a className='box-mini-add-icon' onClick={onImageUpload}></a>
                                    </div>
                                </div>
                            )}
                        </ImageUploading>
                    )}
                </div>
            )}
        </div>
    )
}