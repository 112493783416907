import React from 'react';

export const CategorySelectItem = ({
  key,
  condimentID,
  microsID,
  symphonyID,
  name,
  description,
  image,
  price,
  status,
  order,
  selected,
  nameWeb,
  SubCondiment,
  handleActiveCategory,
  isCategoryAdded,
}) => {
  return (
    <div key={key} className="select-drag__item-select">
      {name}{' '}
      <button
        type="button"
        disabled={isCategoryAdded}
        onClick={() => {
          handleActiveCategory({
            condimentID,
            microsID,
            symphonyID,
            name,
            description,
            image,
            price,
            status,
            order,
            selected,
            nameWeb,
            SubCondiment,
          });
          // handlerCategoryAdded();
        }}
      >
        +
      </button>
    </div>
  );
};
