import React from 'react';
import { Link } from 'react-router-dom';

export const Back = ({ url }) => {
  //   console.log(props);
  return (
    <>
      <div className="back-button">
        <Link to={`${url}`}>
          <span className="icon-arrow-right"></span> Regresar
        </Link>
      </div>
    </>
  );
};
