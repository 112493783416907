import { Link } from 'react-router-dom';
import { types } from '../../../types/types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ListCondiments } from './components/ListCondiments';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Accordion, Button, Icon, Input } from 'semantic-ui-react';
import { CondimentsByGroup } from './components/CondimentsByGroup';
import { LoaderData } from '../../../components/Loader/LoaderData';
import { startGetAllCondiments } from '../../../actions/condiments';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { initialUpdateGroup } from '../../../actions/condimentGroup.js';
import { startGetAllGroupsCondiments } from '../../../actions/condimentGroup';

export const Condiments = () => {
  // Dispatch
  const dispatch = useDispatch();
  const { key } = useSelector((state) => state.key);

  // Get all Categoriesd
  const reqAllGroups = JSON.stringify({ "info": {} });
  const reqAllCondiment = JSON.stringify({ "info": {} });

  useEffect(() => {
    dispatch({ type: types.condimentRequest, payload: {} });
    dispatch({ type: types.groupCondimentRequest, payload: {} });
    dispatch(startGetAllGroupsCondiments(reqAllGroups, key));
    dispatch(startGetAllCondiments(reqAllCondiment, key));
  }, [dispatch]);

  // Get individual condiments
  const condimentRedux = useSelector((state) => state.condiment.allCondiments);

  // Get groups condiments
  const condimentGroupsRedux = useSelector((state) => state.condimentGroup.allGroups);

  // Tab states
  const [tabIndex, setTabIndex] = useState(0);

  // Accordion
  const [activeAccordion, setActiveAccordion] = useState(0);

  const handleAccordion = (index) => {
    const newIndex = activeAccordion === index ? -1 : index;
    setActiveAccordion(newIndex);
  };

  const handleOnDrag = (result) => {
    var group = condimentGroupsRedux.filter(function (condimentGroups) {
      return condimentGroups.condimentGroupID === +result.destination.droppableId;
    });

    const items = group.map((condiments) => condiments.condiments);

    if (items[0]) {
      const [reorderedItems] = items[0].splice(result.source.index, 1);
      items[0].splice(result.destination.index, 0, reorderedItems);

      items[0].map((item, index) => {
        items[0][index].condimentOrder = index;
      });

      var dataGroupUpdate = JSON.stringify({
        requestContent: {
          condimentGroup: {
            condimentGroupID: group[0].condimentGroupID,
            name: group[0].name,
            description: group[0].description,
            minimumSelection: group[0].minimun_selection,
            maximumSelection: group[0].maximun_selection,
            status: group[0].status,
            nameWeb: group[0].nameWeb,
            condiments: items[0],
            condimentsCount: group[0].condimentsCount
          }
        }
      });

      const groupUpdate = {
        condimentGroupID: group[0].condimentGroupID,
        name: group[0].name,
        description: group[0].description,
        condiments: items[0]
      };

      dispatch(initialUpdateGroup(dataGroupUpdate, key, groupUpdate));
    } else {
      console.error("No hay condimetos en este grupo.");
    }
  };

  // search condiment group
  const [nameGroupCondiment, setNameGroupCondiment] = useState('');
  const [foundGroupsCondiment, setFoundGroupsCondiment] = useState(condimentGroupsRedux);
  const filterGroupsCondiment = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = condimentGroupsRedux.filter((item) => {
        return item.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundGroupsCondiment(results);
    } else {
      setFoundGroupsCondiment(condimentGroupsRedux);
    }
    setNameGroupCondiment(keyword);
  };

  // search condiment
  const [nameCondiment, setNameCondiment] = useState('');
  const [foundCondiements, setFoundCondiments] = useState(condimentGroupsRedux);
  const filterCondiments = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = condimentRedux.filter((item) => {
        return (
          (item.name && item.name.toLowerCase().includes(keyword.toLowerCase())) ||
          (item.name_web && item.name_web.toLowerCase().includes(keyword.toLowerCase()))
        );
      });
      setFoundCondiments(results);
    } else {
      setFoundCondiments(condimentRedux);
    }
    setNameCondiment(keyword);
  };

  const onSearch = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setFoundCondiments(condimentRedux);
    setFoundGroupsCondiment(condimentGroupsRedux);
  }, [condimentGroupsRedux, condimentRedux]);

  return (
    <>
      {condimentGroupsRedux ? (
        <>
          {condimentGroupsRedux.length >= 1 ? (
            <>
              <h1 className='text-center m-0 pbottom30'>Condimentos</h1>
              <div className="standard-wrap">
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => setTabIndex(index)}
                >
                  <TabList>
                    <Tab>Grupos de condimientos</Tab>
                    <Tab>Condimentos individuales</Tab>
                  </TabList>
                  {/* Group condiments */}
                  <TabPanel>
                    {condimentGroupsRedux ? (
                      <>
                        <div className="top-filters">
                          <div className="top-filters__left top-filters__left--small">
                            <form onSubmit={onSearch}>
                              <Input
                                className="search-input"
                                list="categories"
                                placeholder="Buscar condimentos individuales..."
                                onChange={filterGroupsCondiment}
                                value={nameGroupCondiment}
                              />
                              <Button>Buscar</Button>
                            </form>
                          </div>
                          <div className="top-filters__right top-filters__right--large top-filters__right--flex-end">
                            <Link
                              className="cta cta--widthAuto margin0"
                              to="/condimentos/group/form/new"
                            >
                              + Crear grupo
                            </Link>
                          </div>
                        </div>
                        <div className="accordion-module">
                          {foundGroupsCondiment ? (
                            <>
                              {foundGroupsCondiment.length > 0 ? (
                                <>
                                  {foundGroupsCondiment.map((group, index) => (
                                    <Accordion key={index}>
                                      <Accordion.Title
                                        active={activeAccordion === index}
                                        onClick={() => handleAccordion(index)}
                                      >
                                        <div>
                                          {group.name}
                                          &nbsp;&#160;
                                          <label style={{ color: '#5C5C5C', opacity: 0.8 }}>
                                            (
                                            {group.products === undefined ? '0' : group.products} condimentos){group.status === true ? ' - Activo' : ' - Inactivo'}
                                            {/* {group.products === undefined ? '0' : group.products} condimentos){group.status === 1 ? ' - Activo' : ' - Inactivo'} */}
                                          </label>
                                        </div>
                                        <span>
                                          {activeAccordion === index ? 'Ver menos' : 'Ver más'}
                                          <Icon name="dropdown" />
                                        </span>
                                      </Accordion.Title>
                                      <Accordion.Content active={activeAccordion === index} >
                                        {group?.condiments && group?.condiments?.length > 0 ? (
                                          <div className="categories-list">
                                            <DragDropContext onDragEnd={handleOnDrag} >
                                              <Droppable droppableId={'' + group.condimentGroupID} >
                                                {(provided) => (
                                                  <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {group.condiments?.map(
                                                      (condiment, index_2) => {
                                                        return (
                                                          <CondimentsByGroup
                                                            key={index_2}
                                                            index={index_2}
                                                            name={condiment.name}
                                                            price={condiment.price}
                                                            status={condiment.status}
                                                            microsID={condiment.microsID}
                                                            symphonyID={condiment.symphonyID}
                                                            condimentID={condiment.condimentID}
                                                          />
                                                        );
                                                      }
                                                    )}
                                                    {provided.placeholder}
                                                  </div>
                                                )}
                                              </Droppable>
                                            </DragDropContext>
                                          </div>
                                        ) : (
                                          <div className='text-center'>No hay condimentos bajo este grupo.</div>
                                        )}
                                        <div className="text-right">
                                          <Link
                                            to={`/condimentos/group/form/${group.condimentGroupID}`}
                                            className="cta cta--tiny cta--h30 cta--secondary-light margin0"
                                          >
                                            <span className="icon-edit mright5"></span>
                                            Editar grupo
                                          </Link>
                                        </div>
                                      </Accordion.Content>
                                    </Accordion>
                                  ))}
                                </>
                              ) : (
                                <h1>Sin resultados!</h1>
                              )}
                            </>
                          ) : (
                            <LoaderData />
                          )}
                        </div>
                      </>
                    ) : (
                      <EmptyState
                        title="Condimientos"
                        text={`No hay grupos de condimentos creados.`}
                        titleButton="+ Crear grupo"
                        linkButton="/condimentos/group/form/new"
                      />
                    )}
                  </TabPanel>
                  {/* Individual condiments */}
                  <TabPanel>
                    <div className="top-filters">
                      <div className="top-filters__left top-filters__left--small">
                        <form onSubmit={onSearch}>
                          <Input
                            className="search-input"
                            list="categories"
                            placeholder="Buscar condimentos individuales..."
                            onChange={filterCondiments}
                            value={nameCondiment}
                          />
                          <Button>Buscar</Button>
                        </form>
                      </div>
                      <div className="top-filters__right top-filters__right--large top-filters__right--flex-end">
                        <Link
                          className="cta cta--widthAuto margin0"
                          to="/condimentos/form/new"
                        >
                          + Crear condimento
                        </Link>
                      </div>
                    </div>
                    <div className="categories-list categories-list--condiments">
                      {foundCondiements ? (
                        <ListCondiments condimentsList={foundCondiements} />
                      ) : (
                        <LoaderData />
                      )}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </>
          ) : (
            <EmptyState
              title="Condimentos"
              text={`No hay condimentos creados. Comienza creando condimentos individuales para poder crear grupos con ellos.`}
              titleButton="+ Crear condimento"
              linkButton="/condimentos/form/new"
            // linkButton="/condimentos/crear"
            />
          )}
        </>
      ) : (
        <LoaderData />
      )}
    </>
  );
};
