import React from 'react';

export const Terms = () => {
  return (
    <>
      <div className="wrapper-terms">
        <h1>TÉRMINOS Y CONDICIONES DE USO APP CAFÉ BARISTA</h1>
        <p>
          Al adquirir cualquier producto o servicio de nuestra APP y/o utilizar
          o acceder a la información desplegada en el mismo, el usuario acepta
          haber estudiado y entendido todos los términos y condiciones del
          servicio que se describen en el presente documento (en adelante
          “Términos y Condiciones”) tales como, pero no limitados a condiciones
          de uso, medios para notificaciones y cláusulas de exención de
          responsabilidad; y manifiesta estar de acuerdo con cada uno de ellos.
        </p>
        <h3>CONTENIDO Y USO</h3>
        <p>
          El Usuario queda informado, y acepta, que el acceso a esta APP, no
          supone el inicio de una relación comercial con Café Barista, quienes
          se reservan el derecho de efectuar sin previo aviso cuantas
          modificaciones vean oportunas en la misma, cambiando, eliminando o
          añadiendo contenidos y/o servicios prestados vía web a través de la
          misma, así como la forma en la que éstos aparezcan.
        </p>
        <p>
          El Usuario se compromete a seguir las instrucciones para la
          utilización correcta del sitio web y/o el APP y, por lo tanto, de los
          servicios que en los mismos se ofrecen. Si el Usuario no está de
          acuerdo con estos Términos y Condiciones, le rogamos no acceder ni
          utilizar el sitio web y/o el APP, su material, su información y/o los
          servicios que por estos medios se ofrecen.
        </p>
        <p>
          <strong>APP:</strong> La aplicación para dispositivos que incluye,
          pero no se limita, a IOS y Android que CAFÉ BARISTA pone a disposición
          de los usuarios.
        </p>
        <p>
          <strong>PLATAFORMA:</strong> La plataforma de CAFÉ BARISTA a la cual
          los CONSUMIDORES podrán tener acceso a través del SITIO WEB y/o el APP
          con el fin de adquirir los PRODUCTOS y/o SERVICIOS que CAFÉ BARISTA
          y/o sus socios comerciales ofrezcan.
        </p>
        <h3 className="underline">AVISO DE PRIVACIDAD</h3>
        <p>
          En Café Barista, respetamos la privacidad de nuestros usuarios y
          estamos totalmente comprometidos con la protección de su información
          personal. Este aviso describe cómo podemos recopilar y utilizar
          información personal, así como los derechos y opciones disponibles
          para nuestros visitantes y usuarios con respecto a dicha información.
        </p>
        <p>
          Recomendamos lea este aviso y se asegure estar de acuerdo con ella,
          antes de acceder a o utilizar cualquiera de nuestros servicios. Si no
          lee, entiende completamente y está de acuerdo con este aviso, debe
          salir inmediatamente de este sitio web, aplicación o servicio y evitar
          o suspender todo uso de cualquiera de nuestras plataformas.
        </p>
        <p>
          Café Barista se reserva el derecho a realizar un seguimiento de tu
          actividad en la Aplicación de y a informar de ello a nuestros
          proveedores de servicios estadísticos de terceros. Todo ello de forma
          anónima.
        </p>
        <p>
          Protección de tu información personal: queremos ayudarte a llevar a
          cabo todos los pasos necesarios.
        </p>
        <h3>¿QUÉ TIPO DE INFORMACIÓN RECIBIMOS, RECOPILAMOS Y ALMACENAMOS?</h3>
        <p>
          Podremos recopilar información acerca de nuestros Visitantes y
          Usuarios:
        </p>
        <p>
          <strong>Datos que el usuario nos proporciona </strong>
        </p>
        <p>
          Podremos recopilar diferentes datos del usuario o sobre este, en
          función de cómo utilice los servicios. A continuación, se muestran
          algunos ejemplos para ayudar al usuario a comprender mejor los datos
          que recopilamos.
        </p>
        <h3>¿CÓMO RECOPILAMOS ESTA INFORMACIÓN?</h3>
        <p>
          Cuando navegas a través de nuestra página web recopilamos y
          registramos información acerca de sesiones e información relacionada,
          ya sea en forma independiente o con la ayuda de servicios de terceros
          mediante el uso de “cookies” y otras tecnologías de seguimiento, como
          se detalla un poco más adelante.
        </p>
        <p>
          Como parte del proceso, podremos recopilar información personal que
          nos proporcionas voluntariamente, como tu nombre, dirección de correo
          electrónico o medio de pago. Tu información personal será utilizada
          solo por los motivos específicos indicados anteriormente.
        </p>
        <h3>¿POR QUÉ RECOPILAMOS ESTA INFORMACIÓN?</h3>
        <p>
          Recopilamos dicha información personal y no personal para los
          siguientes propósitos:
        </p>
        <ul>
          <li>Para proporcionar y operar los servicios</li>
          <li>
            Para proporcionar a nuestros Usuarios asistencia continua al cliente
            y soporte
          </li>
          <li>
            Para poder contactar a nuestros visitantes y usuarios con avisos
            generales y personalizados relacionados con el servicio y mensajes
            promocionales
          </li>
          <li>
            Para crear datos estadísticos agregados y otra información no
            personal agregada y / o deducida, que nosotros o nuestros socios
            comerciales podamos utilizar para proporcionar y mejorar nuestros
            servicios respectivos
          </li>
          <li>Para cumplir con las leyes y regulaciones aplicables</li>
        </ul>
        <h3>¿CON QUIÉN COMPARTIMOS LOS DATOS DE LOS USUARIOS?</h3>
        <p>
          Podemos compartir los datos del usuario con terceros en las siguientes
          circunstancias o según se describe en esta Política de privacidad:
        </p>
        <p>
          Con proveedores de servicios, contratistas y agentes: Compartimos los
          datos del usuario con empresas de terceros que prestan servicios en
          nuestro nombre, como procesamiento de pago, análisis de datos,
          servicios de marketing y publicidad (incluida publicidad de
          reasignación), servicios de correo electrónico y alojamiento, y
          atención al cliente y asistencia. Estos proveedores de servicios
          pueden acceder a los datos personales del usuario y se les exige que
          los utilicen únicamente como se lo indicamos para proporcionar nuestro
          servicio solicitado.
        </p>
        <h3>RETENCIÓN DE DATOS</h3>
        <p>
          Podemos conservar tu Información Personal durante el tiempo que
          consideremos necesario para ofrecerte nuestros servicios o productos.
        </p>
        <p>
          Podemos seguir conservando dicha Información Personal, incluso después
          de desactivar tu Cuenta de Usuario y/o dejar de utilizar cualquiera de
          los Servicios, según sea razonablemente necesario para cumplir con
          nuestras obligaciones legales, resolver disputas acerca de nuestros
          Usuarios, prevenir el fraude y abuso, hacer cumplir nuestros acuerdos
          y/o proteger nuestros intereses legítimos.
        </p>
        <p>
          Queda prohibido alterar o modificar ninguna parte de la APP a de los
          contenidos de la misma, eludir, desactivar o manipular de cualquier
          otra forma (o tratar de eludir, desactivar o manipular) las funciones
          de seguridad u otras funciones del programa y utilizar la APP o sus
          contenidos para un fin comercial o publicitario.
        </p>
        <p>
          Queda prohibido el uso de la APP con la finalidad de lesionar bienes,
          derechos o intereses. Queda igualmente prohibido realizar cualquier
          otro uso que altere, dañe o inutilice las redes, servidores, equipos,
          productos y programas informáticos de Café Barista o de terceros.
        </p>
        <p>
          La APP y sus contenidos (textos, fotografías, gráficos, imágenes,
          tecnología, software, links, contenidos, diseño gráfico, código
          fuente, etc.), así como las marcas y demás signos distintivos son
          propiedad de Café Barista , no adquiriendo el usuario ningún derecho
          sobre ellos. por el mero uso de la APP. El Usuario, deberá abstenerse
          de: a) Reproducir, copiar, distribuir, poner a disposición de
          terceros, comunicar públicamente, transformar o modificar la APP o sus
          contenidos, salvo en los casos contemplados en la ley o expresamente
          autorizados por Café Barista o por el titular de dichos derechos.
        </p>
        <p>
          Con sujeción a las condiciones establecidas en el apartado anterior,
          Café Barista concede al usuario una licencia de uso de la APP, no
          exclusiva, gratuita, para uso personal, circunscrita al territorio
          nacional y con carácter indefinido. Dicha licencia se concede también
          en los mismos términos con respecto a las actualizaciones y mejoras
          que se realizasen en la aplicación. Corresponde al Usuario, en todo
          caso, disponer de herramientas adecuadas para la detección y
          desinfección de programas maliciosos o cualquier otro elemento
          informático dañino.
        </p>
        <p>
          Café Barista no se responsabiliza de los daños producidos a equipos
          informáticos durante el uso de la APP. Igualmente, Café Barista no
          será responsable de los daños producidos a los usuarios
        </p>
        <p>
          <strong>IMPORTANTE:</strong> Podemos, sin que esto suponga ninguna
          obligación con el usuario, modificar estas condiciones de uso sin
          avisar en cualquier momento. Si continúas utilizando la aplicación una
          vez realizada cualquier modificación en estas condiciones de uso, esa
          utilización continuada constituirá la aceptación por tu parte de tales
          modificaciones. Si no aceptas estas condiciones de uso ni aceptas
          quedar sujeto a ellas, no debes utilizar la aplicación ni descargar o
          utilizar cualquier software relacionado. El uso que hagas de la
          aplicación queda bajo tu única responsabilidad. No tenemos
          responsabilidad alguna por la eliminación o la incapacidad de
          almacenar o trasmitir cualquier contenido u otra información mantenida
          o trasmitida por la aplicación. No somos responsables de la precisión
          o la fiabilidad de cualquier información o consejo trasmitidos a
          través de la aplicación.
        </p>
        <p>
          Podemos, en cualquier momento, limitar o interrumpir tu uso a nuestra
          única discreción. Hasta el máximo que permite la ley, en ningún caso
          seremos responsables por cualquier pérdida o daños relacionados.
        </p>
        <p>
          El Usuario se compromete a hacer un uso correcto de la APP, de
          conformidad con la Ley, con los presentes Términos y Condiciones de
          uso y con las demás reglamentos e instrucciones que, en su caso,
          pudieran ser de aplicación El Usuario responderá frente a CAFÉ BARISTA
          y frente a terceros de cualesquiera daños o perjuicios que pudieran
          causarse por incumplimiento de estas obligaciones.
        </p>
        <h3>SEGURIDAD</h3>
        <p>
          Café Barista ha implementado medidas de seguridad diseñadas para
          proteger la Información Personal que compartes con nosotros.
        </p>
        <p>
          También monitoreamos regularmente nuestros sistemas en busca de
          posibles vulnerabilidades y ataques, y regularmente buscamos nuevas
          maneras y Servicios de Terceros para mejorar aún más la seguridad de
          nuestros Servicios y la protección de la privacidad de nuestros
          Visitantes. Sin embargo, a pesar de las medidas y los esfuerzos
          realizados por Café Barista , no podemos y no garantizamos la
          protección y seguridad absoluta de su Información Personal o cualquier
          otro Contenido de Usuario que subas, publiques o compartas con Café
          Barista o cualquier otra persona.
        </p>
        <p>
          Por lo tanto, te invitamos a establecer contraseñas seguras y evitar
          proporcionarnos cualquier información confidencial que creas que su
          divulgación podría causar un daño sustancial o irreparable.
        </p>
        <h3>ACTUALIZACIONES DE LA POLÍTICA DE PRIVACIDAD</h3>
        <p>
          Nos reservamos el derecho de modificar esta política de privacidad en
          cualquier momento, por lo tanto, revísala con frecuencia. Los cambios
          y aclaraciones tendrán efecto inmediatamente después de su publicación
          en la página web. Si realizamos cambios sustanciales a esta política,
          te notificaremos que se ha actualizado, para que sepas qué información
          recopilamos, cómo la usamos y en qué circunstancias, si corresponde,
          usamos y/o divulgamos.
        </p>
        <h3>LEGISLACIÓN APLICABLE</h3>
        <p>
          Con carácter general las relaciones entre Café Barista con los
          usuarios de sus servicios telemáticos, presentes en esta APP, se
          encuentran sometidas a la legislación y jurisdicción de la República
          de Guatemala.
        </p>
        <h3>CONTENIDO Y USO</h3>
        <p>
          El Usuario queda informado, y acepta, que el acceso a esta APP, no
          supone el inicio de una relación comercial con Café Barista, quienes
          se reservan el derecho de efectuar sin previo aviso cuantas
          modificaciones vean oportunas en la misma, cambiando, eliminando o
          añadiendo contenidos y/o servicios prestados vía web a través de la
          misma, así como la forma en la que éstos aparezcan.
        </p>
        <h3>PROPIEDAD INDUSTRIAL E INTELECTUAL</h3>
        <p>
          Todos los elementos de esta web (incluyendo textos, imágenes, etc.)
          son titularidad de Café Barista o de terceros que le han dado permiso
          para su uso, al igual que el logo, y cualesquiera otros contenidos
          protegidos como propiedad industrial.
        </p>
        <p>
          Salvo que expresamente se indique lo contrario, queda prohibida
          cualquier modalidad de explotación, reproducción, distribución,
          comunicación pública o transformación sin la autorización expresa y
          por escrito de Café Barista o lo dispuesto en las Condiciones de Uso
          para usuarios registrados.
        </p>
      </div>
    </>
  );
};
