import React from 'react';

export const TopBarPublic = () => {
  return (
    <div className="top-bar top-bar--public">
      <div className="container top-bar__wrap">
        <div className="top-bar__logo">
          <img
            src="/images/cafe-barista-logo.png"
            width="133"
            height="55"
            alt="Cafe Barista"
          />
        </div>
      </div>
    </div>
  );
};
