import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'semantic-ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { singleFetchService } from '../../../helpers/functions';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { ProductActiveItem } from './components/ProductActiveItem';
import { ProductSelectItem } from './components/ProductSelectItem';
import { BackRelative } from '../../../components/Back/BackRelative';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import { versionGroupCreate, versionGroupUpdate, versionGroupDelete } from '../../../actions/product';

const initialData = {
  substitutionGroupID: 0,
  name: {
    value: '',
    error: false
  },
  description: {
    value: '',
    error: false
  },
  status: true,
  menuItems: []
};

export const FormVersionGroup = () => {
  /***  Selector  ***/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let key = useSelector((state) => state.key.key);
  const { loading } = useSelector((state) => state.ui);
  const versionGroupRequest = useSelector((state) => state.product.versionGroupRequest);


  /*** Variables ***/
  const location = useLocation();
  const [keyword, setKeyword] = useState('');
  const [form, setForm] = useState(initialData);
  const [validForm, setValidForm] = useState(false);
  const [productList, setProductList] = useState([]);
  const [listActivated, setListActivated] = useState([]);
  const [productListAux, setProductListAux] = useState([]);
  const substitutionGroupID = (location.pathname.match(/\d+/) || [])[0] || undefined;

  // Modal states
  const [showModal, setShowModal] = useState(false);
  const [resumeModal, setResumeModal] = useState('No podrás revertir esta acción.');
  const [titleModal, setTitleModal] = useState('¿Estás seguro que deseas eliminar este grupo de versiones?');

  /*** Funciones ***/
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      formTouched: true,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });
  };

  const handlerStatus = (e) => {
    setForm({ ...form, formTouched: true, status: Boolean(Number(e.target.value)) });
  };

  // Buscar un producto
  const handleSearch = (e) => {
    const value = e.target.value;

    if (value !== '') {
      const results = productListAux.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });
      setProductList(results);
    } else {
      setProductList(productListAux);
    }
    setKeyword(value);
  }

  // Agregar un producto de la lista
  const handleActiveProduct = async (newProds) => {
    const existe = await listActivated.find(
      (x) => x.menuItemID === newProds.menuItemID
    );

    if (existe === undefined) {
      setForm({ ...form, formTouched: true });
      setListActivated((prevProds) => {
        return [newProds, ...prevProds];
      });
    }
  };

  // Cambiar el orden de un producto agregado
  const handleOnDrag = (result) => {
    const items = Array.from(listActivated);
    const [reorderedItems] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItems);
    items.map((item, index) => items[index].order = index);
    setForm({ ...form, formTouched: true });
    setListActivated(items);
  };

  // Obtener todos los productos
  const getAllProducts = () => {
    const body = singleFetchService('/products/v1/all', "{}", key)
      .then(async (response) => {
        const res = response.responseContent;
        setProductList(res);
        setProductListAux(res);
        console.log(res);
      })
      .catch((err) => console.error(err));
    return body;
  };

  // Obtener un grupo de versiones
  const getOneVersionGroup = () => {
    const data = JSON.stringify({
      requestContent: {
        substitutionGroups: { substitutionGroupID: +substitutionGroupID }
      }
    });

    const body = singleFetchService('/substitution-groups/v1/id', data, key)
      .then(async (response) => {
        const res = response.responseContent;
        setForm({
          status: res?.status,
          substitutionGroupID: +res?.substitutionGroupID,
          name: { value: res?.name || '', error: false },
          description: { value: res?.description || '', error: false },
          menuItems: res?.menuItems || []
        });
        setListActivated(res?.menuItems || [])
      })
      .catch((err) => console.error(err));
    return body;
  };

  // Enviar formulario 
  const onSubmit = (e) => {
    e.preventDefault();

    const substitutionGroups = {
      substitutionGroupID: +substitutionGroupID || undefined,
      type: substitutionGroupID ? 'updated' : 'created',
      status: form.status,
      name: form.name.value,
      description: form.description.value,
      menuItems: listActivated
    };

    const dataCondiment = JSON.stringify({
      requestContent: { substitutionGroups }
    });

    if (!substitutionGroupID) {
      dispatch(versionGroupCreate(dataCondiment, key, substitutionGroups));
    } else {
      dispatch(versionGroupUpdate(dataCondiment, key, substitutionGroups));
    }
  };

  // Eliminar
  const deleteVersionGroup = (id) => {
    const substitutionGroups = {
      name: form.name.value,
      substitutionGroupID: +substitutionGroupID
    }

    const data = JSON.stringify({
      requestContent: { substitutionGroups: substitutionGroups }
    });
    dispatch(versionGroupDelete(data, key, substitutionGroups));
  };

  /***  useEffects  ***/
  // Crear o Editar
  useEffect(() => {
    getAllProducts();
    if (substitutionGroupID != undefined) getOneVersionGroup();
  }, [substitutionGroupID]);

  // Set state when form is invalid
  useEffect(() => {
    if (form.name.value.length < 2 || listActivated.length <= 1) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [listActivated, form]);

  // Re-direccionar
  useEffect(() => {
    if (versionGroupRequest?.info?.type === 'success') navigate('/productos');
  }, [versionGroupRequest]);

  return (
    <>
      <div className="container pbottom50">
        <BackRelative url="/productos" />
        <h1 className="text-center pb-2 mt-2 mb-5">
          {substitutionGroupID ? 'Editar grupo de versiones' : 'Crear grupo de versiones'}
        </h1>
        <div className="medium-wrap">
          <Form onSubmit={onSubmit} className="general-form">
            <div className="row">
              <div className="col-9">
                <div className="mbottom30">
                  <label className={form.name.error ? 'errorField' : ''}>
                    Nombre del grupo de versiones
                    <Input
                      type="text"
                      name="name"
                      minLength="2"
                      maxLength="25"
                      required={true}
                      placeholder="Nombra este grupo"
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.name.value}
                    />
                    <span className="countCharacters dblock text-right font15">
                    {form.name.value?.length <10 && '0'}{form.name.value?.length}/25
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-3 mb-2">
                <div className="radio-group">
                  <label className="has-tooltip-inline">
                    Estado <Tooltip type={'exclamation'} text="Indica si estará disponible o no como disponible en la app." />
                  </label>
                  <ul className="margin0 padding0">
                    <li>
                      <div className="radio relative">
                        <input
                          id="radio1"
                          type="radio"
                          name="sizeType"
                          value="1"
                          onChange={handlerStatus}
                          checked={form.status == true}
                        />
                        <label htmlFor="radio1" className="radio-label"></label>
                        <label htmlFor="radio1">Activo</label>
                      </div>
                    </li>
                    <li>
                      <div className="radio relative">
                        <input
                          id="radio2"
                          type="radio"
                          name="sizeType"
                          value="0"
                          onChange={handlerStatus}
                          checked={form.status == false}
                        />
                        <label htmlFor="radio2" className="radio-label"></label>
                        <label htmlFor="radio2">Inactivo</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="select-drag col-12">
                <div className="select-drag__wrap">
                  <div className="select-drag__left">
                    <h4>Productos existentes</h4>
                    <div className='w-100 px-2 mb-2 mt-0'>
                      <div className="ui left icon input search-in-subcategory text-center m-auto w-100 mt-0">
                        <i aria-hidden="true" className="search icon" style={{ top: '0px' }}></i>
                        <input type="text" placeholder="Buscar" style={{ marginTop: '0px' }} onChange={handleSearch} value={keyword} />
                      </div>
                    </div>
                    <div className="select-drag__list" >
                      {productList?.map((product, index) => (
                        <ProductSelectItem
                          key={product.menuItemID}
                          name={product.name}
                          listActivated={listActivated}
                          menuItemID={product.menuItemID}
                          handleActiveProduct={handleActiveProduct}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="select-drag__right">
                    <h4>Productos seleccionados</h4>
                    <div className="select-drag__list-drag">
                      {listActivated.length > 0 ? (
                        <DragDropContext onDragEnd={handleOnDrag}>
                          <Droppable droppableId="list-subcondiments">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <ProductActiveItem
                                  form={form}
                                  setForm={setForm}
                                  listActivated={listActivated}
                                  setListActivated={setListActivated}
                                  handleActiveProduct={handleActiveProduct}
                                />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : (
                        <p className="text-center font14">
                          Agrega los productos asignados a este grupo de versiones.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!substitutionGroupID ?
              <div className="text-center">
                <Button
                  loading={loading}
                  disabled={!validForm}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                >
                  Guardar grupo
                </Button>
              </div>
              :
              <div className="flex flex--between">
                <Button
                  type="button"
                  className="cta cta--secondary-plain"
                  onClick={() => setShowModal(true)}
                >
                  Eliminar grupo
                </Button>
                <Button
                  loading={loading}
                  disabled={!validForm || !form.formTouched}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                >
                  Guardar cambios
                </Button>
              </div>
            }
          </Form>
        </div>
      </div>
      <DeleteModal
        show={showModal}
        id={substitutionGroupID}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        handleDelete={deleteVersionGroup}
        withContent={false}
        isLoading={loading}
      />
    </>
  );
}
