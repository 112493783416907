import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Icon } from 'semantic-ui-react';
import { TopBarPublic } from '../../../components/TopBar/TopBarPublic';
import { firstPassword } from '../../../actions/pass';

export const CreateFirstPassword = () => {
  const { loading } = useSelector((state) => state.ui);

  // Get location
  const location = useLocation();
  const { correo = '', token = '' } = queryString.parse(location.search);
  if (!correo || !token) {
    // window.location.href = '/';
    alert('Please enter password');
  }

  // Dispatch
  const dispatch = useDispatch();

  // State Show hide password
  const [showPassword, setShowPassword] = useState(false);
  const handlerShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Validate lenght of password
  const [validInput, setValidInput] = useState({
    password: '',
  });
  const { password } = validInput;

  useEffect(() => {
    if (password.length > 5) {
      document.querySelector('#DisableButton').classList.remove('disabled');
    } else {
      document.querySelector('#DisableButton').classList.add('disabled');
    }
  }, [password]);

  const handleInputChange = ({ target }) => {
    setValidInput({
      ...validInput,
      [target.name]: target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    var data = JSON.stringify({
      requestContent: {
        credentials: {
          email: correo,
          password: password,
          token: token,
        },
      },
    });

    dispatch(firstPassword(data, correo));
  };

  return (
    <>
      <TopBarPublic />
      <div className="container">
        <div className="row full-height">
          <div className="col text-center">
            <h1>Crea una contraseña</h1>
            <p>
              Crea una contraseña para tu usuario robz@barista.com para iniciar
              sesión en el administrardor.
            </p>
            <Form
              onSubmit={onSubmit}
              className="general-form small-form mtop30"
            >
              <label>
                Crea una contraseña
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Crea una nueva contraseña"
                  className="input-text"
                  value={password}
                  onChange={handleInputChange}
                  icon={
                    showPassword ? (
                      <Icon
                        name="eye slash outiline"
                        link
                        onClick={handlerShowPassword}
                      />
                    ) : (
                      <Icon name="eye" link onClick={handlerShowPassword} />
                    )
                  }
                />
              </label>
              <Button
                loading={loading}
                id="DisableButton"
                className="cta disabled"
              >
                Continuar
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
