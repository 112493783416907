import moment from 'moment';
import { types } from '../../../../types/types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestDispatch } from "../../../../actions/schedule";

const convertHour = (time, meridiem) => {
  const hour = time.substring(0, 2);
  const minute = time.substring(3, 5)
  const formatTime = `${hour.trim()}:${minute.trim()} ${meridiem}`;
  return moment(formatTime, ["h:mm A"]).format("HH:mm");
}

export const FormEditSchedule = ({
  index,
  handlerModal,
  scheduleID,
  schedule,
  initialData,
  openFormIndex,
  setOpenFormIndex
}) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialData);
  const [validForm, setValidForm] = useState(false);
  const scheduleRequest = useSelector((state) => state.schedule.scheduleRequest);

  /*** Funciones ***/
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleValidateTime = (e) => {
    e.preventDefault();
    const regex = /^(\d{2}):(\d{2})$/;
    const valregex = regex.test(form[e.target.name].value);
    const parsenumber = Number(form[e.target.name].value.replace(/\D/g, ''));
    const validator = valregex && parsenumber <= 1259 ? false : true;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      formTouched: true,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });

    return {
      ...form,
      formTouched: true,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    };
  };

  const handleChangeTime = (e) => {
    e.preventDefault();
    let value = e.target.value.replace(/[^0-9:]/g, '')

    setForm({
      ...form,
      formTouched: true,
      [e.target.name]: {
        value: value.slice(0, 5),
        ...form[e.target.name].error
      }
    });
  };


  const handleChangeTimeMeridiem = (e) => {
    e.preventDefault();

    const meridiem = form[e.target.name] == 'AM' ? 'PM' : 'AM';
    setForm({
      ...form,
      formTouched: true,
      [e.target.name]: meridiem
    });
  };

  const handleOpenEdit = () => {
    if (index + 1 === openFormIndex) {
      setOpenFormIndex(0)
    } else {
      setOpenFormIndex(index + 1)
    }
  };

  const onSubmitUpdate = async (e) => {
    e.preventDefault();

    if (validForm) {
      const endTime = await convertHour(form.formattedEndHour.value, form.meridiemEnd);
      const initTime = await convertHour(form.formattedInitHour.value, form.meridiemInit);

      const productSchedule = {
        scheduleID: form.scheduleID,
        name: form.name.value,
        description: form.name.value,
        endHour: parseFloat(endTime.substring(0, 2)),
        endMinutes: parseFloat(endTime.substring(3, 5)),
        initHour: parseFloat(initTime.substring(0, 2)),
        initMinutes: parseFloat(initTime.substring(3, 5)),
      };

      const dataProductSchedule = JSON.stringify({
        requestContent: { productSchedule }
      });

      dispatch(requestDispatch('update', 'Updated', dataProductSchedule, productSchedule));
    }
  };

  useEffect(() => {
    if (form.name.value.length > 0 && form.formattedEndHour.value.length == 5 && form.formattedInitHour.value.length == 5) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [form]);

  useEffect(() => {
    if (scheduleRequest?.info?.type === 'success' && index + 1 === openFormIndex) {
      setForm({ ...form, formTouched: false });
      dispatch({ type: types.scheduleRequest, payload: {} });
    }
  }, [scheduleRequest]);

  useEffect(() => {
    setForm({
      scheduleID: schedule?.scheduleID,
      initHour: schedule?.initHour,
      initMinutes: schedule?.initMinutes,
      endHour: schedule?.endHour,
      endMinutes: schedule?.endMinutes,
      name: {
        value: schedule.name,
        error: false
      },
      description: {
        value: schedule.description,
        error: false
      },
      // Auxiliar
      formTouched: false,
      formattedInitHour: {
        value: schedule?.formattedInitHour.substring(0, 5) || '',
        error: false
      },
      formattedEndHour: {
        value: schedule?.formattedEndHour.substring(0, 5) || '',
        error: false
      },
      meridiemEnd: schedule?.meridiemEnd || 'AM',
      meridiemInit: schedule?.meridiemInit || 'AM',
    })
  }, []);

  return (
    <>
      <div key={index} className="col-12 schedule-item">
        {/* Encabezado */}
        <div className="schedule-item__top">
          <div className="schedule-item__left">
            <p>{schedule?.formattedSchedule}</p>
          </div>
          <div className='schedule-item__right'>
            <button type="button" className={index + 1 === openFormIndex ? 'text-lightgray' : null} onClick={handleOpenEdit}>
              <span className="icon-edit"></span> Editar
            </button>
            <button
              type="button"
              onClick={() => {
                handlerModal(scheduleID, index);
              }}
            >
              <span className="icon-delete"></span> Eliminar
            </button>
          </div>
        </div>
        <div className={`schedule-item__middle ${index + 1 === openFormIndex ? 'schedule-item__middle--open' : ''}`}>
          <div className="m-0">
            <div className="row p-0 row-group">
              <div className="col-6">
                <label className={form.name.error ? 'errorField' : 'dblock'}>
                  Nombre de horario
                  <input
                    required
                    type="text"
                    name="name"
                    minLength="1"
                    className="input-text"
                    value={form.name.value}
                    onBlur={handleValidate}
                    onChange={handleChange}
                    placeholder="Nombra este horario"
                  />
                </label>
              </div>
              <div className="col-3">
                <label className={form.formattedInitHour.error ? 'errorField' : ''}>
                  Hora inicio
                  <div className="input-time">
                    <input
                      type="text"
                      id="formattedInitHour"
                      name="formattedInitHour"
                      placeholder="HH:MM"
                      data-valid-example="HH:MM"
                      onChange={handleChangeTime}
                      onBlur={handleValidateTime}
                      value={form.formattedInitHour.value}
                      required
                    />
                    <button
                      type="button"
                      name='meridiemInit'
                      className="input-time__button"
                      onClick={handleChangeTimeMeridiem}
                    >
                      {form.meridiemInit}
                    </button>
                  </div>
                  {form.formattedInitHour.error &&
                    <span className="error-input-text-tag">
                      Hora invalido (HH:MM)
                    </span>
                  }
                </label>
              </div>
              <div className="col-3 relative">
                <label className={form.formattedEndHour.error ? 'errorField' : ''}>
                  Hora fin
                  <div className="input-time">
                    <input
                      type="text"
                      id="formattedEndHour"
                      name="formattedEndHour"
                      placeholder="HH:MM"
                      data-valid-example="HH:MM"
                      onChange={handleChangeTime}
                      onBlur={handleValidateTime}
                      value={form.formattedEndHour.value}
                      required
                    />
                    <button
                      type="button"
                      name='meridiemEnd'
                      className="input-time__button"
                      onClick={handleChangeTimeMeridiem}
                    >
                      {form.meridiemEnd}
                    </button>
                  </div>
                  {form.formattedEndHour.error &&
                    <span className="error-input-text-tag">
                      Hora invalido (HH:MM)
                    </span>
                  }
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* Guardar */}
        {index + 1 === openFormIndex &&
          <div className="mt-3 schedule-item__bottom">
            <div className={!validForm || !form.formTouched ? 'schedule-item__right__disabled' : 'schedule-item__right'}>
              <button type="button" disabled={!validForm || !form.formTouched} onClick={onSubmitUpdate}>
                <span className="icon-save"></span> Guardar cambios
              </button>
            </div>
          </div>
        }
      </div>
    </>
  );
};
