import React, { useState, useEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'semantic-ui-react';
import { initialDeleteGroup,  initialUpdateGroup,} from '../../../actions/groups';
import { BackRelative } from '../../../components/Back/BackRelative';
import { LoaderData } from '../../../components/Loader/LoaderData';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import { singleFetchService } from '../../../helpers/functions';
import { CategoryActiveItem } from './components/CategoryActiveItem';
import { CategorySelectItem } from './components/CategorySelectItem';

export const EditGroupCategory = () => {
  const dispatch = useDispatch();

  // Get key
  let key = useSelector((state) => state.key.key);
  const { loading } = useSelector((state) => state.ui);
  const [loadingData, setLoadingData] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  // Get category id
  const categoriesGroupID = (location.pathname.match(/\d+/) || [])[0];
  console.log(categoriesGroupID);

  if (!categoriesGroupID) {
    navigate('/categorias');
  }

  // State form and list categories active, validate Form
  const [stateForm, setStateForm] = useState({});
  const [listCategoriesActivated, setListCategoriesActivated] = useState([{}]);
  const [validForm, setValidForm] = useState(false);

  // Request data
  var data = JSON.stringify({
    requestContent: {
      categoryGroup: {
        categoriesGroupID: +categoriesGroupID,
      },
    },
  });

  // Function to get data group edit
  const responseData = () => {
    const body = singleFetchService('/categories_groups/v1/id', data, key)
      .then((res) => {
        setStateForm(res.responseContent);
        setLoadingData(false);
        setListCategoriesActivated(res.responseContent.categories);
      })
      .catch((err) => console.log(err));
    return body;
  };

  // Get category by id
  useEffect(() => {
    responseData();
  }, [categoriesGroupID]);

  // Validate each imput form
  const { id, name, description } = stateForm;

  useEffect(() => {
    if (name?.length === 0 || description?.length === 0) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [name, description]);

  const handlerName = (e) => {
    e.preventDefault();
    setStateForm({ ...stateForm, name: e.target.value });
  };

  const handlerDesc = (e) => {
    e.preventDefault();
    setStateForm({ ...stateForm, description: e.target.value });
  };

  // List categories and list categories activated
  const { list } = useSelector((state) => state.category);

  // Adding categories to list activated
  const handleActiveCategory = (newCat) => {
    setListCategoriesActivated((prevCats) => {
      return [newCat, ...prevCats];
    });
  };

  // Change order list categories activated
  const handleOnDrag = (result) => {
    // console.log(result);
    const items = Array.from(listCategoriesActivated);
    const [reorderedItems] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItems);

    items.map((item, index) => {
      items[index].order = index;
    });

    setListCategoriesActivated(items);
  };

  const [isCategoryAdded, setIsCategoryAdded] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    var data = JSON.stringify({
      requestContent: {
        categoriesGroup: {
          id: +id,
          name: name,
          description: description,
          categories: listCategoriesActivated,
        },
      },
    });

    const categoryGroup = {
      id: +id,
      name: name,
      description: description,
      categories: listCategoriesActivated,
    };

    // console.log(categoryGroup);

    dispatch(initialUpdateGroup(data, key, categoryGroup));
  };

  //   Modal states
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [resumeModal, setResumeModal] = useState('');
  const [idSchedule, setIdSchedule] = useState(false);

  const handlerModal = (type, index) => {
    switch (type) {
      case 'delete':
        setTitleModal(
          '¿Estás seguro que deseas eliminar este grupo de categorías?'
        );
        setResumeModal('No podrás revertir esta acción.');
        setShowModal(true);
        setIdSchedule(index);
        break;
      case 'noDelete':
        setTitleModal(
          '¡No es posible eliminar un grupo con categorías asignadas!'
        );
        setResumeModal(
          'Para eliminarlo, primero remueve las categorías que tiene asignadas.'
        );
        setShowModal(true);
        setIdSchedule(index);
        break;
      default:
        setTitleModal(null);
        setResumeModal(null);
        setShowModal(false);
        break;
    }
  };

  const deleteGroup = (id) => {
    var data = JSON.stringify({
      requestContent: {
        categoriesGroup: {
          id: +id,
          name: name,
          description: description,
          categories: listCategoriesActivated,
        },
      },
    });

    const categoryGroup = {
      name: '',
      description: '',
      categories: listCategoriesActivated,
    };

    // console.log(data);

    dispatch(initialDeleteGroup(data, key, categoryGroup));
    navigate('/categorias');
  };

  return (
    <>
      <div className="container">
        <BackRelative url="/categorias" />
        <h1 className="text-center m-0 pbottom30">
          Editar grupo de categorías
        </h1>
        <div className="medium-wrap">
          {loadingData ? (
            <LoaderData />
          ) : (
            <Form onSubmit={onSubmit} className="general-form">
              <div className="mbottom30">
                <label>
                  Nombre de grupo
                  <Input
                    type="text"
                    name="name"
                    placeholder="Nombra el grupo de categorías"
                    maxLength="25"
                    onChange={handlerName}
                    value={name}
                    required
                  />
                  <span className="countCharacters dblock text-right font15">
                    {name?.length}/25
                  </span>
                </label>
              </div>
              <div className="mbottom30">
                <label>
                  Descripción
                  <textarea
                    name="description"
                    rows="30"
                    placeholder="Proporciona una descripción breve..."
                    maxLength="150"
                    onChange={handlerDesc}
                    value={description}
                    required
                  ></textarea>
                  <span className="countCharacters dblock text-right font15">
                    {description?.length}/150
                  </span>
                </label>
              </div>
              <div className="select-drag">
                <h3>Selección de categorías</h3>
                <div className="select-drag__wrap">
                  <div className="select-drag__left">
                    <h4>Individuales</h4>
                    <div className="select-drag__list">
                      {list?.map((cat, index) => (
                        <CategorySelectItem
                          key={index}
                          handleActiveCategory={handleActiveCategory}
                          categoryID={cat.categoryID}
                          name={cat.name}
                          description={cat.description}
                          status={cat.status}
                          schedule={cat.schedule}
                          order={cat.order}
                          image={cat.image}
                          products={cat.products}
                          listCategoriesActivated={listCategoriesActivated}
                          isCategoryAdded={isCategoryAdded}
                          setIsCategoryAdded={setIsCategoryAdded}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="select-drag__right">
                    <h4>Seleccionados</h4>
                    <div className="select-drag__list-drag">
                      {listCategoriesActivated?.length > 0 ? (
                        <DragDropContext onDragEnd={handleOnDrag}>
                          <Droppable droppableId="list-categories">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <CategoryActiveItem
                                  listCategoriesActivated={
                                    listCategoriesActivated
                                  }
                                  setListCategoriesActivated={
                                    setListCategoriesActivated
                                  }
                                  handleActiveCategory={handleActiveCategory}
                                  isCategoryAdded={isCategoryAdded}
                                  setIsCategoryAdded={setIsCategoryAdded}
                                />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      ) : (
                        <p className="text-center font14">
                          Agrega las categorías que conforman este grupo.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex--between">
                <Button
                  type="button"
                  onClick={() => {
                    handlerModal(
                      listCategoriesActivated?.length > 1
                        ? 'noDelete'
                        : 'delete'
                    );
                  }}
                  className="cta cta--secondary-plain"
                >
                  Eliminar
                </Button>
                <Button
                  loading={loading}
                  className={`cta cta--secondary ${
                    validForm ? '' : 'disabled'
                  }`}
                >
                  Guardar cambios
                </Button>
              </div>
            </Form>
          )}
        </div>
      </div>

      <DeleteModal
        show={showModal}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        id={categoriesGroupID}
        handleDelete={deleteGroup}
        withContent={listCategoriesActivated?.length > 1 ? true : false}
        isLoading={loading}
      />
    </>
  );
};
