import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { Button, Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import ImageUploading from 'react-images-uploading';
import { BackRelative } from '../../../components/Back/BackRelative';
import { createCategory } from '../../../actions/category';
import { schedulesGetAll } from '../../../actions/schedule';
import { Tooltip } from '../../../components/Tooltip/Tooltip';

const initialFormState = {
  nameCategory: '',
  descCategory: '',
  status: 1,
  schedule: 0,
};

export const NewCategory = () => {
  // Get token state
  const { key } = useSelector((state) => state.key);
  // Loading state
  const { loading } = useSelector((state) => state.ui);
  const { schedules } = useSelector((state) => state.schedule);
  const category = useSelector((state) => state.category);

  // Dispatch
  const dispatch = useDispatch();

  // Get schedules
  useEffect(() => {
    const raw = JSON.stringify({
      info: {
        sessionId: 'tokenid',
      },
      requestContent: {},
    });
    // console.log('login horarios', body.info.sessionId);
    dispatch(schedulesGetAll('all', raw));
  }, [dispatch]);

  // Blank Input
  const [blankInput, setBlankInput] = useState(false);

  // List schedules
  const arraySchedule = [];
  const listSchedule = schedules?.map((item) => {
    arraySchedule.push({
      key: item.id,
      value: item.id,
      text: item.name,
    });
    // setSchedules(arraySchedule);
  });

  // Input file image
  const [images, setImages] = useState([]);
  const maxNumber = 1;

  const onChange = (imageList) => {
    // data for submit
    setImages(imageList);
  };

  // State new category
  const [inputsForm, setInputsForm] = useState(initialFormState);

  const { nameCategory, descCategory, status, schedule } = inputsForm;

  const handleInputChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'nameCategory' || e.target.name === 'descCategory') {
      if (validator.isEmpty(e.target.value)) {
        setBlankInput(true);
      } else {
        setBlankInput(false);
      }
    }

    setInputsForm({
      ...inputsForm,
      [e.target.name]: e.target.value,
    });
    // debugger;
  };

  useEffect(() => {
    if (
      !blankInput &&
      nameCategory.length > 3 &&
      descCategory.length > 3 &&
      images.length !== 0 &&
      schedule !== 0 &&
      status !== 0
    ) {
      document.querySelector('#buttonSubmit').classList.remove('disabled');
    } else {
      document.querySelector('#buttonSubmit').classList.add('disabled');
    }
  }, [nameCategory, descCategory, images, schedule, status]);

  useEffect(() => {
    setInputsForm(initialFormState);
    setImages([]);
  }, [category.creation.success]);

  // console.log(images);

  // console.log(inputsForm);
  const onSubmit = (e) => {
    e.preventDefault();
    var data = JSON.stringify({
      requestContent: {
        category: {
          name: nameCategory,
          description: descCategory,
          image: images[0].data_url,
          status: +status,
          schedule: schedule,
        },
      },
    });

    const category = {
      name: nameCategory,
      description: descCategory,
      image: images[0].data_url,
      status: +status,
      schedule: schedule,
    };

    dispatch(createCategory(data, key, category));
  };

  return (
    <>
      <div className="container">
        <BackRelative url="/categorias" />
        <div className="medium-wrap">
          <h1 className="text-center m-0 pbottom30">Crear categoría</h1>
          <Form onSubmit={onSubmit} className="general-form">
            <div className="mbottom30">
              <label>
                Nombre de categoría
                <Input
                  type="text"
                  name="nameCategory"
                  placeholder="Nombra la categoría"
                  maxLength="25"
                  value={inputsForm.nameCategory}
                  onChange={handleInputChange}
                  required
                />
                <span className="countCharacters dblock text-right font15">
                  {nameCategory.length}/25
                </span>
              </label>
            </div>
            <div className="mbottom30">
              <label>
                Descripción
                <textarea
                  name="descCategory"
                  rows="30"
                  placeholder="Proporciona una descripción breve..."
                  maxLength="150"
                  value={inputsForm.descCategory}
                  onChange={handleInputChange}
                  required
                ></textarea>
                <span className="countCharacters dblock text-right font15">
                  {descCategory.length}/150
                </span>
              </label>
            </div>
            <div className="row m-0">
              <div className="col-4">
                <div className="radio-group">
                  <label>Estado</label>
                  <ul className="margin0 padding0">
                    <li>
                      <div className="radio relative">
                        <input
                          id="radio1"
                          type="radio"
                          name="status"
                          value={inputsForm.status}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="radio1" className="radio-label"></label>
                        <label htmlFor="radio1">Activo</label>
                      </div>
                    </li>
                    <li>
                      <div className="radio relative">
                        <input
                          id="radio2"
                          type="radio"
                          name="status"
                          value={inputsForm.status}
                          onChange={handleInputChange}
                        />
                        <label htmlFor="radio2" className="radio-label"></label>
                        <label htmlFor="radio2">Inactivo</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-8">
                <label>
                  Horario habilitado
                  <Dropdown
                    name="categorySchedule"
                    className="mtop10"
                    placeholder="Seleccionar horario..."
                    value={inputsForm.schedule}
                    fluid
                    // search
                    selection
                    options={arraySchedule}
                    lazyLoad
                    onChange={(e, data) => {
                      setInputsForm({ ...inputsForm, schedule: data.value });
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="mbottom30">
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={['jpg', 'png', 'jpeg']}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper ptop40">
                    <label className="dblock pbottom15 has-tooltip-inline">
                      Imagen
                      <Tooltip text="Medida 331x176px" />
                    </label>
                    <button
                      type="button"
                      className={`cta cta--secondary-light cta--tiny margin0 ${
                        imageList.length > 0 ? 'dnone' : null
                      }`}
                      style={isDragging ? { color: '#333' } : null}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Agregar imagen
                    </button>
                    {imageList.map((image, index) => (
                      <div key={index} className="image-item ptop15">
                        <img src={image.data_url} alt="" width="350" />
                        <div className="image-item__btn-wrapper ptop10">
                          <button
                            type="button"
                            className="link-button"
                            onClick={() => onImageUpdate(index)}
                          >
                            <Icon name="edit" size="small" />
                            Cambiar imagen
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
            </div>
            <div className="text-center">
              <Button
                id="buttonSubmit"
                className="cta cta--secondary disabled"
                loading={loading}
              >
                Guardar categoría
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
