import { types } from '../types/types';
import { fetchService } from '../helpers/fetch';
import { prepareGroups } from '../helpers/prepareGroups';
import { finishLoading, startLoading } from './ui';
import { revalidateKey } from './key';

import { toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';

// Create group
export const startCreateGroup = (data, token, catGroup) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/categories_groups/v1/create', data, token)
      .then((res) => {
        dispatch(createGroup(catGroup));
        dispatch(finishLoading(true));
        toast.success('Grupo creado con exito!');
      })
      .catch((err) => {
        dispatch(finishLoading(false));
        toast.error('Error al crear grupo!');
      });
  };
};

export const createGroup = (group) => {
  return {
    type: types.groupCategoryCreate,
    payload: group,
  };
};

// Get all

export const startGetAllGroups = (data, token) => {
  return async (dispatch) => {
    // data = {info:{}}
    const body = fetchService('/categories_groups/v1/all', data, token)
      .then((res) => {
        const groups = prepareGroups(res.responseContent);
        console.log('groups', groups);
        dispatch(getAllGroups(groups));
      })
      .catch((err) => {
        console.log(err);
        //dispatch(revalidateKey(err.info.sessionId));
      });
  };
};

export const getAllGroups = (group) => {
  return {
    type: types.groupCategoryAll,
    payload: group,
  };
};

// Get by id

export const initialGetGroupById = (data, token) => {
  return async (dispatch) => {
    const body = fetchService('/categories_groups/v1/id', data, token)
      .then((res) => {
        // console.log(res.responseContent);
        dispatch(getGroupById(res.responseContent));
      })
      .catch((err) => {
        //dispatch(revalidateKey(err.info.sessionId));
      });
  };
};

const getGroupById = (group) => {
  return {
    type: types.groupCategoryById,
    payload: group,
  };
};

// Update group

export const initialUpdateGroup = (data, token, group) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/categories-groups/v1/update', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(updateGroup(group));
        toast.success('Cambios guardados exitosamente');
      })
      .catch((err) => {
        dispatch(finishLoading());
        //dispatch(revalidateKey(err.info.sessionId));
        toast.error('No se realizo la actualización');
      });
  };
};

// Update reorder categories by group
export const initialUpdateGroupReorderCategories = (data, token, group) => {
  return async (dispatch) => {
    dispatch(startLoading());
    
    const body = fetchService('/categories-groups/v1/update', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(updateGroup(group));
      })
      .catch((err) => {
        dispatch(finishLoading());
        //dispatch(revalidateKey(err.info.sessionId));
      });
  };
};

const updateGroup = (group) => {
  return {
    type: types.groupCategoryUpdate,
    payload: group,
  };
};

// Delete group

export const initialDeleteGroup = (data, token, group) => {
  return async (dispatch) => {
    // console.log(data);
    dispatch(startLoading());
    const body = fetchService('/categories_groups/v1/delete', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(deleteGroup(group));
        toast.success('Grupo eliminado con éxito.');
      })
      .catch((err) => {
        dispatch(finishLoading());
       // dispatch(revalidateKey(err.info.sessionId));
        toast.error('No se pudo eliminar el grupo');
      });
  };
};

const deleteGroup = (group) => {
  return {
    type: types.groupCategoryDelete,
    payload: group,
  };
};
