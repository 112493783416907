import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { Button, Form, Input, Icon } from 'semantic-ui-react';
import { singleFetchService } from '../../../helpers/functions';
import { Droppable, DragDropContext } from 'react-beautiful-dnd';
import { BackRelative } from '../../../components/Back/BackRelative';
import { CategoryActiveItem } from './components/CategoryActiveItem';
import { CategorySelectItem } from './components/CategorySelectItem';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import { startCreateCondimentGroup, initialUpdateGroup, initialDeleteGroup } from '../../../actions/condimentGroup';

const initialData = {
  name: {
    value: '',
    error: false
  },
  nameWeb: {
    value: '',
    error: false
  },
  description: {
    value: '',
    error: false
  },
  minimumSelection: {
    value: 1,
    error: false
  },
  maximumSelection: {
    value: 1,
    error: false
  },

  status: false, // 0,
  condiments: [],
  condimentGroupType: 0
}

export const FormCondimentGroup = () => {
  /***  Selector  ***/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let key = useSelector((state) => state.key.key);
  const { loading } = useSelector((state) => state.ui);
  const { allCondiments } = useSelector((state) => state.condiment);
  const groupCondimentRequest = useSelector((state) => state.condimentGroup.request);
  
  /***  Variables ***/
  // Formulario
  const [keyword, setKeyword] = useState('');
  const [form, setForm] = useState(initialData);
  const [validForm, setValidForm] = useState(false);
  const [mostrarSlider, setMostrarSlider] = useState(false);
  const [isCategoryAdded, setIsCategoryAdded] = useState(false);
  const [allCondimentsAux, setAllCondimentsAux] = useState(allCondiments);

  // Obtener parametro GET (condimentGroup)
  const location = useLocation();
  const condimentGroupID = (location.pathname.match(/\d+/) || [])[0] || undefined;
  const [listCondimentActivated, setListCondimentActivated] = useState([]);

  // Modal states
  const [titleModal, setTitleModal] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [resumeModal, setResumeModal] = useState('');

  /*** Funciones ***/
  // Funciones de Formulario
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });
  };

  const handlerStatus = (e) => {
    // e.preventDefault();

    if (listCondimentActivated.length > 0) {
      setForm({ ...form, status: Number(e.target.value) });
    }
  };

  const handlerStatusByListCondiment = (value) => {
    setForm({ ...form, status: value });
  };

  const handlerMaximun = (e) => {
    e.preventDefault();
    const maxValue = parseInt(e.target.value, 10);
    if (!isNaN(maxValue) && maxValue >= 0 && maxValue >= form.minimumSelection.value) {
      setForm({
        ...form,
        maximumSelection: {
          value: maxValue,
          ...form.maximumSelection.error
        }
      });
    }
  };

  const handlerMinimun = (e) => {
    e.preventDefault();
    const minValue = parseInt(e.target.value, 10);
    if (!isNaN(minValue) && minValue >= 0 && minValue <= form.maximumSelection.value) {
      setForm({
        ...form,
        minimumSelection: {
          value: minValue,
          ...form.minimumSelection.error
        }
      });
    }
  };

  // Funciones de condimentos
  const handleSearch = (e) => {
    const value = e.target.value;

    if (value !== '') {
      const results = allCondiments.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });
      setAllCondimentsAux(results);
    } else {
      setAllCondimentsAux(allCondiments);
    }
    setKeyword(value);
  }
  
  const handleActiveCategory = async (newCat) => {
    // Agregar categorías a la lista activada

    if (mostrarSlider && listCondimentActivated.length >= 3) {
      //alert('¡Solo puedes seleccionar hasta tres condimentos cuando el slider está activo!');
      return;
    }

    const existe = await listCondimentActivated.find(
      (x) => x.condimentID === newCat.condimentID
    );

    if (existe === undefined) {
      setListCondimentActivated((prevCats) => {
        const isDefault = listCondimentActivated.length === 0 ? true : false;

        const updatedList = [
          { ...newCat, default: isDefault },
          ...prevCats,
        ];

        return updatedList;
      });
    }
  };

  const handleOnDrag = (result) => {
    // Ordenar la lista de categorías activadas
    const items = Array.from(listCondimentActivated);
    const [reorderedItems] = items.splice(result.source.index, 1);

    items.splice(result.destination.index, 0, reorderedItems);

    items.map((item, index) => {
      item.order = index;
    });

    setListCondimentActivated(items);
  };

  const handleSliderChange = () => {
    setMostrarSlider(!mostrarSlider);
    if (!mostrarSlider) {
      setForm({
        ...form,
        maximumSelection: { ...form.maximumSelection, value: 3 },
        minimumSelection: { ...form.minimumSelection, value: 1 }
      });
    } else {
      setForm({
        ...form,
        maximumSelection: { ...form.maximumSelection, value: 0 },
        minimumSelection: { ...form.minimumSelection, value: 0 }
      });
    }
  };

  // Obtener un grupo
  const getOneCondimentGroup = () => {
    const data = JSON.stringify({
      requestContent: {
        condimentGroup: { condimentGroupID: +condimentGroupID }
      }
    });

    const body = singleFetchService('/condiments-groups/v1/id', data, key)
      .then((response) => {
        const res = response.responseContent;
        console.log('Resultado: ', res);
        setForm({
          status: res?.status || false, // Number(res?.status) || 0,
          condiments: res?.condiments || [],
          name: { value: res?.name || '', error: false },
          nameWeb: { value: res?.nameWeb || '', error: false },
          description: { value: res?.description || '', error: false },
          minimumSelection: { value: Number(res?.minimumSelection), error: false },
          maximumSelection: { value: Number(res?.maximumSelection), error: false }
        });
        setListCondimentActivated(res?.condiments || [])
      })
      .catch((err) => console.log(err));
    return body;
  };

  // Enviar formulario 
  const onSubmit = (e) => {
    e.preventDefault();

    const groupCondiment = {
      type: condimentGroupID ? 'create' : 'update',
      condimentGroupId: +condimentGroupID || undefined,
      name: form.name.value,
      nameWeb: form.nameWeb.value,
      description: form.description.value,
      minimumSelection: form.minimumSelection.value,
      maximumSelection: form.maximumSelection.value,
      status: listCondimentActivated.length > 0 ? form.status : false, // listCondimentActivated.length > 0 ? +form.status : 0,
      condiments: listCondimentActivated
    };

    const dataCondiment = JSON.stringify({
      requestContent: {
        condimentGroup: groupCondiment
      }
    });

    if (!condimentGroupID) {
      dispatch(startCreateCondimentGroup(dataCondiment, key, groupCondiment));
    } else {
      dispatch(initialUpdateGroup(dataCondiment, key, groupCondiment));
    }
  };

  // Eliminar grupo
  const handlerModal = (type, index) => {
    switch (type) {
      case 'delete':
        setTitleModal('¿Estás seguro que deseas eliminar este grupo de condimentos?');
        setResumeModal('No podrás revertir esta acción.');
        setShowModal(true);
        break;
      case 'noDelete':
        setTitleModal('¡No es posible eliminar un grupo con condimentos asignadas!');
        setResumeModal('Para eliminarlo, primero remueve los condimentos que tiene asignadas.');
        setShowModal(true);
        break;
      default:
        setTitleModal(null);
        setResumeModal(null);
        setShowModal(false);
        break;
    }
  };

  const deleteGroup = (id) => {
    const condimentGroup = {
      name: form.name.value,
      nameWeb: form.nameWeb.value,
      condimentGroupId: +condimentGroupID || +id
    }

    const data = JSON.stringify({
      requestContent: { condimentGroup: condimentGroup }
    });;

    dispatch(initialDeleteGroup(data, key, condimentGroup));
  };

  /***  useEffects  ***/
  // Crear o Editar
  useEffect(() => {
    if (condimentGroupID != undefined) {
      getOneCondimentGroup();
    }
  }, [condimentGroupID]);

  // Validar formulario
  useEffect(() => {
    if (form.name.value.length > 5 && form.nameWeb.value.length > 5 && form.description.value.length > 5) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [form]);

  // Re-direccionar
  useEffect(() => {
    if (groupCondimentRequest?.info?.type === 'success') navigate('/condimentos');
  }, [groupCondimentRequest]);

  return (
    <>
      <div className="container pbottom50">
        <BackRelative url="/condimentos" />
        <h1 className="text-center m-0 pbottom30">
          {!condimentGroupID ? 'Crear grupo de condimentos' : 'Editar grupo de condimentos'}
        </h1>
        <div className="medium-wrap">
          <Form onSubmit={onSubmit} className="general-form">
            <h3>Información general</h3>
            <div className="row">
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.name.error ? 'errorField' : ''}>
                    Nombre a mostrar en la app
                    <Input
                      type="text"
                      name="name"
                      minLength="5"
                      maxLength="25"
                      required={true}
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.name.value}
                      placeholder="Nombre para clientes"
                    />
                    <span className="countCharacters dblock text-right font15">
                      {form.name.value?.length}/25
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.nameWeb.error ? 'errorField' : ''}>
                    Nombre en administrador
                    <Input
                      type="text"
                      name="nameWeb"
                      minLength="5"
                      maxLength="25"
                      required={true}
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.nameWeb.value}
                      placeholder="Nombre para uso interno"
                    />
                    <span className="countCharacters dblock text-right font15">
                      {form.nameWeb.value?.length}/25
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mbottom30">
              <label className={form.description.error ? 'errorField' : ''}>
                Descripción
                <textarea
                  rows="30"
                  minLength="5"
                  maxLength="300"
                  required={true}
                  name="description"
                  placeholder="Proporciona una descripción breve..."
                  onBlur={handleValidate}
                  onChange={handleChange}
                  value={form.description.value}
                ></textarea>
                <span className="countCharacters dblock text-right font15">
                  {form.description.value?.length}/300
                </span>
              </label>
            </div>
            <div className="row m-0 pbottom100">
              <div className="col-3">
                <div className="radio-group">
                  <label className="has-tooltip-inline">
                    Estado
                    <Tooltip text="Selecciona 'activo' si va dentro de la aplicación o 'Inactivo' para que no se muestre." />
                  </label>
                  <ul className="margin0 padding0">
                    <li>
                      <div className="radio relative">
                        <input
                          id="radio1"
                          type="radio"
                          name="status"
                          onChange={handlerStatus}
                          value="1"
                          checked={form.status && true}
                          // checked={Number(form.status) === 1 && true}
                        />
                        <label htmlFor="radio1" className="radio-label"></label>
                        <label htmlFor="radio1">Activo</label>
                      </div>
                    </li>
                    <li>
                      <div className="radio relative">
                        <input
                          id="radio2"
                          type="radio"
                          name="status"
                          value="0"
                          onChange={handlerStatus}
                          checked={!form.status && true}
                          // checked={Number(form.status) === 0 && true}
                        />
                        <label htmlFor="radio2" className="radio-label"></label>
                        <label htmlFor="radio2">Inactivo</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-9">
                <div className="row">
                  <div className="col-6">
                    <div className="maximum-units">
                      <label className="has-tooltip-inline pb-1">
                        Mínimo de unidades <Tooltip text="Cantidad mínima." />
                      </label>
                      <div className="maximum-units__input">
                        <label>
                          <input
                            type="number"
                            name="minimumSelection"
                            min="0"
                            placeholder="00"
                            disabled={mostrarSlider}
                            onChange={handlerMinimun}
                            value={form.minimumSelection.value}
                          />
                          unidades
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="maximum-units">
                      <label className="has-tooltip-inline pb-1">
                        Máximo de unidades  <Tooltip text="El máximo de unidades se refiere a la máxima cantidad que el usuario puede añadir en su personalización. Ejemplo: máximo 6 shots de café" />
                      </label>
                      <div className="maximum-units__input">
                        <label>
                          <input
                            name="maximumSelection"
                            type="number"
                            placeholder="00"
                            onChange={handlerMaximun}
                            min={form.minimumSelection.value}
                            value={form.maximumSelection.value}
                            disabled={mostrarSlider}
                          />
                          unidades
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-9 mt-5">
                <div>
                  <label className="has-tooltip-inline">
                    Crear slider
                    <Tooltip text="Este grupo se mostrará en la app como un slider como el de extrashot y solo se puede asociar 3 condimentos." />
                  </label>
                  <input
                    id='barista-checkbox'
                    className='barista-checkbox'
                    type="checkbox"
                    onChange={handleSliderChange} // Añade la función que maneje el cambio del checkbox
                    checked={mostrarSlider}
                  />
                  <span style={{ display: 'inline-block', margin: '5px 0px 0px 5px' }}>Crear un slider para este condimento</span>
                </div>
              </div>
            </div>
            <div className="select-drag__115">
              <h3>Selección condimentos</h3>
              <div className="select-drag__wrap">
                <div className="select-drag__left">
                  <h4>Individuales</h4>
                  <div className='w-100 px-2 mb-2 mt-0'>
                    <div className="ui left icon input search-in-subcategory text-center m-auto w-100 mt-0">
                      <i aria-hidden="true" className="search icon" style={{ top: '0px' }}></i>
                      <input type="text" placeholder="Buscar" style={{ marginTop: '0px' }} onChange={handleSearch} value={keyword} />
                    </div>
                  </div>
                  {/* <div className='d-block'>
                    <Input iconPosition='left' placeholder='Email'>
                      <Icon name='search' />
                      <input />
                    </Input>
                  </div> */}
                  <div className="select-drag__list">
                    {allCondimentsAux?.map((cnd, index) => (
                      <CategorySelectItem
                        key={index}
                        handleActiveCategory={handleActiveCategory}
                        condimentID={cnd.condimentID}
                        microsID={cnd.microsID}
                        symphonyID={cnd.symphonyID}
                        name={cnd.name}
                        description={cnd.description}
                        image={cnd.image}
                        price={cnd.price}
                        status={cnd.status}
                        order={cnd.order}
                        selected={cnd.selected}
                        nameWeb={cnd.nameWeb}
                        SubCondiment={cnd.SubCondiment}
                        listCondimentActivated={listCondimentActivated}
                        isCategoryAdded={isCategoryAdded}
                        setIsCategoryAdded={setIsCategoryAdded}
                      />
                    ))}
                  </div>
                </div>
                <div className="select-drag__right__65">
                  <div className="slider-select-drag__right">
                    {mostrarSlider && (
                      <div className='slider_gradient_2' style={{ marginTop: '20px', width: '100%' }}>
                        <input
                          type="range"
                          min="0"
                          max="2"
                          step="1"
                          className='slider_gradient'
                          value={
                            listCondimentActivated[0] && listCondimentActivated[0].default === true ? '0' :
                              listCondimentActivated[1] && listCondimentActivated[1].default === true ? '1' :
                                listCondimentActivated[2] && listCondimentActivated[2].default === true ? '2' :
                                  '0'
                          }
                        />
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px', }}>
                          <table style={{ width: '100%' }}>
                            <tbody>
                              <tr>
                                <td className={`td-slide ${(listCondimentActivated[0] && listCondimentActivated[0].default === true) && 'slide-item-defaul'}`}>
                                  {listCondimentActivated[0] ? listCondimentActivated[0].name : ''}
                                </td>
                                <td className={`td-slide ${(listCondimentActivated[1] && listCondimentActivated[1].default === true) && 'slide-item-defaul'}`}>
                                  {listCondimentActivated[1] ? listCondimentActivated[1].name : ''}
                                </td>
                                <td className={`td-slide ${(listCondimentActivated[2] && listCondimentActivated[2].default === true) && 'slide-item-defaul'}`}>
                                  {listCondimentActivated[2] ? listCondimentActivated[2].name : ''}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='title-category-active-item'>
                    <span className='ps-3'>Seleccionados</span>
                    <span>Default</span>
                  </div>
                  <div className="select-drag__list-drag">
                    {listCondimentActivated.length > 0 ? (
                      <DragDropContext onDragEnd={handleOnDrag}>
                        <Droppable droppableId="list-categories">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              <CategoryActiveItem
                                status={form.status}
                                setStatus={handlerStatusByListCondiment}
                                listCondimentActivated={listCondimentActivated}
                                setListCondimentActivated={setListCondimentActivated}
                                handleActiveCategory={handleActiveCategory}
                                isCategoryAdded={isCategoryAdded}
                                setIsCategoryAdded={setIsCategoryAdded}
                              />
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <p className="text-center font14">
                        Agrega condimentos individuales a este grupo.
                      </p>
                    )}
                    {mostrarSlider && listCondimentActivated.length === 3 && (
                      <p className="text-limit">
                        <span className="icon-warning"></span>Haz alcanzado el límite de 3 condimentos para un slider de temperatura.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!condimentGroupID ?
              <div className="text-center">
                <Button
                  loading={loading}
                  disabled={!validForm}
                  // disabled={!validForm || listCondimentActivated?.length === 0}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                >
                  Guardar grupo
                </Button>
              </div>
              :
              <div className="flex flex--between">
                <Button
                  type="button"
                  className="cta cta--secondary-plain"
                  onClick={() => {
                    handlerModal(
                      listCondimentActivated?.length > 0 ? 'noDelete' : 'delete'
                    );
                  }}
                >
                  Eliminar grupo
                </Button>
                <Button
                  loading={loading}
                  disabled={!validForm}
                  // disabled={!validForm || listCondimentActivated?.length === 0}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                >
                  Guardar cambios
                </Button>
              </div>
            }
          </Form>
        </div>
      </div>

      <DeleteModal
        show={showModal}
        id={condimentGroupID}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        handleDelete={deleteGroup}
        withContent={listCondimentActivated?.length > 0 ? true : false}
        isLoading={loading}
      />
    </>
  );
};
