import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { startLogout } from '../../actions/auth';

export const TopBar = () => {
  const dispatch = useDispatch();
  const { name } = useSelector((state) => state.auth);
  const userName = name.charAt(0);
  const userData = JSON.parse(localStorage.getItem('user'));
  const userRole = Number(userData.rol_id);

  const handleLogout = () => {
    dispatch(startLogout());
  };
  return (
    <div className="top-bar">
      <div className="container top-bar__wrap">
        <div className="top-bar__logo">
          <img
            src="images/cafe-barista-logo.png"
            width="133"
            height="55"
            alt="Cafe Barista"
          />
        </div>
        <nav id="site-navigation" className="navigation show-for-large text-center">
          <ul>
            {(userRole === 1 || userRole === 2) && (
              <>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      'nav-item nav-link ' + (isActive ? 'active' : '')
                    }
                    to="/productos"
                  >
                    Productos
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      'nav-item nav-link ' + (isActive ? 'active' : '')
                    }
                    to="/categorias"
                  >
                    Categorías
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      'nav-item nav-link ' + (isActive ? 'active' : '')
                    }
                    to="/condimentos"
                  >
                    Condimentos
                  </NavLink>
                </li>
              </>
            )}
            <li>
              <NavLink
                className={({ isActive }) =>
                  'nav-item nav-link' + (isActive ? 'active' : '')
                }
                to="/cobrarQR"
              >
                Cobrar QR
              </NavLink>
            </li>
            {(userRole === 1) && (
              <>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      'nav-item nav-link ' + (isActive ? 'active' : '')
                    }
                    to="/usuarios"
                  >
                    Usuarios
                  </NavLink>
                </li>
              </>
            )}
            {(userRole === 1 || userRole === 2) && (
              <>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      'nav-item nav-link ' + (isActive ? 'active' : '')
                    }
                    to="/ubicaciones"
                  >
                    Ubicaciones
                  </NavLink>
                </li>
              </>
            )}
            <li>
              <Link to="#" className="open-submenu open-submenu--user">
                <div className="profile-avatar profile-avatar--small">
                  <span>{userName}</span>
                </div>
                <span>
                  Hola, <strong>{name}</strong>{' '}
                  <span className="icon-dropdown"></span>
                </span>
              </Link>
              <ul className="submenu-mobile submenu-mobile--user">
                <li>
                  <Link to="/perfil">Mi perfil</Link>
                </li>
                <li>
                  <button onClick={handleLogout} to="/reenviar-contrasena">
                    Cerrar Sesión
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
