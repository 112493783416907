import { toast } from 'react-toastify';
import { revalidateKey } from './key';
import { types } from '../types/types';
import { fetchService } from '../helpers/fetch';
import { finishLoading, startLoading } from './ui';
import { prepareCondimentGroups } from '../helpers/prepareCondimentGroups';


// Get all
export const startGetAllGroupsCondiments = (data, token) => {
  return async (dispatch) => {
    const body = fetchService('/condiments-groups/v1/all', data, token)
      .then((res) => {
        const groups = prepareCondimentGroups(res.responseContent);
        dispatch(getAllGroupsCondiments(groups));
      })
      .catch((err) => {
        //dispatch(revalidateKey(err.info.sessionId));
      });
  };
};

const getAllGroupsCondiments = (group) => {
  console.log(group);
  return {
    type: types.groupCondimentAll,
    payload: group,
  };
};

// Create group
export const startCreateCondimentGroup = (data, token, catGroup) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/condiments-groups/v1/create', data, token)
      .then((res) => {
        dispatch(createCondimentGroup(res));
        dispatch(requestCondimentGroup(res));
        dispatch(finishLoading(true));
        res?.info?.type == 'success' 
        ? toast.success(res?.info?.message || 'Grupo creado con exito!')
        : toast.error(res?.info?.message || 'Error al crear grupo!');
      })
      .catch((err) => {
        dispatch(finishLoading(false));
        toast.error('Error al crear grupo!');
      });
  };
};

export const createCondimentGroup = (group) => {
  return {
    type: types.groupCondimentCreated,
    payload: group,
  };
};

//Get by id
export const initialGetGroupById = (data, token) => {
  return async (dispatch) => {
    const body = fetchService('/condiments-groups/v1/id', data, token)
      .then((res) => {
        // console.log(res.responseContent);
        dispatch(getGroupById(res.responseContent));
      })
      .catch((err) => {
        dispatch(revalidateKey(err.info.sessionId));
      });
  };
};

const getGroupById = (group) => {
  return {
    type: types.groupCategoryById,
    payload: group,
  };
};

// Update group
export const initialUpdateGroup = (data, token, group) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = fetchService('/condiments-groups/v1/update', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(updateGroup(res));
        dispatch(requestCondimentGroup(res));
        res?.info?.type == 'success' 
        ? toast.success(res?.info?.message || 'Cambios guardados exitosamente')
        : toast.error(res?.info?.message || 'No se realizo la actualización!');
      })
      .catch((err) => {
        console.error(err);
        dispatch(finishLoading());
        dispatch(revalidateKey(token)); // err.info.sessionId
        toast.error('No se realizo la actualización');
      });
  };
};

const updateGroup = (group) => {
  return {
    type: types.groupCondimentUpdated,
    payload: group,
  };
};

//Delete group
export const initialDeleteGroup = (data, token, group) => {
  return async (dispatch) => {
    // console.log(data);
    dispatch(startLoading());
    const body = fetchService('/condiments-groups/v1/delete', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(deleteGroup(group));
        dispatch(requestCondimentGroup(res));
        res?.info?.type == 'success' 
        ? toast.success(res?.info?.message || 'Grupo De Condimento Eliminado')
        : toast.error(res?.info?.message || 'No se realizo la actualización!');
      })
      .catch((err) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(err.info.sessionId));
        toast.error('No se pudo eliminar el grupo');
      });
  };
};

const deleteGroup = (group) => {
  return {
    type: types.groupCondimentDeleted,
    payload: group,
  };
};

export const requestCondimentGroup = (group) => {
  return {
    type: types.groupCondimentRequest,
    payload: group,
  };
};