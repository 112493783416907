import moment from "moment";
const Base = process.env.REACT_APP_BASE;

export const singleFetchService = async (endpoint, data, tokenId) => {
  try {
    const resp = await fetch(Base + endpoint, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'authorization': `Bearer ${tokenId}`, 
      },
      body: data,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": 'OPTIONS, PUT, DELETE, GET, POST',
      "Access-Control-Request-Method": '*'
    });
    return await resp.json();
  } catch (err) {
    return err.json();
  }
};
// export const singleFetchService = (endpoint, data, tokenId) => {
//   const url = `${baseUrl}${endpoint}`;
//   return fetch(url, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       'authorization': `Bearer ${tokenId}`,
//     },
//     body: data,
//     "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Headers": "*",
//       "Access-Control-Allow-Methods": 'POST, PUT, DELETE, GET, OPTIONS',
//       "Access-Control-Request-Method": '*'
//   }).then(resp=>resp.json()).catch(err=>err.json());
// };

export const convert = (time) => {
  return moment(time, ['h:mm A']).format('HH:mm');
};