import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

export const CondimentActiveItem = ({ listActivated, setlistActivated }) => {
  //
  const handlerRemoveCategory = (index) => {
    const list = listActivated;
    const remove = list.filter((condiment) => condiment?.condimentID !== index);
    setlistActivated(remove);
  };

  // console.log(listActivated);

  return listActivated.map((condiments, index) => (
    <div className="select-drag__item-drag select-drag__item-drag--none">
      <span className="icon-drag mright5"></span>
      {index + 1}. {condiments?.name}
      <button
        type="button"
        onClick={() => {
          handlerRemoveCategory(condiments?.condimentID);
          //   handlerCategoryAdded();
        }}
      >
        -
      </button>
    </div>
  ));
};
