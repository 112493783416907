import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { TopBar } from '../components/TopBar/TopBar';
import { Loading } from '../components/Loader/Loading';
import { Categories } from '../Pages/PrivatePages/Categories/Categories';
import { NewCategory } from '../Pages/PrivatePages/Categories/NewCategory';
import { EditCategory } from '../Pages/PrivatePages/Categories/EditCategory';
import { Schedules } from '../Pages/PrivatePages/Schedules/Schedules';
import { CreateCategoryGroup } from '../Pages/PrivatePages/CategoryGroup/CreateCategoryGroup';
import { EditGroupCategory } from '../Pages/PrivatePages/CategoryGroup/EditGroupCategory';
import { Condiments } from '../Pages/PrivatePages/Condiments/Condiments';
import { FormCondiment } from '../Pages/PrivatePages/Condiments/FormCondiment';
import { FormCondimentGroup } from '../Pages/PrivatePages/CondimentGroup/FormCondimentGroup';
import { Stores } from '../Pages/PrivatePages/Ubicaciones/Stores';
import { FormStore } from '../Pages/PrivatePages/Ubicaciones/FormStore';
import { FormStoreSchedules } from '../Pages/PrivatePages/Ubicaciones/FormStoreSchedules';
import { FormBranchOffice } from '../Pages/PrivatePages/Ubicaciones/FormBranchOffice';
import { ListUsers } from '../Pages/PrivatePages/Users/ListUser';
import { FormUser } from '../Pages/PrivatePages/Users/FormUser';
import CobrarConQR from '../Pages/PrivatePages/qrpay/cobrarqr';

import { Products } from '../Pages/PrivatePages/Products/Products';
import { EditProduct } from '../Pages/PrivatePages/Products/EditProduct';
import { FormProductsSize } from '../Pages/PrivatePages/Products/FormProductsSize';
import { FormProduct } from '../Pages/PrivatePages/Products/FormProduct';
import { FormVersionGroup } from '../Pages/PrivatePages/Products/FormVersionGroup';
import { FormSubProductGroup } from '../Pages/PrivatePages/Products/FormSubProductGroup';

export const DashboardRoutes = () => {
  const userData = JSON.parse(localStorage.getItem('user'));
  const userRole = Number(userData.rol_id);
  const full_loading = useSelector((state) => state.ui.full_loading);

  return (
    (userRole === 1 || userRole === 2) ? (
      <>
        {full_loading === true && < Loading /> }
        <TopBar />
        <Routes>
          {/* Ruta por default */}
          <Route path="/*" element={<Products />} />
          {/* Usuarios */}
          {(userRole === 1) && (
            <>
              <Route path="/usuarios" element={<ListUsers />} />
              <Route path="/usuarios/form/:id" element={< FormUser />} />
            </>
          )}
          {/* Schedules */}
          <Route path="/horarios" element={<Schedules />} />

          {/* Categories */}
          <Route path="/categorias" element={<Categories />} />
          <Route path="/categorias/crear" element={<NewCategory />} />
          <Route path="/categorias/editar/:id" element={<EditCategory />} />
          
          {/* Products */}
          <Route path="/productos" element={<Products />} />

          <Route path="/productos/editar/:id" element={<EditProduct />} />
          <Route path="/productos/product/form/:id" element={<FormProduct />} />

          <Route path="/productos/product-size/form/:id" element={<FormProductsSize />} />
          <Route path="/productos/group-version/form/:id" element={<FormVersionGroup />} />
          <Route path="/productos/subproduct-group/form/:id" element={<FormSubProductGroup />} />

          {/* <Route path="/productos/combo-meals/form/:id" element={<EditProduct />} /> */}
          {/* <Route path="/productos/group-subproductos/form/:id" element={<EditProduct />} /> */}


          {/* Grupos de categorias */}
          <Route
            path="/categorias/crear-grupo"
            element={<CreateCategoryGroup />}
          />
          <Route
            path="/categorias/editar-grupo/:id"
            element={<EditGroupCategory />}
          />
          {/* Condiments */}
          <Route path="/condimentos" element={<Condiments />} />
          <Route path="/condimentos/form/:id" element={<FormCondiment />} />
          <Route path="/condimentos/group/form/:id" element={<FormCondimentGroup />} />
          {/* cobrar qr */}
          <Route path="/cobrarQR" element={<CobrarConQR />} />
          {/* Ubicaciones */}
          <Route path="/ubicaciones" element={<Stores />} />
          <Route path="/ubicaciones/store/form/:id" element={< FormStore /*Stores FormStore*/ />} />
          <Route path="/ubicaciones/storeType/form/:id" element={< FormBranchOffice /*Stores FormBranchOffice*/ />} />
          <Route path="/ubicaciones/storeSchedules/form" element={< FormStoreSchedules /*Stores FormStoreSchedules*/ />} />
        </Routes>
      </>
    ) : (
      <>
        <TopBar />
        <Routes>
          {/* Ruta por default */}
          <Route path="/*" element={<CobrarConQR />} />
          {/* cobrar qr */}
          <Route path="/cobrarQR" element={<CobrarConQR />} />
        </Routes>
      </>
    )
  );
};
