import { types } from '../types/types';

const initialState = {
  storeRequest: {},
  // distance
  distance: {},
  distanceUpdated: {},
  // store
  storesAll: [],
  storeById: {},
  storeCreated: {},
  storeUpdated: {},
  storeDeleted: {},
  // storeType
  storeTypesAll: [],
  storeTypeById: {},
  storeTypeCreated: {},
  storeTypeUpdated: {},
  storeTypeDeleted: {},
  // storeSchedules
  storeSchedulesAll: [],
  storeSchedulById: {},
  storeSchedulCreated: {},
  storeSchedulUpdated: {},
  storeSchedulDeleted: {},
};

export const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.storeRequest:
      return {
        ...state,
        storeRequest: { ...action.payload }
      };
    case types.distance:
      return {
        ...state,
        distance: { ...action.payload }
      };
    case types.distanceUpdated:
      return {
        ...state,
        distanceUpdated: { ...action.payload }
      };
    case types.storesAll:
      return {
        ...state,
        storesAll: [...action.payload],
      };
    case types.storeById:
      return {
        ...state,
        storeById: { ...action.payload }
      };
    case types.storeCreated:
      return {
        ...state,
        storeCreated: { ...action.payload }
      };
    case types.storeUpdated:
      return {
        ...state,
        storeUpdated: { ...action.payload }
      };
    case types.storeDeleted:
      return {
        ...state,
        storeDeleted: { ...action.payload }
      };
    case types.storeTypesAll:
      return {
        ...state,
        storeTypesAll: [...action.payload],
      };
    case types.storeTypeById:
      return {
        ...state,
        storeTypeById: { ...action.payload }
      };
    case types.storeTypeCreated:
      return {
        ...state,
        storeTypeCreated: { ...action.payload }
      };
    case types.storeTypeUpdated:
      return {
        ...state,
        storeTypeUpdated: { ...action.payload }
      };
    case types.storeTypeDeleted:
      return {
        ...state,
        storeTypeDeleted: { ...action.payload }
      };
      
    case types.storeSchedulesAll:
      return {
        ...state,
        storeSchedulesAll: [...action.payload],
      };
    case types.storeSchedulById:
      return {
        ...state,
        storeSchedulById: { ...action.payload }
      };
    case types.storeSchedulCreated:
      return {
        ...state,
        storeSchedulCreated: { ...action.payload }
      };
    case types.storeSchedulUpdated:
      return {
        ...state,
        storeSchedulUpdated: { ...action.payload }
      };
    case types.storeSchedulDeleted:
      return {
        ...state,
        storeSchedulDeleted: { ...action.payload }
      };
    default:
      return state;
  }
};
