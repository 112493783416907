import { types } from '../types/types';

const initialState = {
  single: {
    name: '',
    nameWeb: '',
    status: 1,
    maximun_selection: 0,
    description: '',
    condiments: [],
  },
  request: {},
  created: {},
  updated: {},
  deleted: {},
  allGroups: null,
};

export const groupCondimentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.groupCondimentAll:
      return {
        ...state,
        allGroups: [...action.payload],
      };
    case types.groupCondimentCreated:
      return {
        ...state,
        created: {
          ...action.payload,
        },
      };
    case types.groupCondimentById:
      return {
        ...state,
        single: {
          ...action.payload,
        },
      };
    case types.groupCondimentUpdated:
      return {
        ...state,
        updated: {
          ...action.payload,
        },
      };
    case types.groupCondimentDeleted:
      return {
        ...state,
        deleted: {
          ...action.payload,
        },
      };
    case types.groupCondimentRequest:
      return {
        ...state,
        request: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
