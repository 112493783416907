export const prepareSchedules = (schedule = []) => {
  return schedule.map((e) => ({
    scheduleID: e?.scheduleID,
    name: e?.name,
    description: e?.description,
    formattedEndHour: e?.formattedEndHour,
    formattedInitHour: e?.formattedInitHour,
    formattedSchedule: e?.formattedSchedule,
    initHour: e?.formattedInitHour.substring(0, 2),
    initMinutes: e?.formattedInitHour.substring(3, 5),
    endHour: e?.formattedEndHour.substring(0, 2),
    endMinutes: e?.formattedEndHour.substring(3, 5),
    meridiemEnd: e?.formattedEndHour.substring(6, 9).toUpperCase(),
    meridiemInit: e?.formattedInitHour.substring(6, 9).toUpperCase(),
  }));
};