import React from 'react';
import { Navigate } from 'react-router-dom';

export const PublicRoutes = ({ children }) => {
  let userData = JSON.parse(localStorage.getItem('user'));
  let user, userId, userRole;

  if (userData) {
    user = userData;
    userId = userData.autorized;
    userRole = Number(userData.rol_id);
    // return (userRole === 1 || userRole === 2) ? <Navigate to="/productos" /> : <Navigate to="/cobrarQR" />;
  } else {
    user = false;
    // return children;
  }
  return user ? <Navigate to="/productos" /> : children;
};

// import React from 'react';
// import { Routes, Navigate } from 'react-router-dom';
// // import { Route } from 'react-router';
// import PropTypes from 'prop-types';

// const PublicRoutes = ({ isAutenticated, component: Component, ...rest }) => {
//   return (
//     <Routes
//       {...rest}
//       component={(props) =>
//         isAutenticated ? <Navigate to="/" /> : <Component {...props} />
//       }
//     />
//   );
// };

// PublicRoutes.propTypes = {
//   isAutenticated: PropTypes.bool.isRequired,
//   component: PropTypes.func.isRequired,
// };

// export default PublicRoutes;
