import moment from "moment";
import { Form } from 'semantic-ui-react';
import { types } from '../../../../types/types';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestDispatch } from "../../../../actions/schedule";


const convertHour = (time, meridiem) => {
  const hour = time.substring(0, 2);
  const minute = time.substring(3, 5)
  const formatTime = `${hour.trim()}:${minute.trim()} ${meridiem}`;
  return moment(formatTime, ["h:mm A"]).format("HH:mm");
}

export const FormCreateSchedule = ({
  loading,
  initialData
}) => {
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialData);
  const [validForm, setValidForm] = useState(false);
  const scheduleRequest = useSelector((state) => state.schedule.scheduleRequest);

  /*** Funciones ***/
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleValidateTime = (e) => {
    e.preventDefault();
    const regex = /^(\d{2}):(\d{2})$/;
    const valregex = regex.test(form[e.target.name].value);
    const parsenumber = Number(form[e.target.name].value.replace(/\D/g, ''));
    const validator = valregex && parsenumber <= 1259 ? false : true;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      formTouched: true,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });
  };

  const handleChangeTime = (e) => {
    e.preventDefault();
    let value = e.target.value.replace(/[^0-9:]/g, '')

    setForm({
      ...form,
      formTouched: true,
      [e.target.name]: {
        value: value.slice(0, 5),
        ...form[e.target.name].error
      }
    });
  };

  const handleChangeTimeMeridiem = (e) => {
    e.preventDefault();

    const meridiem = form[e.target.name] == 'AM' ? 'PM' : 'AM';
    setForm({
      ...form,
      formTouched: true,
      [e.target.name]: meridiem
    });
  };

  const onSubmitCreate = async (e) => {
    e.preventDefault();

    if (validForm) {
      const endTime = await convertHour(form.formattedEndHour.value, form.meridiemEnd);
      const initTime = await convertHour(form.formattedInitHour.value, form.meridiemInit);

      const productSchedule = {
        name: form.name.value,
        description: form.name.value,
        endHour: parseFloat(endTime.substring(0, 2)),
        endMinutes: parseFloat(endTime.substring(3, 5)),
        initHour: parseFloat(initTime.substring(0, 2)),
        initMinutes: parseFloat(initTime.substring(3, 5)),
      };

      const dataProductSchedule = JSON.stringify({
        requestContent: { productSchedule }
      });

      dispatch(requestDispatch('create', 'Created', dataProductSchedule, productSchedule));
    }
  };

  useEffect(() => {
    if (form.name.value.length > 0 && form.formattedEndHour.value.length == 5 && form.formattedInitHour.value.length == 5) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [form]);

  useEffect(() => {
    if (scheduleRequest?.info?.type === 'success') {
      setForm(initialData);
      dispatch({ type: types.scheduleRequest, payload: {} });
    }
  }, [scheduleRequest]);

  return (
    <>
      <Form
        onSubmit={onSubmitCreate}
        className="row p-0 row-group mbottom30"
      >
        <div className="col-6">
          <label className={form.name.error ? 'errorField' : ''}>
            Nombre de horario
            <input
              required
              type="text"
              name="name"
              minLength="1"
              className="input-text"
              value={form.name.value}
              onBlur={handleValidate}
              onChange={handleChange}
              placeholder="Nombra este horario"
            />
          </label>
        </div>
        <div className="col-3">
          <label className={form.formattedInitHour.error ? 'errorField' : ''}>
            Hora inicio
            <div className="input-time">
              <input
                type="text"
                id="formattedInitHour"
                name="formattedInitHour"
                placeholder="HH:MM"
                data-valid-example="HH:MM"
                onChange={handleChangeTime}
                onBlur={handleValidateTime}
                value={form.formattedInitHour.value}
                required
              />
              <button
                type="button"
                name='meridiemInit'
                className="input-time__button"
                onClick={handleChangeTimeMeridiem}
              >
                {form.meridiemInit}
              </button>
            </div>
            {form.formattedInitHour.error &&
              <span className="error-input-text-tag">
                Hora invalido (HH:MM)
              </span>
            }
          </label>
        </div>
        <div className="col-3 relative">
          <label className={form.formattedEndHour.error ? 'errorField' : ''}>
            Hora fin
            <div className="input-time">
              <input
                type="text"
                id="formattedEndHour"
                name="formattedEndHour"
                placeholder="HH:MM"
                data-valid-example="HH:MM"
                onChange={handleChangeTime}
                onBlur={handleValidateTime}
                value={form.formattedEndHour.value}
                required
              />
              <button
                type="button"
                name='meridiemEnd'
                className="input-time__button"
                onClick={handleChangeTimeMeridiem}
              >
                {form.meridiemEnd}
              </button>
            </div>
            {form.formattedEndHour.error &&
              <span className="error-input-text-tag">
                Hora invalido (HH:MM)
              </span>
            }
          </label>
        </div>
        <div className="col-12">
          {validForm ? (
            <button
              type="submit"
              className={'cta cta--tiny mtop30 cta--secondary-plain'}
            >
              Guardar horario
            </button>
          ) : (
            <span
              type="submit"
              className={'cta cta--tiny mtop30 cta-secondary-plain-disabled'}
            >
              Guardar horario
            </span>
          )}
          {/* <button
            type="submit"
            className='cta cta--tiny mtop30 cta--secondary-plain'
            className={`cta cta--tiny mtop30  ${validForm ? 'cta--secondary-plain' : 'cta-secondary-plain-disabled'}`}
          >
            Guardar horario
          </button> */}
        </div>
      </Form>
    </>
  );
};
