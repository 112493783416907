import { types } from '../types/types';

const initialState = {
  scheduleAll: [],
  scheduleById: {},
  scheduleCreated: {},
  scheduleUpdated: {},
  scheduleDeleted: {},
  scheduleRequest: {},
};

export const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.scheduleAll:
      return {
        ...state,
        scheduleAll: [...action.payload],
      };
    case types.scheduleById:
      return {
        ...state,
        scheduleById: { ...action.payload }
      };
    case types.scheduleCreated:
      return {
        ...state,
        scheduleCreated: { ...action.payload }
      };
    case types.scheduleUpdated:
      return {
        ...state,
        scheduleUpdated: { ...action.payload }
      };
    case types.scheduleDeleted:
      return {
        ...state,
        scheduleDeleted: { ...action.payload }
      };
    case types.scheduleRequest:
      return {
        ...state,
        scheduleRequest: { ...action.payload }
      };

    // case types.scheduleAll:
    //   return {
    //     // ...state,
    //     ...action.payload,
    //   };
    // case types.scheduleCreate:
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // case types.scheduleUpdate:
    //   return {
    //     ...state,
    //   };
    // case types.scheduleDelete:
    //   return {
    //     schedule: true,
    //   };
    default:
      return state;
  }
};
