import { types } from '../types/types';

const initialState = {
  creation: {
    name: '',
    description: '',
    image: '',
    status: 1,
    schedule: 1,
    success: false,
  },
  list: null,

  categoryUpdated: {},
  categoryRequest: {}
};

export const categoryReducer = (state = initialState, action) => {
  // console.log(action);

  switch (action.type) {
    case types.categoryAll:
      return {
        ...state,
        list: [...action.payload],
      };

    case types.categoryCreate:
      return {
        ...state,
        creation: {
          ...action.payload,
        },
      };

    case types.categoryCreateSuccess:
      return {
        ...state,
        creation: {
          ...action.payload,
        },
      };

    case types.categoryCreateFailed:
      return {
        ...state,
        creation: {
          ...action.payload,
        },
      };

    case types.categoryById:
      return {
        ...state,
        creation: {
          ...action.payload,
        },
      };
    case types.categoryDelete:
      return {
        ...state,
        creation: {
          ...action.payload,
        },
      };
    case types.categoryRequest:
      return {
        ...state,
        categoryRequest: { ...action.payload }
      };
    case types.categoryUpdated:
      return {
        ...state,
        categoryUpdated: { ...action.payload }
      };
    default:
      return state;
  }
};
