// Return categoies format

export const prepareCategories = (categories = []) => {
  return categories.map((e) => ({
    categoryID: e.categoryID,
    name: e.name,
    description: e.description,
    image: e.image,
    status: e.status,
    schedule: e.schedule,
    productsCount: e.productsCount,
    order: e.order,
  }));
};
