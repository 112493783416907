import { toast } from 'react-toastify';
import { fetchSinTocken, fetchWithToken } from '../helpers/fetch';
import { types } from '../types/types';
import { getAllCategories } from './category';
import { startGetAllGroups } from './groups';
import { revalidateKey } from './key';
import { schedulesGetAll } from './schedule';
import { finishLoading, startLoading } from './ui';

export const startLogin = (data) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = fetchSinTocken('sessions', data, 'POST')
      .then((res) => {
        if(res.info.type != 'success') {
          throw res;
        }
        
        localStorage.setItem('user', JSON.stringify(res.responseContent));

        dispatch(revalidateKey(res.info.sessionId));
        dispatch(finishLoading());
        dispatch(
          login({
            uid: res.responseContent.id,
            email: res.responseContent.email,
            name: res.responseContent.name,
            lastName: res.responseContent.surname,
            rol_id: res.responseContent.rol_id,
            status: res.responseContent.status,
            autorized: res.responseContent.autorized,
          })
        );

        // get all schedules
        let data = JSON.stringify({});
        dispatch(schedulesGetAll('all', data, res.info.sessionId));
        dispatch(startGetAllGroups(data, res.info.sessionId));
        dispatch(getAllCategories(data, res.info.sessionId));
        // res.info.sessionId
      })
      .catch((err) => {
        dispatch(finishLoading());
        toast.error(err.info.message || 'Consulte al administrador.');
        localStorage.removeItem('user');
        document.querySelector('#DisableButton').classList.remove('disabled');
      });
  };
};

const login = (user) => ({
  type: types.authLogin,
  payload: user,
});

// Revalidar sesion

export const startChecking = () => {
  return (dispatch) => {
    let isUserId = JSON.parse(localStorage.getItem('user'));
    let userId, user;

    if (isUserId) {
      userId = isUserId;
      user = userId;
    } else {
      user = false;
    }

    if (user) {
      dispatch(
        login({
          uid: user.id,
          email: user.email,
          name: user.name,
          lastName: user.surname,
          rol_id: user.rol_id,
          status: user.status,
          autorized: user.autorized,
        })
      );
    } else {
      dispatch(checkingFinish());
    }
  };
};

const checkingFinish = () => ({ type: types.authCheckingFinish });

// Logout

export const startLogout = () => {
  return (dispatch) => {
    localStorage.clear();
    dispatch(logout(), finishLoading());
  };
};

const logout = () => ({ type: types.authLogout });
