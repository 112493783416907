import React from 'react';
import ReactDOM from 'react-dom';
import { AdminBaristaApp } from './AdminBaristaApp';

import 'semantic-ui-css/semantic.min.css';
import './scss/styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';

ReactDOM.render(<AdminBaristaApp />, document.getElementById('root'));
