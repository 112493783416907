import { types } from "../types/types"

export const startLoading = () => ({
    type: types.uiStartLoading
});

export const finishLoading = () => ({
    type: types.uiFinishLoading
});

export const full_loading = (state) => ({
    type: types.full_loading,
    payload: state
});