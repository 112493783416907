import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Icon } from 'semantic-ui-react';
import { FormEditStoreSchedule } from './components/FormEditStoreSchedule';
import { BackRelative } from '../../../components/Back/BackRelative';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import { FormCreateStoreSchedule } from './components/FormCreateStoreSchedule';
import { schedulesGetAll } from '../../../actions/schedule';
// import { allSchedules, updateGroupSchedules, updateSchedules } from '../../../actions/schedule';

import { types } from '../../../types/types';
import { storeSchedulesGetAll } from '../../../actions/store';


const initialStateNewSchedule = {
  id: -1,
  name: '',
  description: '',
  from: '',
  to: '',
};

export const FormStoreSchedules = () => {
  /***  Selector  ***/
  const dispatch = useDispatch();
  const { key } = useSelector((state) => state.key);
  const storeSchedulesList = useSelector((state) => state.store.storeSchedulesAll);

   /***  Variables  ***/
  const [storeSchedules, setStoreSchedules] = useState(storeSchedulesList);

  /***  useEffects ***/
  useEffect(() => {
    setStoreSchedules(storeSchedulesList);
  }, [storeSchedulesList]);

  useEffect(() => {
    dispatch(storeSchedulesGetAll('{}', key));
    dispatch({ type: types.storeRequest, payload: {} });
  }, [dispatch]);

  /********************** OLD **********************/

  let { schedules } = useSelector((state) => state.schedule);
  const { loading } = useSelector((state) => state.ui);

  useEffect(() => {
    const raw = JSON.stringify({
      info: {
        sessionId: 'tokenid',
      },
      requestContent: {},
    });
    // console.log('login horarios', body.info.sessionId);
    dispatch(schedulesGetAll('all', raw));
  }, [dispatch]);

  // const timeMask = '00:00';
  // const timeMask = function (value) {
  //   var pattern = new RegExp(/^(1[0-2]|0?[1-9]):([0-5]?[0-9])$/i);
  //   // console.log(value, pattern.test(value));
  //   return pattern.test(value);
  // };

  //   Modal states
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [resumeModal, setResumeModal] = useState('');
  const [idSchedule, setIdSchedule] = useState(false);

  const handlerModal = (type, index) => {
    switch (type) {
      case 'delete':
        setTitleModal('¿Estás seguro que deseas eliminar este horario?');
        setResumeModal('No podrás revertir esta acción.');
        setShowModal(true);
        setIdSchedule(index);
        break;
      default:
        setTitleModal(null);
        setResumeModal(null);
        setShowModal(false);
        break;
    }
  };

  /*----------------------------------------------------------------
    $ NEW SCHEDULe
  ----------------------------------------------------------------*/

  const [newSchedule, setNewSchedule] = useState(initialStateNewSchedule);

  const handleNewSchedule = (e) => {
    setNewSchedule({
      ...newSchedule,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateNewSchedule = (schedule) => {
    // e.preventDefault();
    // console.log(schedule);
    // schedules.push(schedule);
    // dispatch(updateGroupSchedules(schedules));
    // setNewSchedule(initialStateNewSchedule);
  };

  /*----------------------------------------------------------------
    $ UPDATE SCHEDULES
  ----------------------------------------------------------------*/

  const [updateSchedule, setUpdateSchedule] = useState([
    {
      id: -1,
      name: '',
      description: '',
      fromIs: '',
      toIs: '',
      from: '',
      to: '',
    },
  ]);

  const handlesetUpdateSchedule = (e, index) => {
    // let updateSchedules = schedules;
    // // debugger;
    // const { name, value } = e.target;
    // updateSchedules[index][name] = value;
    // setUpdateSchedule([...updateSchedules]);
  };

  const handleDeleteSchedule = (index) => {
    // let updateSchedules = schedules;
    // updateSchedules.splice(index, 1);
    // // debugger;
    // setUpdateSchedule([...updateSchedules]);
    // setShowModal(false);
  };

  /*----------------------------------------------------------------
    $ SAVE SCHEDULES
  ----------------------------------------------------------------*/

  const onSubmit = (e) => {
    // e.preventDefault();
    // // Dispatch information
    // const structure = JSON.stringify({
    //   requestContent: {
    //     schedules: schedules,
    //   },
    // });
    // dispatch(updateSchedules(structure, key, schedules));
  };

  return (
    <>
      <div className="container">
        <BackRelative url="/ubicaciones" />
        <h1 className="text-center">Horarios</h1>
        <div className="schedules">
          <h3>Crear nuevo horario</h3>
          <div className="schedules__first">
            <div className="m-0">
              <FormCreateStoreSchedule
                handleCreateNewSchedule={handleCreateNewSchedule}
                handleNewSchedule={handleNewSchedule}
              />
              <div className="row schedules__list">
                <div className="col-12">
                  <h3>Horarios creados</h3>
                </div>
                {storeSchedules?.length <= 0 ? (
                  <div className="col-12 ptop15">
                    <p>Cuando tengas horarios creados, podrás verlos y editarlos aquí.</p>
                  </div>
                ) : (
                  <Form className="col-12 schedules__exists ptop15" onSubmit={onSubmit}>
                    {storeSchedules?.map((item, index) => (
                      <>
                        <FormEditStoreSchedule
                          id={item.id}
                          key={item.id}
                          index={index}
                          name={item.name}
                          fromInit={item.fromIs}
                          toInit={item.toIs}
                          from={item.from}
                          to={item.to}
                          handlerModal={handlerModal}
                          handlesetUpdateSchedule={handlesetUpdateSchedule}
                        />
                      </>
                    ))}
                    {/* <div className="col-12 pbottom50">
                      <Button
                        loading={loading}
                        className="cta cta--ui-mAuto"
                      >
                        Guardar horarios
                      </Button>
                    </div> */}
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={showModal}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        id={idSchedule}
        handleDelete={handleDeleteSchedule}
      />
    </>
  );
};
