import { toast } from 'react-toastify';
import { fetchSinTocken } from '../helpers/fetch';
import { types } from '../types/types';
import { finishLoading, startLoading } from './ui';

export const recoverPassword = (data, email) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = await fetchSinTocken('resendemail', data, 'POST');
    console.log(body);

    if (body.info.type === 'success') {
      localStorage.setItem('email', email);
      setTimeout(() => {
        window.location.href = '/reenviar-contrasena';
      }, 2000);
      dispatch(
        finishLoading(),
        password({
          email,
        })
      );
    } else {
      dispatch(finishLoading());
      toast.error('Correo no válido');
      document.querySelector('#DisableButton').classList.remove('disabled');
    }
  };
};

export const firstPassword = (data, email) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = await fetchSinTocken('resetpassword', data, 'POST');
    // const body = await resp.json();

    if (body.info.type === 'success') {
      toast.success('Contraseña actualizada correctamente.');
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
      dispatch(
        finishLoading(),
        password({
          email,
        })
      );
    } else {
      dispatch(finishLoading());
      toast.error(body.info.type);
      document.querySelector('#DisableButton').classList.remove('disabled');
    }
  };
};

export const resendPassword = (data, email) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = await fetchSinTocken('resendemail', data, 'POST');
    // const body = await resp.json();

    if (body.info.type === 'success') {
      toast.success('Se envio nuevamente el correo a tu bandeja.');
      dispatch(
        finishLoading(),
        password({
          email,
        })
      );
    } else {
      dispatch(finishLoading());
      toast.error('Correo no válido');
      document.querySelector('#DisableButton').classList.remove('disabled');
    }
  };
};

export const renewPassword = (data, email) => {
  return async (dispatch) => {
    dispatch(startLoading());
    const body = await fetchSinTocken('resetpassword', data, 'POST');
    // const body = await resp.json();

    if (body.info.type === 'success') {
      toast.success('La contraseña se actualizo correctamente.');
      localStorage.removeItem('email');
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
      dispatch(
        finishLoading(),
        password({
          email,
        })
      );
    } else {
      dispatch(finishLoading());
      toast.error(body.info.type);
      document.querySelector('#DisableButton').classList.remove('disabled');
    }
  };
};

const password = (pass) => {
  return {
    type: types.passForgot,
    payload: pass,
  };
};
