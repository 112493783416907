import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { singleFetchService } from '../../../helpers/functions';
import { BackRelative } from '../../../components/Back/BackRelative';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import { Button, Form, Icon, Input, Radio, Dropdown } from 'semantic-ui-react';
import { storeTypeGetAll, storeSchedulesGetAll, createStore, updateStore, deleteStore } from '../../../actions/store';

const initialData = {
  storeCode: {
    value: '',
    error: false
  },
  storeName: {
    value: '',
    error: false
  },
  description: {
    value: '',
    error: false
  },
  storeType: 0,
  latitude: {
    value: '',
    error: false
  },
  longitude: {
    value: '',
    error: false
  },
  address: {
    value: '',
    error: false
  },
  phone1: {
    value: '',
    error: false
  },
  phone2: {
    value: '',
    error: false
  },
  manager: {
    value: '',
    error: false
  },
  status: 'A',
  delivery: 'A',
  storeIP: {
    value: '',
    error: false
  },
  schedules: []
}


export const FormStore = () => {
  /***  Selector  ***/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let key = useSelector((state) => state.key.key);
  const { loading } = useSelector((state) => state.ui);
  const storeRequest = useSelector((state) => state.store.storeRequest);
  const storeTypeList = useSelector((state) => state.store.storeTypesAll);
  const storeSchedulesList = useSelector((state) => state.store.storeSchedulesAll);
  const [storesType, setStoresType] = useState(storeTypeList);
  const [storeSchedules, setStoreSchedules] = useState(storeSchedulesList);

  /***  Variables ***/
  // Formulario
  const [form, setForm] = useState(initialData);
  const [validForm, setValidForm] = useState(false);
  const [schedulesQuantity, setSchedulesQuantity] = useState([{}]);
  const [storeSchedulesError, setStoreSchedulesError] = useState(false);

  // Obtener parametro GET (condimentGroup)
  const location = useLocation();
  const storeID = (location.pathname.match(/\d+/) || [])[0] || undefined;

  // Modal states
  const [showModal, setShowModal] = useState(false);
  const resumeModal = 'No podrás revertir esta acción.';
  const titleModal = '¿Estás seguro que deseas eliminar esta ubicación?';

  // Funciones de Formulario
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });
  };

  const handleStatusChange = (name) => {
    // status, delivery
    setForm({
      ...form,
      [name]: form[name] === 'A' ? 'I' : 'A'
    });
  };

  const handleStoreTypeChange = (e) => {
    setForm({
      ...form,
      storeType: Number(e.target.value)
    });
  };

  const handleAddSelectSchedules = (e) => {
    e.preventDefault();
    if (schedulesQuantity.length < 3) setSchedulesQuantity([...schedulesQuantity, {}]);
  };

  const handleLessSelectSchedules = (e, index) => {
    e.preventDefault();

    // Eliminar una posicion de la iteracion
    const schedulesQuantityAux = [...schedulesQuantity];
    schedulesQuantityAux.splice(schedulesQuantityAux.length - 1, 1);
    setSchedulesQuantity(schedulesQuantityAux);

    // Eliminar un horario 
    const storeSchedulesAux = [...form.schedules];
    storeSchedulesAux.splice(index, 1);

    setForm({
      ...form,
      schedules: storeSchedulesAux
    });
  };

  const handleStoreSchedulesChange = async (e, index, scheduleID) => {
    e.preventDefault();

    const findStoreSchedules = await storeSchedules.find((x) => x.scheduleID === scheduleID);
    const schedules = [...form.schedules];
    schedules[index] = findStoreSchedules;

    setForm({
      ...form,
      schedules: schedules
    });

    handleStoreSchedulesError(scheduleID);
  };

  const handleStoreSchedulesValid = (scheduleID) => {
    const valid = form.schedules.find((x) => x?.scheduleID === scheduleID);

    if (valid) {
      return true;
    } else {
      return false;
    }
  }

  const handleStoreSchedulesError = async (scheduleID) => {
    /* 
    Pendiente de validar por falta de datos
    let error = false;
    for (let i = 0; i <= 3; i++) {
      if (form.schedules[i]) {
        form.schedules[i].dayOfTheWeek.forEach((x) => {
          // valid = form.schedules[i].dayOfTheWeek.include(x);
          // valid = form.schedules[i].dayOfTheWeek.include(x);
        });
      }
    }
    */
    const valid = await form.schedules.filter((x) => x?.scheduleID === scheduleID);
    if (valid.length >= 1) {
      setStoreSchedulesError(true);
    } else {
      setStoreSchedulesError(false);
    }
  }

  // Obtener un tipo de tienda
  const getOneStore = () => {
    const data = JSON.stringify({
      requestContent: {
        store: { storeID: +storeID }
      }
    });

    const body = singleFetchService('/stores/v1/id', data, key)
      .then((response) => {
        const res = response.responseContent;
        console.log('res: ', res);
        setForm({
          storeCode: { value: res?.storeCode || '', error: false },
          storeName: { value: res?.storeName || '', error: false },
          description: { value: res?.description || '', error: false },
          latitude: { value: res?.latitude || '', error: false },
          longitude: { value: res?.longitude || '', error: false },
          address: { value: res?.address || '', error: false },
          phone1: { value: res?.phone1 || '', error: false },
          phone2: { value: res?.phone2 || '', error: false },
          manager: { value: res?.manager || '', error: false },
          status: res?.status,
          delivery: res?.delivery || 'I',
          storeIP: res?.storeIP,
          storeType: res?.storeType,
          schedules: res?.schedules || []
        });

        let schedules = [];
        (res?.schedules[0]) && (schedules.push({}));
        (res?.schedules[1]) && (schedules.push({}));
        (res?.schedules[2]) && (schedules.push({}));
        setSchedulesQuantity(schedules)
      })
      .catch((err) => console.log(err));
    return body;
  };

  // Guardar tipo de tienda
  const handleSubmit = (e) => {
    e.preventDefault();
    const storeData = {
      requestContent: {
        store: {
          type: !storeID ? 'create' : 'update',
          storeID: Number(storeID) || undefined,
          storeCode: form.storeCode.value,
          storeName: form.storeName.value,
          description: form.description.value,
          latitude: parseFloat(form.latitude.value),
          longitude: parseFloat(form.longitude.value),
          address: form.address.value,
          phone1: form.phone1.value,
          phone2: form.phone2.value,
          manager: form.manager.value,
          status: form.status,
          delivery: form.delivery,
          storeIP: form.storeIP.value,
          storeType: form.storeType,
          schedules: form.schedules || []
        }
      }
    };

    if (!storeID) {
      delete storeData.requestContent.store.storeID;
      dispatch(createStore(JSON.stringify(storeData), key, storeData));
    } else {
      dispatch(updateStore(JSON.stringify(storeData), key, storeData));
    }
    // }
  };

  // Eliminar grupo
  const deleteGroup = (id) => {
    const store = {
      storeID: +storeID,
      status: form.status,
      storeName: form.storeName.value,
      storeCode: form.storeCode.value
    }

    const data = JSON.stringify({
      requestContent: { store: store }
    });

    dispatch(deleteStore(data, key, store));
  };

  /***  useEffects ***/
  useEffect(async () => {
    if (storeID != undefined) getOneStore();
  }, [storeID]);

  useEffect(() => {
    dispatch(storeTypeGetAll('{}', key));
    dispatch(storeSchedulesGetAll('{}', key));
  }, [dispatch]);

  useEffect(() => {
    setStoresType(storeTypeList);
    setStoreSchedules(storeSchedulesList);
  }, [storeTypeList, storeSchedulesList]);

  useEffect(() => {
    if (
      form.storeCode.value.length <= 1 ||
      form.storeName.value.length <= 1 ||
      form.longitude.value.length <= 1 ||
      form.address.value.length <= 1 ||
      form.phone1.value.length <= 1 ||
      form.manager.value.length <= 1 ||
      form.storeType == 0 ||
      form.schedules.length === 0
    ) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [form]);

  // Re-direccionar
  useEffect(() => {
    if (storeRequest?.info?.type === 'success') navigate('/ubicaciones');
  }, [storeRequest]);

  return (
    <>
      <div className="container">
        <BackRelative url="/ubicaciones" />
        <div className="large-wrap">
          <h1 className="text-center m-0 pbottom30">
            {!storeID ? 'Crear ubicación' : 'Editar ubicación'}
          </h1>
          <Form onSubmit={handleSubmit} className="general-form row">
            <div className="mbottom30 col-6">
              <label className={form.storeName.error ? 'errorField' : ''}>
                Nombre de sucursal
                <Input
                  type="text"
                  name="storeName"
                  minLength="1"
                  required={true}
                  onBlur={handleValidate}
                  onChange={handleChange}
                  value={form.storeName.value}
                  placeholder="Nombra de esta sucursal"
                />
              </label>
            </div>
            <div className="mbottom30 col-6">
              <label className={form.storeCode.error ? 'errorField' : ''}>
                Código de sucursal
                <Input
                  type="text"
                  name="storeCode"
                  minLength="1"
                  required={true}
                  onBlur={handleValidate}
                  onChange={handleChange}
                  value={form.storeCode.value}
                  placeholder="Ingresa el código"
                />
              </label>
            </div>
            <div className="mbottom30 col-6">
              <label className={form.manager.error ? 'errorField' : ''}>
                Gerente de tienda
                <Input
                  type="text"
                  name="manager"
                  minLength="1"
                  required={true}
                  onBlur={handleValidate}
                  onChange={handleChange}
                  value={form.manager.value}
                  placeholder="Ingresa su nombre"
                />
              </label>
            </div>
            <div className="mbottom30 col-6">
              <div className="row">
                <div className="col-6">
                  <label className={form.phone1.error ? 'errorField' : ''}>
                    Teléfono 1
                    <Input
                      type="text"
                      name="phone1"
                      minLength="1"
                      required={true}
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.phone1.value}
                      placeholder="Teléfono 1"
                    />
                  </label>
                </div>
                <div className="col-6">
                  <label className={form.phone2.error ? 'errorField' : ''}>
                    Teléfono 2 (Opcional)
                    <Input
                      type="text"
                      name="phone2"
                      required={false}
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.phone2.value}
                      placeholder="Teléfono 2"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="mbottom30 col-12">
              <label className={form.address.error ? 'errorField' : ''}>
                Dirección
                <textarea
                  rows="20"
                  name="address"
                  placeholder="Escribe la dirección exacta de la sucursal"
                  minLength="1"
                  required={true}
                  onBlur={handleValidate}
                  onChange={handleChange}
                  value={form.address.value}
                ></textarea>
              </label>
            </div>
            <div className="mbottom30 col-6">
              <label className={form.latitude.error ? 'errorField' : ''}>
                Altitud (en decimales)
                <Input
                  type="text"
                  minLength="1"
                  name="latitude"
                  required={true}
                  onBlur={handleValidate}
                  onChange={handleChange}
                  value={form.latitude.value}
                  placeholder="00.0000"
                />
              </label>
            </div>
            <div className="mbottom30 col-6">
              <label className={form.longitude.error ? 'errorField' : ''}>
                Longitud (en decimales)
                <Input
                  type="text"
                  minLength="1"
                  name="longitude"
                  required={true}
                  onBlur={handleValidate}
                  onChange={handleChange}
                  value={form.longitude.value}
                  placeholder="00.0000"
                />
              </label>
            </div>
            <div className="mbottom30 col-6">
              <div className="radio-group">
                <label className="dblock pbottom15">Tipo de sucursal</label>
                <ul className="margin0 padding0 list-column">
                  {storesType.map((type, index) => (
                    // <li key={type.typeID}>
                    <li key={index}>
                      <div className="radio relative">
                        <input
                          type="radio"
                          name="status"
                          id={type.typeID}
                          value={type.typeID}
                          onChange={handleStoreTypeChange}
                          checked={Number(form.storeType) === Number(type.typeID)}
                        />
                        <label htmlFor={type.typeID} className="radio-label"></label>
                        <label htmlFor={type.typeID}>{type.typeName}</label>
                        {/* <label htmlFor={`storeType-${index}`} className="radio-label"></label> */}
                        {/* <label htmlFor={`storeType-${index}`}>{type.typeName}</label> */}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="mbottom30 col-auto">
              <label className="dblock pbottom15">Estatus</label>
              <Radio toggle value={form?.status} checked={form?.status === 'A'} onChange={(e) => handleStatusChange('status')} />
            </div>
            <div className="mbottom30 col-auto">
              <label className="dblock pbottom15">Tiene delivery</label>
              <Radio toggle value={form?.delivery} checked={form?.delivery === 'A'} onChange={(e) => handleStatusChange('delivery')} />
            </div>

            {storeSchedulesError &&
              // {handleStoreSchedulesError() &&
              <div className="col-12 error-box">
                <p className='error-box-title'>
                  <Icon style={{ color: 'red' }} name="warning circle"></Icon>
                  Hay un error con los horarios.
                </p>
                <p className='error-box-text'>
                  Seleccionaste uno o más horarios que comparten un día de la semana.
                  Debes seleccionar horarios que no compartan días de la semana entre
                  sí o crear un nuevo horario.
                </p>
              </div>
            }

            <div className="col-6 mt-4">
              {schedulesQuantity.map((test, index) => (
                <div className="mbottom30">
                  <label>
                    Selecciona un horario {test.length}
                    {/* length {Boolean(form?.schedules?.scheduleID)} |  */}
                    {/* index: {index} | scheduleID: {form?.schedules[index]?.scheduleID} */}
                    <Dropdown
                      fluid
                      selection
                      className='mt-2'
                      value={form?.schedules[index]?.scheduleID}
                      options={
                        storeSchedules?.map(
                          (storeSchedules, indexTwo) => {
                            return ({
                              key: indexTwo,
                              text: storeSchedules.name,
                              value: storeSchedules.scheduleID,
                              disabled: handleStoreSchedulesValid(storeSchedules.scheduleID)
                              // disabled: storeSchedules.scheduleID === 5
                            })
                          })
                      }
                      placeholder="Seleccionar..."
                      onChange={(e, { value }) => handleStoreSchedulesChange(e, index, value)}
                    />
                  </label>

                  {schedulesQuantity.length > 1 &&
                    <button
                      className='mt-2 btn-delete'
                      onClick={(e) => handleLessSelectSchedules(e, index)}
                    >
                      <Icon name="trash alternate outline"></Icon> Eliminar
                    </button>
                  }
                </div>
              )
              )}
              {schedulesQuantity.length < 3 ?
                <div className="text-right">
                  <button className='btn-add' onClick={handleAddSelectSchedules}>
                    + Agregar otro horario
                  </button>
                </div>
                :
                <div>
                  <p className="text-limit">
                    {/* <span className="icon-warning"></span>Has alcanzado el máximo de 3 horarios por ubicación. */}
                    <Icon style={{ color: 'red' }} name="warning circle"></Icon>
                    Has alcanzado el máximo de 3 horarios por ubicación.
                  </p>
                </div>
              }
            </div>
            {!storeID ?
              <div className="mt-5 text-center">
                <Button
                  loading={loading}
                  disabled={!validForm}
                  className={`cta cta--secondary ${!validForm ? 'disabled' : ''}`}
                >
                  Guardar ubicación
                </Button>
              </div>
              :
              <div className="mt-5  flex flex--center">
                <Button
                  type="button"
                  className="cta cta--secondary-plain mx-3"
                  onClick={() => setShowModal(true)}
                >
                  Eliminar ubicación
                </Button>
                <Button
                  loading={loading}
                  disabled={!validForm}
                  className={`cta cta--secondary ${!validForm ? 'disabled' : ''}`}
                >
                  Guardar cambios
                </Button>
              </div>
            }
          </Form>
        </div>
      </div>
      <DeleteModal
        show={showModal}
        id={storeID}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        handleDelete={deleteGroup}
        withContent={false}
        isLoading={loading}
      />
    </>
  );
};
