import { types } from '../types/types';

const initialState = {
  // Products
  productsAll: [],
  productByCategories: [],
  productById: {},
  productCreated: {},
  productUpdated: {},
  productDeleted: {},
  productRequest: {},
  // ProductSizes
  productSizesAll: [],
  productSizeById: {},
  productSizeCreated: {},
  productSizeUpdated: {},
  productSizeDeleted: {},
  productSizeRequest: {},
  // SubProductGroup
  subProductGroupsAll: [],
  subProductGroupById: {},
  subProductGroupCreated: {},
  subProductGroupUpdated: {},
  subProductGroupDeleted: {},
  subProductGroupRequest: {},
  // VersionGroup (Products)
  versionGroupsAll: [],
  versionGroupById: {},
  versionGroupCreated: {},
  versionGroupUpdated: {},
  versionGroupDeleted: {},
  versionGroupRequest: {}
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    // Products
    case types.productsAll:
      return {
        ...state,
        productsAll: [...action.payload],
      };
    case types.productByCategories:
      return {
        ...state,
        productByCategories: [...action.payload],
      };
    case types.productById:
      return {
        ...state,
        productById: { ...action.payload }
      };
    case types.productCreated:
      return {
        ...state,
        productCreated: { ...action.payload }
      };
    case types.productUpdated:
      return {
        ...state,
        productUpdated: { ...action.payload }
      };
    case types.productDeleted:
      return {
        ...state,
        productDeleted: { ...action.payload }
      };
    case types.productRequest:
      return {
        ...state,
        productRequest: { ...action.payload }
      };
    // ProductSizes
    case types.productSizesAll:
      return {
        ...state,
        productSizesAll: [ ...action.payload ],
      };
    case types.productSizeById:
      return {
        ...state,
        productSizeById: { ...action.payload }
      };
    case types.productSizeCreated:
      return {
        ...state,
        productSizeCreated: { ...action.payload }
      };
    case types.productSizeUpdated:
      return {
        ...state,
        productSizeUpdated: { ...action.payload }
      };
    case types.productSizeDeleted:
      return {
        ...state,
        productSizeDeleted: { ...action.payload }
      };
    case types.productSizeRequest:
      return {
        ...state,
        productSizeRequest: { ...action.payload }
      };
    // SubProductGroup
    case types.subProductGroupsAll:
      return {
        ...state,
        subProductGroupsAll: [...action.payload],
      };
    case types.subProductGroupById:
      return {
        ...state,
        subProductGroupById: { ...action.payload }
      };
    case types.subProductGroupCreated:
      return {
        ...state,
        subProductGroupCreated: { ...action.payload }
      };
    case types.subProductGroupUpdated:
      return {
        ...state,
        subProductGroupUpdated: { ...action.payload }
      };
    case types.subProductGroupDeleted:
      return {
        ...state,
        subProductGroupDeleted: { ...action.payload }
      };
    case types.subProductGroupRequest:
      return {
        ...state,
        subProductGroupRequest: { ...action.payload }
      };
    // VersionGroup (Products)
    case types.versionGroupsAll:
      return {
        ...state,
        versionGroupsAll: [...action.payload],
      };
    case types.versionGroupById:
      return {
        ...state,
        versionGroupById: { ...action.payload }
      };
    case types.versionGroupCreated:
      return {
        ...state,
        versionGroupCreated: { ...action.payload }
      };
    case types.versionGroupUpdated:
      return {
        ...state,
        versionGroupUpdated: { ...action.payload }
      };
    case types.versionGroupDeleted:
      return {
        ...state,
        versionGroupDeleted: { ...action.payload }
      };
    case types.versionGroupRequest:
      return {
        ...state,
        versionGroupRequest: { ...action.payload }
      };
    default:
      return state;
  }
};
