import { types } from '../types/types';

const initialState = {
  checking: true,
  //   email: 'user@gmail.com',
  //   token: 'sdflksjdflkjsdflkjsdf'
};

export const passReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.passForgot:
      return {
        ...state,
        ...action.payload,
        checking: false,
      };

    default:
      return state;
  }
};
