import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { startLogin } from '../../../actions/auth';
import { Form, Input, Button, Icon } from 'semantic-ui-react';
import { TopBarPublic } from '../../../components/TopBar/TopBarPublic';

export const Login = () => {
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state.ui);
  const { autorized } = useSelector((state) => state.auth);

  
  if (autorized) {
    /* id 1-SuperAdministrador; id 2-Administrador; id 7-Cajero; */
    const userData = JSON.parse(localStorage.getItem('user'));
    const userRole = Number(userData.rol_id);
    console.log('login: ', userData.rol_id, userData);
    (userRole === 1 || userRole === 2) ? navigate('/productos') : navigate('/cobrarQR');;    
  }

  // Dispatch
  const dispatch = useDispatch();

  // Show/Hide password
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  //   Validate email
  const [emailError, setEmailError] = useState(false);

  // Validate fields
  const [validForm, setValidForm] = useState({
    email: '',
    password: '',
  });
  // const { email, password } = validForm;

  useEffect(() => {
    if (emailError && validForm.password.length > 7) {
      document.querySelector('#DisableButton').classList.remove('disabled');
    } else {
      document.querySelector('#DisableButton').classList.add('disabled');
    }
  }, [validForm.email, emailError, validForm.password]);

  //   HandleInputChange
  const handleInputChange = (e) => {
    if (e.target.name === 'email') {
      if (validator.isEmail(e.target.value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }

    setValidForm({
      ...validForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    const { email, password } = validForm;
    e.preventDefault();

    const raw = {
      requestContent: {
        credentials: {
          email,
          password
        }
      }
    }

    dispatch(startLogin(raw));
  };

  return (
    <>
      <TopBarPublic />
      <div className="container ptop50">
        <div className="row">
          <div className="col text-center">
            <h1>Inicia sesión</h1>
            <Form
              onSubmit={onSubmit}
              className="general-form small-form mtop30"
            >
              <label className="mbottom30">
                Correo
                <input
                  type="email"
                  name="email"
                  placeholder="Ingresa tu correo"
                  className="input-text"
                  onChange={handleInputChange}
                  required
                />
              </label>
              <label>
                Contraseña
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Crea una nueva contraseña"
                  className="input-text inputPassword"
                  value={validForm.password}
                  onChange={handleInputChange}
                  icon={
                    showPassword ? (
                      <Icon
                        name="eye slash outline"
                        link
                        onClick={handleShowPassword}
                      />
                    ) : (
                      <Icon name="eye" link onClick={handleShowPassword} />
                    )
                  }
                />
              </label>
              <Button
                id="DisableButton"
                className="cta disabled"
                loading={loading}
              >
                Iniciar Sesión
              </Button>
              <Link to="/recuperar-contrasena" className="dblock">
                Olvidé mi contraseña
              </Link>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
