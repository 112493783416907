import { types } from '../types/types';

const initialState = {
  creation: {
    condimentID: 1,
    micros_id: 1,
    symphony_id: 1,
    name: '',
    description: '',
    price: '',
    name_web: '',
    SubCondiment: [],
  },
  request: {},
  created: {},
  updated: {},
  deleted: {},
  allCondiments: null,
};

export const condimentReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case types.condimentAll:
      return {
        ...state,
        allCondiments: [...action.payload],
      };
    case types.condimentCreated:
      return {
        ...state,
        created: {
          ...action.payload,
        },
      };
    case types.condimentUpdated:
      return {
        ...state,
        updated: {
          ...action.payload,
        },
      };
    case types.condimentDeleted:
      return {
        ...state,
        deleted: {
          ...action.payload,
        },
      };

    case types.condimentRequest:
      return {
        ...state,
        request: {
          ...action.payload,
        },
      };
    case types.condimentById:
      return {
        ...state,
        creation: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};
