import React from 'react';
import { Link } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';

export const VersionGroup = ({
  index,
  image,
  name,
  status,
  price,
  productID,
  symphonyID,
  description
}) => {
  return (
    <Draggable key={'' + productID} draggableId={'' + index} index={index}>
      {(provided) => (
        <div
          className="category-item"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="category-item__drag">
            <span className="icon-drag"></span>
          </div>
          <div className="category-item__left">
            <img src={image} width="" height="" />
          </div>
          <div className="category-item__right">
            <span className="category-item__position">
              Posición #{index + 1}
            </span>
            <h3>{name} <span style={{color: '#939188', fontWeight: 600}}>({description})</span></h3>
            <p>
            <span>Symphony: #{symphonyID}</span></p>
            <p><span>Q {price.toFixed(2)}</span></p>
            <p><span>Estado: {status ? 'Activo' : 'Inactivo'}</span>{' '}
            </p>
            <Link to={`/productos/product/form/${productID}`}>
              <span className="icon-edit"></span>
            </Link>
          </div>
        </div>
      )}
    </Draggable>
  );
};
