import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { AppRouter } from './routes/AppRouter';
import { store } from './store/store';

export const AdminBaristaApp = () => {
  return (
    <Provider store={store}>
      <AppRouter />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        style={{ width: '80%', maxWidth: '670px' }}
      />
    </Provider>
  );
};
