import { types } from '../types/types';

const initialState = {
  roles: [],
  loading: false,
  error: null
};

export const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.rolesAll:
      return {
        ...state,
        roles: [...action.payload],
      };
    default:
      return state;
  }
};
