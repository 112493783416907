// Return products format

export const prepareProductsGroups = (groups = []) => {
  return groups.map((e) => ({
    subMenuItemGroupID: e.subMenuItemGroupID,
    //microsID: e.microsID,
    name: e.name,
    description: e.description,
    //minimun_selection: e.minimumSelection,
    //maximun_selection: e.maximumSelection,
    status: e.status,
    //nameWeb: e.nameWeb,
    menuItems: e.menuItems,
    //products: e.productsCount,
  }));
};
