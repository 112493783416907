import React from 'react';
import { Navigate } from 'react-router-dom';

export const PrivateRoutes = ({ children }) => {
  let isUserId = JSON.parse(localStorage.getItem('user'));
  let userId, user;

  if (isUserId) {
    userId = isUserId.autorized;
    user = userId;
  } else {
    user = false;
  }
  // const data = JSON.parse(localStorage.getItem('user'));

  return user ? children : <Navigate to="/" />;
};

// import React from 'react';
// import PropTypes from 'prop-types';
// import { Routes, Navigate, Outlet } from 'react-router-dom';
// // import { Route } from 'react-router';

// const PrivateRoutes = ({ isAutenticated, component: Component, ...rest }) => {
//   return (
//     <Routes
//       {...rest}
//       component={(props) =>
//         isAutenticated ? <Outlet {...props} /> : <Navigate to="/" />
//       }
//     />
//   );
// };

// PrivateRoutes.propTypes = {
//   isAutenticated: PropTypes.bool.isRequired,
//   component: PropTypes.func.isRequired,
// };

// export default PrivateRoutes;
