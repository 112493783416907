import { toast } from 'react-toastify';
import { fetchService } from '../helpers/fetch';
import { prepareCategories } from '../helpers/prepareCategories';
import { types } from '../types/types';
import { revalidateKey } from './key';
import { finishLoading, startLoading } from './ui';

// Get all categories list

export const getAllCategories = (data, token) => {
  return async (dispatch) => {
    const body = fetchService('/categories/v1/all', data, token)
      .then((res) => {
        console.log(res)
        const categories = prepareCategories(res.responseContent);
        console.log('categories', categories);
        dispatch(getCategories(categories));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getCategories = (categories) => {
  return {
    type: types.categoryAll,
    payload: categories,
  };
};

// Create category
const categorySuccess = (category) => {
  return {
    type: types.categoryCreateSuccess,
    payload: {
      ...category,
      success: true,
    },
  };
};

const categoryFailed = (category) => {
  return {
    type: types.categoryCreateFailed,
    payload: {
      ...category,
      success: false,
    },
  };
};

// Get category by id

export const categoryById = (data, token) => {
  return async (dispatch) => {
    const body = fetchService('/categories/v1/id', data, token)
      .then((res) => {
        dispatch(revalidateKey(res.info.sessionId));
        dispatch(getCategoryById(res.responseContent));
      })
      .catch((err) => {
        dispatch(revalidateKey(err.info.sessionId));
      });
  };
};

export const getCategoryById = (category) => {
  return {
    type: types.categoryById,
    payload: category,
  };
};

// Update category

export const startDeleteCategory = (data, token, category) => {
  return async (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/categories/v1/delete', data, token)
      .then((res) => {
        dispatch(finishLoading());
        dispatch(revalidateKey(res.info.sessionId));
        dispatch(deleteCategory(category));
        toast.success('Categoria eliminada');
      })
      .catch((err) => {
        dispatch(revalidateKey(err.info.sessionId));
        dispatch(finishLoading());
      });
  };
};

export const deleteCategory = (category) => {
  return {
    type: types.categoryDelete,
    payload: category,
  };
};


/// REFACTOR
export const setRedux = (type, data) => {
  return {
    payload: data,
    type: types[type]
  };
};

export const createCategory = (categoryData, token, category) => {
  return (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/categories/v1/update', categoryData, token, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(setRedux('createCategory', category.creation));
        dispatch(setRedux('categorySuccess', category.creation));
        // dispatch(setRedux('categoryRequest', res));
        // dispatch(setRedux('categoryUpdated', category));
        toast.success(res?.info?.message || 'Categoría creada con exito.');
      })
      .catch((err) => {
        toast.error(err?.info?.message || 'Ocurrió un error al crear la categoría.');
        dispatch(revalidateKey(err?.info?.sessionId));
        dispatch(setRedux('categoryRequest', err));
        dispatch(finishLoading());
      });
  };
};

export const updateCategory = (categoryData, token, category) => {
  return (dispatch) => {
    dispatch(startLoading());

    const body = fetchService('/categories/v1/update', categoryData, token, 'POST')
      .then((res) => {
        dispatch(finishLoading());
        dispatch(setRedux('categoryRequest', res));
        dispatch(setRedux('categoryUpdated', category));
        toast.success(res?.info?.message || 'Categoría actualizada.');
      })
      .catch((err) => {
        toast.error(err?.info?.message || 'No se pudo actualizar la categoría.');
        dispatch(revalidateKey(err?.info?.sessionId));
        dispatch(setRedux('categoryRequest', err));
        dispatch(finishLoading());
      });
  };
};