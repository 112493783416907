import { Form, Button } from 'semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convert } from '../../../../helpers/functions';

const initialData = {
  name: {
    value: '',
    error: false
  },
  description: {
    value: '',
    error: false
  },
  textDateInit: {
    value: '',
    error: false
  },
  textDateEnd: {
    value: '',
    error: false
  },
  initHour: 0,
  initMinutes: 0,
  endHour: 0,
  endMinutes: 0,
  formattedSchedule: '',
  formattedEndHour: 'am',
  formattedInitHour: 'am',
  dayOfTheWeek: {
    monday: false,
    tuesday: false,
    wednesday: true,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  }
}

export const FormCreateStoreSchedule = ({ handleCreateNewSchedule }) => {
  /*********************** NEW ***********************/
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.ui);
  const storeScheduleRequest = useSelector((state) => state.store.storeRequest);

  /***  Variables ***/
  // Formulario
  const [form, setForm] = useState(initialData);
  const [validForm, setValidForm] = useState(false);
  const dayOfTheWeekList = [
    ['monday', 'Lunes', '42px'],
    ['tuesday', 'Martes', '34px'],
    ['wednesday', 'Miercoles', '15px'],
    ['thursday', 'Jueves', '39px'],
    ['friday', 'Viernes', '31px'],
    ['saturday', 'Sábado', '31px'],
    ['sunday', 'Doming', '28px'],
  ];

  /***  FUNCIONES ***/
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });
  };

  const handlerDayOfTheWeek = async (e) => {
    const dayOfTheWeekAux = { ...form.dayOfTheWeek };
    dayOfTheWeekAux[e.target.name] = form.dayOfTheWeek[e.target.name] === true ? false : true;
    setForm({
      ...form,
      dayOfTheWeek: dayOfTheWeekAux
    });
  }

  /***  USE EFFECT ***/
  useEffect(() => {
    console.log('entro');
    if (
      form.name.value.length <= 1 ||
      form.textDateInit.value.length === 0 ||
      form.textDateEnd.value.length === 0
    ) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [form]);

  useEffect(() => {
    if (storeScheduleRequest?.info?.type === 'success') setForm(initialData);
  }, [storeScheduleRequest]);

  /***  OLD ***/
  // State AM - PM
  const [startActiveTime, setStartActiveTime] = useState(true);
  const handleTimeStart = () => {
    setStartActiveTime(!startActiveTime);
  };

  const [endActiveTime, setEndActiveTime] = useState(true);
  const handleTimeEnd = () => {
    setEndActiveTime(!endActiveTime);
  };

  const [handleTitle, setHandleTitle] = useState('');
  const [handleFrom, setHandleFrom] = useState('');
  const [handleTo, setHandleTo] = useState('');

  //   const convert = (time) => {
  //     return moment(time, ['h:mm A']).format('HH:mm');
  //   };

  const createNewSchedule = (e) => {
    e.preventDefault();
    const schedule = {
      id: -1,
      name: handleTitle,
      description: handleTitle,
      from: convert(`${handleFrom} ${!startActiveTime ? 'PM' : 'AM'} `),
      to: convert(`${handleTo} ${!endActiveTime ? 'PM' : 'AM'} `),
    };

    // console.log(schedule);

    handleCreateNewSchedule(schedule);
    setHandleTitle('');
    setHandleFrom('');
    setHandleTo('');
    setStartActiveTime(true);
    setEndActiveTime(true);
  };

  // $('#from').inputmask('hh:mm', {
  //   placeholder: '00:00',
  //   insertMode: false,
  //   showMaskOnHover: false,
  //   hourFormat: 12,
  // });


  return (
    <>
      <Form className="row p-0 row-group mbottom30" onSubmit={createNewSchedule}>
        <div className="col-6">
          <label className={form.name.error ? 'errorField' : ''}>
            Nombre de horario
            <input
              type="text"
              name="name"
              minLength="1"
              required={true}
              className="input-text"
              onBlur={handleValidate}
              onChange={handleChange}
              value={form.name.value}
              placeholder="Nombra este horario"
            />
          </label>
        </div>
        <div className="col-3">
          <label className={form.textDateInit.error ? 'errorField' : ''}>
            Hora inicio
            <div className="input-time">
              <input
                type="text"
                minLength="4"
                required={true}
                id="textDateInit"
                name="textDateInit"
                placeholder="HH:MM"
                onBlur={handleValidate}
                onChange={handleChange}
                data-valid-example="HH:MM"
                value={form.textDateInit.value}
              // onChange={handlerFrom}
              // value={handleFrom}
              />
              <button
                type="button"
                className="input-time__button"
                onClick={handleTimeStart}
              >
                {startActiveTime ? 'AM' : 'PM'}
              </button>
            </div>
          </label>
        </div>
        <div className="col-3 relative">
          <label className={form.textDateEnd.error ? 'errorField' : ''}>
            Hora fin
            <div className="input-time">
              <input
                type="text"
                minLength="4"
                required={true}
                id="textDateEnd"
                name="textDateEnd"
                placeholder="HH:MM"
                onBlur={handleValidate}
                onChange={handleChange}
                // data-valid-example="HH:MM"
                value={form.textDateEnd.value}
              // onChange={handlerTo}
              // value={handleTo}
              />
              <button
                type="button"
                className="input-time__button"
                onClick={handleTimeEnd}
              >
                {endActiveTime ? 'AM' : 'PM'}
              </button>
            </div>
          </label>
        </div>
        <div className="col-12 relative mt-4">
          <label>Selecciona los días en los que aplica este horario</label>
          <div className='row'>
            {dayOfTheWeekList.map((day, index) => (
              <div className='col-2 mt-3 day-bag'>
                <span style={{ marginRight: day[2] }}> {day[1]}</span>
                <input
                  name={day[0]}
                  type="checkbox"
                  value={index + 1}
                  key={day[0] + index}
                  id='barista-checkbox'
                  className='barista-checkbox'
                  onChange={(e) => handlerDayOfTheWeek(e)}
                  checked={form.dayOfTheWeek[day[0]] === true}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-12">
          <Button
            loading={loading}
            disabled={!validForm}
            className={`cta cta--tiny cta--secondary-plain mtop30 ${!validForm ? 'disabled' : ''}`}
          >
            Agregar horario
          </Button>
        </div>
      </Form>
    </>
  );
};
