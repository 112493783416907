import moment from 'moment';
import React, { useState } from 'react';

export const FormEditStoreSchedule = ({
  index,
  name,
  id,
  fromInit,
  toInit,
  from,
  to,
  handlerModal,
  handlesetUpdateSchedule,
}) => {
  const setFromInit = +fromInit;
  const setToInit = +toInit;

  // State AM - PM
  const [startActiveTime, setStartActiveTime] = useState(
    setFromInit > 12 ? false : true
  );
  const handleTimeStart = () => {
    setStartActiveTime(!startActiveTime);
  };

  const [endActiveTime, setEndActiveTime] = useState(
    setToInit > 12 ? false : true
  );
  const handleTimeEnd = () => {
    setEndActiveTime(!endActiveTime);
  };

  //   Edit
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => {
    setOpenEdit(!openEdit);
  };

  return (
    <>
      <div key={index} className="col-12 schedule-item">
        <div className="schedule-item__top">
          <div className="schedule-item__left">
            <p>{name}</p>
          </div>
          <div className="schedule-item__right">
            <button type="button" onClick={handleOpenEdit}>
              <span className="icon-edit"></span> Editar
            </button>
            <button
              type="button"
              onClick={() => {
                handlerModal('delete', index);
              }}
            >
              <span className="icon-delete"></span> Eliminar
            </button>
          </div>
        </div>
        <div
          className={`schedule-item__middle ${
            openEdit ? 'schedule-item__middle--open' : ''
          }`}
        >
          <div className="m-0">
            <div className="row p-0 row-group">
              <div className="col-6">
                <input
                  type="hidden"
                  name="id"
                  value={id}
                  onChange={(e) => {
                    handlesetUpdateSchedule(e, index);
                  }}
                />
                <label className="dblock">
                  Nombre de horario
                  <input
                    type="text"
                    name="name"
                    placeholder="Nombra este horario"
                    className="input-text"
                    value={name}
                    onChange={(e) => {
                      handlesetUpdateSchedule(e, index);
                    }}
                    required
                  />
                </label>
              </div>
              <div className="col-3">
                <label className="dblock">
                  Hora inicio
                  <div className="input-time">
                    <input
                      type="text"
                      name="from"
                      placeholder="HH:MM"
                      className="input-text"
                      // pattern="[0-9]{4}-[0-1][0-9]-[0-3][0-9] (1[012]|0[1-9]):[0-5][0-9] (am|pm|AM|PM)"
                      value={from}
                      onChange={(e) => {
                        handlesetUpdateSchedule(e, index);
                      }}
                      required
                    />
                    <button
                      type="button"
                      className="input-time__button"
                      onClick={() => handleTimeStart(index)}
                    >
                      {startActiveTime ? 'AM' : 'PM'}
                    </button>
                  </div>
                </label>
              </div>
              <div className="col-3">
                <label className="dblock">
                  Hora fin
                  <div className="input-time">
                    <input
                      type="text"
                      name="to"
                      placeholder="HH:MM"
                      className="input-text"
                      // pattern="[0-9]{4}-[0-1][0-9]-[0-3][0-9] (1[012]|0[1-9]):[0-5][0-9] (am|pm|AM|PM)"
                      value={to}
                      onChange={(e) => {
                        handlesetUpdateSchedule(e, index);
                      }}
                      required
                    />
                    <button
                      type="button"
                      className="input-time__button"
                      onClick={() => handleTimeEnd(index)}
                    >
                      {endActiveTime ? 'AM' : 'PM'}
                    </button>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
