import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { resendPassword } from '../../../actions/pass';
import { Back } from '../../../components/Back/Back';
import { BackRelative } from '../../../components/Back/BackRelative';
import { TopBarPublic } from '../../../components/TopBar/TopBarPublic';

export const ResendPassword = () => {
  const { loading } = useSelector((state) => state.ui);

  //Dispatch
  const dispatch = useDispatch();

  // Get email
  const email = localStorage.getItem('email');
  if (!email) {
    window.location.href = '/';
  }

  const handleSubmitPass = () => {
    var data = JSON.stringify({
      requestContent: {
        credentials: {
          email,
        },
      },
    });

    dispatch(resendPassword(data, email));
  };

  return (
    <>
      <TopBarPublic />
      <div className="container">
        <div className="row full-height">
          <Back url="/login" />
          <div className="col">
            <div className="large-wrap">
              <h1 className="text-center">Recupera tu contraseña</h1>
              <p>
                Hemos enviado un correo a rob@barista.com con instrucciones para
                renovar tu contraseña. Puede demorar un par de minutos en
                llegar, recuerda revisar tu folder de spam.
              </p>
              <div className="medium-wrap">
                <p className="resend-email">
                  ¿No encuentras el correo?{' '}
                  <Button
                    loading={loading}
                    onClick={handleSubmitPass}
                    className="link-button"
                  >
                    Reenviar
                  </Button>
                </p>
                <p className="contact-support">
                  ¿Necesitas ayuda adicional?{' '}
                  <Link to="/reenviar-contrasena">Contactar soporte</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
