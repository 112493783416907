import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button } from 'semantic-ui-react';
import validator from 'validator';
import { recoverPassword } from '../../../actions/pass';
import { Back } from '../../../components/Back/Back';
import { TopBarPublic } from '../../../components/TopBar/TopBarPublic';

export const ForgotPassword = () => {
  const { loading } = useSelector(state => state.ui);
  // Dispatch
  const dispatch = useDispatch();

  //   Validate email
  const [emailError, setEmailError] = useState(false);

  // Validate fields
  const [validForm, setValidForm] = useState({
    email: '',
    password: '',
  });
  const { email } = validForm;

  useEffect(() => {
    if (emailError) {
      document.querySelector('#DisableButton').classList.remove('disabled');
    } else {
      document.querySelector('#DisableButton').classList.add('disabled');
    }
  }, [email]);

  //   HandleInputChange
  const handleInputChange = (e) => {
    e.preventDefault();
    if (e.target.name === 'email') {
      if (validator.isEmail(e.target.value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }

    setValidForm({
      ...validForm,
      [e.target.name]: e.target.value,
    });
  };

  // Submit
  const onSubmit = (e) => {
    e.preventDefault();

    var data = JSON.stringify({
      requestContent: {
        credentials: {
          email,
        },
      },
    });

    dispatch(recoverPassword(data, email));
  };

  return (
    <>
      <TopBarPublic />
      <div className="container position-relative">
        <Back url="/" />
        <div className="row full-height">
          <div className="col text-center">
            <h1>Recupera tu contraseña</h1>
            <p>
              Crea una contraseña para tu usuario robz@barista.com para iniciar
              sesión en el administrardor.
            </p>
            <Form
              onSubmit={onSubmit}
              className="general-form small-form mtop30"
            >
              <label className="mbottom30">
                Correo
                <input
                  type="email"
                  name="email"
                  placeholder="Ingresa tu correo"
                  className="input-text"
                  onChange={handleInputChange}
                  required
                />
              </label>
              <Button
                loading={loading}
                id="DisableButton"
                className="cta disabled"
              >
                Continuar
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
