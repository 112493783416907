import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

export const ProductActiveItem = ({ form, setForm, listActivated, setListActivated }) => {
  const handlerRemoveProduct = (removeMenuItemID) => {
    const list = listActivated;
    const remove = list.filter((product) => product?.menuItemID !== removeMenuItemID);
    setListActivated(remove);
    setForm({ ...form, formTouched: true });
  };

  return listActivated.map((product, index) => (
    <Draggable
      key={'' + product?.menuItemID}
      draggableId={'' + product?.menuItemID}
      index={index}
    >
      {(provided) => (
        <div
          className="select-drag__item-drag"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <span className="icon-drag mright5"></span>
          {index + 1}. {product?.name}
          <button
            type="button"
            onClick={() => {
              handlerRemoveProduct(product?.menuItemID);
            }}
          >
            -
          </button>
        </div>
      )}
    </Draggable>
  ));
};
