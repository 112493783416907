import React from 'react';
import { Link } from 'react-router-dom';

export const EmptyState = ({
  title,
  text,
  titleButton,
  linkButton,
  hasSchedules,
  titleButtonPlain,
  linkButtonPlain,
  titleButtonSolid,
  linkButtonSolid,
}) => {
  return (
    <>
      <div className="container text-center empty-state">
        <h1>{title}</h1>
        <div
          className={`empty-state__buttons ${hasSchedules ? 'dflex' : 'dnone'
            } `}
        >
          {linkButtonPlain && (
            <Link
              className="cta cta--secondary-plain cta--widthAuto margin0"
              to={linkButtonPlain}
            >
              {titleButtonPlain}
            </Link>
          )}
          {linkButtonSolid && (
            <Link className="cta cta--widthAuto margin0" to={linkButtonSolid}>
              {titleButtonSolid}
            </Link>
          )}
        </div>
        <div className="empty-state__content medium-wrap">
          <img src="./images/empty-icon.svg" alt={title} />
          <p>{text}</p>
          {linkButton != '' ? (
            <Link
              className={`cta cta--secondary cta--small cta--widthAuto margin0 ${hasSchedules ? 'cta--secondary-light' : ''
                }`}
              to={linkButton}
            >
              {titleButton}
            </Link>
          ) : (
            <span className='d-block my-5'>&nbsp;</span>
          )}
        </div>
      </div>
    </>
  );
};
