import { Droppable } from 'react-beautiful-dnd';
import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'semantic-ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { singleFetchService } from '../../../helpers/functions';
import { BackRelative } from '../../../components/Back/BackRelative';
import { CondimentActiveItem } from './components/CondimentActiveItem';
import { CondimentSelectItem } from './components/CondimentSelectItem';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import { createNewCondiment, startUpdateCondiment, startDeleteCondiment } from '../../../actions/condiments';

const initialData = {
  name: {
    value: '',
    error: false
  },
  nameWeb: {
    value: '',
    error: false
  },
  symphonyID: {
    value: '',
    error: false
  },
  price: {
    value: 0,
    error: false
  },
  description: {
    value: '',
    error: false
  },

  subCondimentID: undefined,
  // extra
  image: '',
  microsID: '',
  status: true,
  default: false,
  condimentOrder: 0,
};

const list = [];

export const FormCondiment = () => {
  /***  Selector  ***/
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('');
  let key = useSelector((state) => state.key.key);
  const { loading } = useSelector((state) => state.ui);
  const condimentRequest = useSelector((state) => state.condiment.request);


  // Obtener parametro GET (condimentGroup)
  const location = useLocation();
  const [form, setForm] = useState(initialData);
  const condimentID = (location.pathname.match(/\d+/) || [])[0] || undefined;

  // Modal states
  const [titleModal, setTitleModal] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [resumeModal, setResumeModal] = useState('');

  /*** Funciones ***/
  const handleValidate = (e) => {
    e.preventDefault();

    const validator = (
      (form[e.target.name]?.value?.length === 0 && e.target.required) ||
      (e.target.maxLength > 0 && form[e.target.name].value.length > e.target.maxLength) ||
      (e.target.minLength > 0 && form[e.target.name].value.length < e.target.minLength)
    ) ? true : false;

    setForm({
      ...form, [e.target.name]: {
        error: validator,
        value: form[e.target.name].value
      }
    });
  };

  const handleValidatePrice = (e) => {
    e.preventDefault();

    const validator = (
      parseFloat(e.target.value) <= -1 || parseFloat(form.price.value) <= -1 || e.target.value == ''
    ) ? true : false;

    setForm({
      ...form, price: {
        error: validator,
        value: form.price.value
      }
    });
  };

  const handleChange = (e) => {
    e.preventDefault();

    setForm({
      ...form,
      [e.target.name]: {
        value: e.target.value,
        ...form[e.target.name].error
      }
    });
  };

  const handlerPrice = (e) => {
    e.preventDefault();

    const value = e.target.value != '' ? parseFloat(e.target.value): null;
    setForm({
      ...form,
      price: {
        value: value,
        ...form[e.target.name].error
      }
    });
  };

  const handlerSymphonyID = (e) => {
    e.preventDefault();
    // validar codigo
  };

  /*************************** Selección de condimentos ***************************/
  // Get condiments
  const condimentsList = useSelector((state) => state.condiment.allCondiments);
  const [condimentsListAux, setCondimentsListAux] = useState(condimentsList);

  const [isCategoryAdded, setIsCategoryAdded] = useState(false);

  // Order functions
  const [listActivated, setlistActivated] = useState(list);

  // Funciones de condimentos
  const handleSearch = (e) => {
    const value = e.target.value;

    if (value !== '') {
      const results = condimentsList.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      });
      setCondimentsListAux(results);
    } else {
      setCondimentsListAux(condimentsList);
    }
    setKeyword(value);
  }

  // Adding categories to list activated
  const handleActiveCategory = (newCat) => {
    setlistActivated((prevCats) => {
      return [newCat, ...prevCats];
    });
  };

  // Change order list categories activated
  const handleOnDrag = (result) => {
    const items = Array.from(listActivated);
    const [reorderedItems] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItems);
    items.map((item, index) => items[index].order = index);
    setlistActivated(items);
  };


  // Obtener un condimento
  const getOneCondiment = () => {
    const data = JSON.stringify({
      requestContent: {
        condiment: { condimentID: +condimentID }
      }
    });

    const body = singleFetchService('/condiments/v1/id', data, key)
      .then(async (response) => {
        const res = response.responseContent;
        setForm({
          default: res?.default,
          image: res?.image || '',
          microsID: res?.microsID,
          status: res?.status || true,
          condimentID: res?.condimentID,
          condimentOrder: res?.condimentOrder,
          subCondimentID: res?.subCondimentID,
          name: { value: res?.name || '', error: false },
          nameWeb: { value: res?.nameWeb || '', error: false },
          symphonyID: { value: res?.symphonyID || '', error: false },
          price: { value: parseFloat(res?.price) || 0, error: false },
          description: { value: res?.description || '', error: false },
        });

        if (res.subCondimentID && res.subCondimentID > 0) setSubCondiment(res.subCondimentID);
      })
      .catch((err) => console.error(err));
    return body;
  };

  const setSubCondiment = (subCondimentID) => {
    const findCondiments = condimentsList.find(x => x.condimentID == subCondimentID);
    if (findCondiments != undefined) setlistActivated([findCondiments]);
  }

  // Enviar formulario 
  const onSubmit = (e) => {
    e.preventDefault();

    const dataCondiment = JSON.stringify({
      requestContent: {
        condiment: {
          type: condimentID ? 'updated' : 'created',
          condimentID: Number(condimentID) || undefined,
          image: '',
          name: form.name.value,
          nameWeb: form.nameWeb.value,
          symphonyID: form.symphonyID.value,
          price: parseFloat(form.price.value),
          description: form.description.value,
          status: form.status,
          default: form.default,
          microsID: form.microsID,
          condimentOrder: form.condimentOrder,
          subCondimentID: listActivated[0]?.subCondimentID || null
        }
      }
    });

    if (!condimentID) {
      dispatch(createNewCondiment(dataCondiment, key));
    } else {
      dispatch(startUpdateCondiment(dataCondiment, key));
    }
  };

  // Eliminar condimento
  const handlerModal = (type, index) => {
    switch (type) {
      case 'delete':
        setTitleModal('¿Estás seguro que deseas eliminar este condimento?');
        setResumeModal('No podrás revertir esta acción.');
        setShowModal(true);
        break;
      case 'noDelete':
        setTitleModal('¡No es posible eliminar un condimento con un sub-condimento asignadas!');
        setResumeModal('Para eliminarlo, primero remueve el sub-condimentos que tiene asignadas.');
        setShowModal(true);
        break;
      default:
        setTitleModal(null);
        setResumeModal(null);
        setShowModal(false);
        break;
    }
  };

  const deleteGroup = (id) => {
    const condiment = {
      condimentID: +id,
      name: form.name.value,
      nameWeb: form.nameWeb.value
    }

    const data = JSON.stringify({
      requestContent: { condiment: condiment }
    });

    dispatch(startDeleteCondiment(data, key, condiment));
  };

  /***  useEffects  ***/

  // Crear o Editar
  useEffect(() => {
    if (condimentID != undefined) {
      getOneCondiment();
    }
  }, [condimentID]);


  // Set state when form is invalid
  const [validForm, setValidForm] = useState(false);
  useEffect(() => {
    if (form.name.value.length < 2 || form.nameWeb.value.length < 2 || form.description.value.length < 2 || form.symphonyID.value.length < 2 || parseFloat(form.price.value) <= -1 || form.price.value == null) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [form]);

  // Re-direccionar
  useEffect(() => {
    if (condimentRequest?.info?.type === 'success') navigate('/condimentos');
  }, [condimentRequest]);

  return (
    <>
      <div className="container pbottom50">
        <BackRelative url="/condimentos" />
        <div className="medium-wrap">
          <h1 className="text-center m-0 pbottom30">
            {condimentID ? 'Editar condimento' : 'Crear condimento'}
          </h1>
          <Form onSubmit={onSubmit} className="general-form">
            <div className="row">
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.name.error ? 'errorField' : ''}>
                    Nombre a mostrar en la app
                    <Input
                      type="text"
                      name="name"
                      minLength="2"
                      maxLength="25"
                      required={true}
                      placeholder="Nombre para clientes"
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.name.value}
                    />
                    <span className="countCharacters dblock text-right font15">
                      {form.nameWeb.value?.length}/25
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.nameWeb.error ? 'errorField' : ''}>
                    Nombre en administrador
                    <Input
                      type="text"
                      name="nameWeb"
                      placeholder="Nombre para uso interno"
                      minLength="2"
                      maxLength="25"
                      required={true}
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.nameWeb.value}
                    />
                    <span className="countCharacters dblock text-right font15">
                      {form.nameWeb.value?.length}/25
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="mbottom30">
                  <label className={form.symphonyID.error ? 'errorField' : ''}>
                    Symphony ID
                    <Input
                      type="text"
                      name="symphonyID"
                      placeholder="Ingresa el Symphony ID"
                      minLength="1"
                      maxLength="25"
                      required={true}
                      onBlur={handleValidate}
                      onChange={handleChange}
                      value={form.symphonyID.value}
                    />
                    <span className="countCharacters dblock text-right font15">
                      {form.symphonyID.value.length}/25
                    </span>
                  </label>
                </div>
              </div>
              {/* unit-of-measurement__input  */}
              <div className="col-6">
                <div className="mbottom30">
                  <label className="has-tooltip-inline pb-1">Precio</label>
                  <div className={form.price.error ? 'unit-of-measurement__input_error' : 'unit-of-measurement__input'} >
                    <label >
                      GTQ
                      <input
                        className="unit-left"
                        name="price"
                        type="number"
                        placeholder="00"
                        onChange={handlerPrice}
                        onBlur={handleValidatePrice}
                        value={form.price.value}
                        required
                      />
                    </label>
                  </div>
                </div>
              </div>
              {/* <div className="col-6"> */}
              {/* <div className="mbottom30">
                  {/*<label className={validMicrosID ? '' : 'errorField'}>
                    Micros ID
                    <Input
                      type="text"
                      name="MicrosId"
                      placeholder="Ingresa el Micros ID"
                      maxLength="25"
                      onChange={handlerNameMicros}
                      value={nameMicros}
                      required
                    />
                    <span className="error-tag">
                      <span className="icon-note"></span>Este código ya está en
                      uso.
                    </span>
                  </label>*/}
              {/* </div> */}
              {/* </div> */}
            </div>
            <div className="mbottom30">
              <label className={form.description.error ? 'errorField' : ''}>
                Descripción
                <textarea
                  rows="30"
                  placeholder="Proporciona una descripción breve..."
                  minLength="5"
                  maxLength="300"
                  required={true}
                  name='description'
                  onBlur={handleValidate}
                  onChange={handleChange}
                  value={form.description.value}
                ></textarea>
                <span className="countCharacters dblock text-right font15">
                  {form.description.value?.length}/300
                </span>
              </label>
            </div>
            {/* <div className="mbottom30 col-3">
              <label>
                Precio
                <Input
                  type="number"
                  name="price"
                  placeholder="GTQ 25.00"
                  maxLength="25"
                  onChange={handlerNamePrice}
                  value={namePrice}
                  required
                />
                </label>
            </div> */}
            <div className="select-drag col-12">
              <h3>Selección de sub-condimentos</h3>
              <div className="select-drag__wrap">
                <div className="select-drag__left">
                  <h4>Existentes</h4>
                  <div className='w-100 px-2 mb-2 mt-0'>
                    <div class="ui left icon input search-in-subcategory text-center m-auto w-100 mt-0">
                      <i aria-hidden="true" class="search icon" style={{ top: '0px' }}></i>
                      <input type="text" placeholder="Buscar" style={{ marginTop: '0px' }} onChange={handleSearch} value={keyword} />
                    </div>
                  </div>
                  <div className="select-drag__list" >
                    {condimentsListAux?.map((condimento, index) => (
                      <CondimentSelectItem
                        key={index}
                        handleActiveCategory={handleActiveCategory}
                        subCondimentID={condimento.condimentID}
                        name={condimento.name}
                        listActivated={listActivated}
                        isCategoryAdded={isCategoryAdded}
                        setIsCategoryAdded={setIsCategoryAdded}
                      />
                    ))}
                  </div>
                </div>
                <div className="select-drag__right">
                  <h4>Seleccionados</h4>
                  <div className="select-drag__list-drag">
                    {listActivated.length > 0 ? (
                      <DragDropContext onDragEnd={handleOnDrag}>
                        <Droppable droppableId="list-subcondiments">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              <CondimentActiveItem
                                listActivated={listActivated}
                                setlistActivated={setlistActivated}
                                handleActiveCategory={handleActiveCategory}
                                isCategoryAdded={isCategoryAdded}
                                setIsCategoryAdded={setIsCategoryAdded}
                              />
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <p className="text-center font14">
                        Agrega condimentos individuales.
                      </p>
                    )}
                    {listActivated.length === 1 && (
                      <p className="text-limit text-left" >
                        <span className="icon-warning"></span>Solo puedes añadir un máximo de un sub-condimento.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* valid {Number(validForm)} */}
            {!condimentID ?
              <div className="text-center">
                <Button
                  loading={loading}
                  disabled={!validForm}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                // disabled={listCondimentActivated?.length != maximun_selection}
                >
                  Guardar condimento
                </Button>
              </div>
              :
              <div className="flex flex--between">
                <Button
                  type="button"
                  className="cta cta--secondary-plain"
                  onClick={() => {
                    handlerModal(
                      listActivated?.length > 0 ? 'noDelete' : 'delete'
                    );
                  }}
                >
                  Eliminar condimento
                </Button>
                <Button
                  loading={loading}
                  disabled={!validForm}
                  className={`cta cta--secondary ${validForm ? '' : 'disabled'}`}
                // disabled={listCondimentActivated?.length != maximun_selection}
                >
                  Guardar cambios
                </Button>
              </div>
            }
          </Form>
        </div>
      </div>

      <DeleteModal
        show={showModal}
        id={condimentID}
        setShow={setShowModal}
        title={titleModal}
        resume={resumeModal}
        handleDelete={deleteGroup}
        withContent={listActivated?.length > 0 ? true : false}
        isLoading={loading}
      />
    </>
  );
};
