import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Button, Form, Input } from 'semantic-ui-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { startCreateGroup } from '../../../actions/groups';
import { BackRelative } from '../../../components/Back/BackRelative';
import { CategoryActiveItem } from './components/CategoryActiveItem';
import { CategorySelectItem } from './components/CategorySelectItem';
// import { Droppable } from 'react-beautiful-dnd';

const initialState = {
  name: '',
  description: '',
  categories: [],
};

const initialStateActiveCategories = [];

export const CreateCategoryGroup = () => {
  // Dispatch function
  const dispatch = useDispatch();

  // Get key = SessionId
  const { key } = useSelector((state) => state.key);

  // Loading state for buttons
  const { loading } = useSelector((state) => state.ui);

  // List categories and list categories activated
  const { list } = useSelector((state) => state.category);

  const [listCategoriesActivated, setListCategoriesActivated] = useState(
    initialStateActiveCategories
  );
  // Adding categories to list activated
  const handleActiveCategory = (newCat) => {
    setListCategoriesActivated((prevCats) => {
      return [newCat, ...prevCats];
    });
  };

  // Change order list categories activated
  const handleOnDrag = (result) => {
    // console.log(result);
    const items = Array.from(listCategoriesActivated);
    const [reorderedItems] = items.splice(result.source.index, 1);
    // console.log(items);

    items.splice(result.destination.index, 0, reorderedItems);

    items.map((item, index) => {
      items[index].order = index;
    });

    setListCategoriesActivated(items);
  };
  // console.log(listCategoriesActivated);

  // console.log(formState);
  // Info group
  const [formState, setFormState] = useState(initialState);

  const handleFormChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const [isCategoryAdded, setIsCategoryAdded] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    // console.log(listCategoriesActivated);

    var data = JSON.stringify({
      requestContent: {
        categoryGroup: {
          name: formState.name,
          description: formState.description,
          categories: listCategoriesActivated,
        },
      },
    });

    const categoryGroup = {
      id: 1,
      name: formState.name,
      description: formState.description,
      categories: listCategoriesActivated,
    };

    // console.log(categoryGroup);
    dispatch(startCreateGroup(data, key, categoryGroup));

    setFormState(initialState);
    setListCategoriesActivated(initialStateActiveCategories);
  };

  return (
    <>
      <div className="container">
        <BackRelative url="/categorias" />
        <h1 className="text-center m-0 pbottom30">Crear grupo de categorías</h1>
        <div className="medium-wrap">
          <Form onSubmit={onSubmit} className="general-form">
            <div className="mbottom30">
              <label>
                Nombre de grupo
                <Input
                  type="text"
                  name="name"
                  placeholder="Nombra el grupo de categorías"
                  maxLength="25"
                  onChange={handleFormChange}
                  value={formState?.name}
                  required
                />
                <span className="countCharacters dblock text-right font15">
                  {formState.name?.length}/25
                </span>
              </label>
            </div>
            <div className="mbottom30">
              <label>
                Descripción
                <textarea
                  name="description"
                  rows="30"
                  placeholder="Proporciona una descripción breve..."
                  maxLength="150"
                  onChange={handleFormChange}
                  value={formState.description}
                  required
                ></textarea>
                <span className="countCharacters dblock text-right font15">
                  {formState.description.length}/150
                </span>
              </label>
            </div>
            <div className="select-drag">
              <h3>Selección de categorías</h3>
              <div className="select-drag__wrap">
                <div className="select-drag__left">
                  <h4>Individuales</h4>
                  <div className="select-drag__list">
                    {list?.map((cat, index) => (
                      <CategorySelectItem
                        key={index}
                        handleActiveCategory={handleActiveCategory}
                        categoryID={cat.categoryID}
                        name={cat.name}
                        description={cat.description}
                        status={cat.status}
                        schedule={cat.schedule}
                        order={cat.order}
                        image={cat.image}
                        products={cat.productsCount}
                        listCategoriesActivated={listCategoriesActivated}
                        isCategoryAdded={isCategoryAdded}
                        setIsCategoryAdded={setIsCategoryAdded}
                      />
                    ))}
                  </div>
                </div>
                <div className="select-drag__right">
                  <h4>Seleccionados</h4>
                  <div className="select-drag__list-drag">
                    {listCategoriesActivated.length > 0 ? (
                      <DragDropContext onDragEnd={handleOnDrag}>
                        <Droppable droppableId="list-categories">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              <CategoryActiveItem
                                listCategoriesActivated={
                                  listCategoriesActivated
                                }
                                setListCategoriesActivated={
                                  setListCategoriesActivated
                                }
                                handleActiveCategory={handleActiveCategory}
                                isCategoryAdded={isCategoryAdded}
                                setIsCategoryAdded={setIsCategoryAdded}
                              />
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <p className="text-center font14">
                        Agrega las categorías que conforman este grupo.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Button loading={loading} className="cta cta--secondary">
                Guardar grupo
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
