import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

export const CondimentsByGroup = ({
  index,
  condimentID,
  microsID,
  symphonyID,
  price,
  name,
  status,
}) => {
  return (
    <Draggable key={'' + condimentID} draggableId={'' + index} index={index} >
      {(provided) => (
        <div
          className="category-item category-item--condiment category-item--condiment--full"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="category-item__drag">
            <span className="icon-drag"></span>
          </div>
          <div className="category-item__right">
          <span className="category-item__position">
              Posición #{index + 1}
            </span>
            <h3>
              {name} <span>(Blanca)</span>
            </h3>
            <p className="condiment-specs">
              <span>
                Micros #{microsID} | Symphony #{symphonyID}
              </span>
              <span>Q {price}</span>
              <span>Estado: {status ? 'Activo' : 'Inactivo'}</span>
            </p>
            <Link to={`/condimentos/editar/${condimentID}`}>
              <span className="icon-edit"></span>
            </Link>
          </div>
        </div>
      )}
    </Draggable>
  );
};

