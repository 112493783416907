import React from 'react';

export const CategorySelectItem = ({
  categoryID,
  name,
  description,
  status,
  schedule,
  order,
  image,
  products,
  handleActiveCategory,
  isCategoryAdded,
  setIsCategoryAdded,
}) => {
  // console.log(listCategoriesActivated);

  // const [isCategoryAdded, setIsCategoryAdded] = useState(false);
  //const handlerCategoryAdded = () => {
   // setIsCategoryAdded(!isCategoryAdded);
  //};

  return (
    <div className="select-drag__item-select">
      {name}{' '}
      <button
        type="button"
        disabled={isCategoryAdded}
        onClick={() => {
          handleActiveCategory({
            categoryID,
            name,
            description,
            status,
            schedule,
            order,
            image,
            products,
          });
          // handlerCategoryAdded();
        }}
      >
        +
      </button>
    </div>
  );
};
