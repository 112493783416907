import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

export const CategoryActiveItem = ({
  status,
  setStatus,
  listCondimentActivated,
  setListCondimentActivated
}) => {
  if (!listCondimentActivated || listCondimentActivated.length === 0) {
    return null; // O puedes devolver algún otro elemento si no hay datos para mostrar
  }

  const handlerRemoveCategory = (condimentID, index) => {
    if (listCondimentActivated[index].default === true) {
      (index != 0) && (listCondimentActivated[index - 1].default = true);
      (index === 0 && listCondimentActivated.length > 1) && (listCondimentActivated[1].default = true);
    }

    const list = listCondimentActivated;
    const remove = list.filter((cnd) => cnd.condimentID !== condimentID);
    setListCondimentActivated(remove);
    if (listCondimentActivated.length === 1) setStatus(0);
  };

  const setDefault = async (index) => {
    const list = [...listCondimentActivated];
    const findIndexDefault = await list.findIndex((x) => x.default === true);
    list[findIndexDefault].default = false;
    list[index].default = true;
    setListCondimentActivated(list);
  }

  const overPointer = (index) => {
    document.getElementById('span-' + index).hidden = false;
    document.getElementById('icon-' + index).hidden = true;
  }

  const outPointer = (index, Default) => {
    document.getElementById('span-' + index).hidden = true;
    document.getElementById('icon-' + index).hidden = Default === false;
  }

  return listCondimentActivated.map((cnd, index) => (
    <Draggable
      key={'' + cnd.condimentID}
      draggableId={'' + cnd.condimentID}
      index={index}
    >
      {(provided) => (
        <div
          onMouseOver={() => overPointer(index)}
          onMouseOut={() => outPointer(index, cnd.default)}
          className="select-drag__item-drag row"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className='col-9 pe-0 me-0'>
            <span className="icon-drag mright5"></span>{index + 1}. {cnd.name}
          </div>
          <div className='col-3 pe-3 ps-0 ms-0 pe-'>
            <i id={'icon-' + index} className='btn-default btn-star-on' style={{ float: 'inline-end' }} hidden={!cnd.default}></i>
            <span id={'span-' + index} hidden style={{ float: 'inline-end' }}>
              <i className='btn-default btn-less' onClick={(e) => handlerRemoveCategory(cnd.condimentID, index)}> - </i>
              &nbsp;
              <i className={cnd.default === true ? 'btn-default btn-star-on' : 'btn-default btn-star-off'} onClick={(e) => setDefault(index)}></i>
            </span>
          </div>
        </div>
      )}
    </Draggable>
  ));
};