// Return categoies format

export const prepareCondiments = (condiments = []) => {
  return condiments.map((e) => ({
    condimentID: e.condimentID,
    microsID: e.microsID,
    symphonyID: e.symphonyID,
    name: e.name,
    description: e.description,
    image: e.image,
    price: e.price,
    status: e.status,
    order: e.condimentOrder,
    selected: e.selected,
    nameWeb: e.nameWeb,
    condimentsCount: e.condimentsCount,
    SubCondiment: e.SubCondimentID ? {
      subCondimentID: e.SubCondiment.subCondimentID,
      name: e.SubCondiment.name,
    } : null,
  }));
};
