import React from 'react';
import { Link } from 'react-router-dom';

export const BackRelative = ({ url }) => {
  //   console.log(props);
  return (
    <>
      <div className="back-button back-button--relative">
        <Link to={`${url}`}>
          <span className="icon-arrow-right"></span> Regresar
        </Link>
      </div>
    </>
  );
};
