import { combineReducers } from 'redux';
import { keyReducer } from './keyReducer';
import { authReducer } from '../reducers/authReducer';
import { passReducer } from '../reducers/passReducer';
import { categoryReducer } from './categoryReducer';
import { productReducer } from './productReducer';
import { scheduleReducer } from './scheduleReducer';
import { uiReducer } from './uiReducer';
import { groupCategoryReducer } from './groupCategoryReducer';
import { groupProductReducer } from './groupProductReducer';
import { groupCondimentsReducer } from './groupCondimentsReducer';
import { condimentReducer } from './condimentReducer';
import { usersReducer } from './usersReducer';
import { rolesReducer } from './rolesReducer';
import { storeReducer } from './storeReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  key: keyReducer,
  pass: passReducer,
  schedule: scheduleReducer,
  ui: uiReducer,
  category: categoryReducer,
  categoryGroup: groupCategoryReducer,
  condiment: condimentReducer,
  condimentGroup: groupCondimentsReducer,
  users: usersReducer,
  roles: rolesReducer,
  store: storeReducer,
  product: productReducer,
  productGroup: groupProductReducer,
});
