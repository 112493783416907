import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BackRelative } from '../../../components/Back/BackRelative';
import { DeleteModal } from '../../../components/Schedules/DeleteModal';
import { FormCreateSchedule } from './components/FormCreateSchedule';
import { FormEditSchedule } from './components/FormEditSchedule';
import { schedulesGetAll, requestDispatch } from '../../../actions/schedule';

const initialData = {
  scheduleID: 0,
  initHour: 0,
  initMinutes: 0,
  endHour: 0,
  endMinutes: 0,
  name: {
    value: '',
    error: false
  },
  description: {
    value: '',
    error: false
  },
  // Auxiliar
  formTouched: false,
  formattedInitHour: {
    value: '',
    error: false
  },
  formattedEndHour: {
    value: '',
    error: false
  },
  meridiemEnd: 'AM',
  meridiemInit: 'AM',
};

export const Schedules = () => {
  /***  Selector  ***/
  const dispatch = useDispatch();
  const { key } = useSelector((state) => state.key);
  // const loading = useSelector((state) => state.ui.full_loading);

  // Listado de horarios
  const scheduleListRedux = useSelector((state) => state.schedule.scheduleAll);
  const scheduleRequest = useSelector((state) => state.schedule.scheduleRequest);

  /***  Variables  ***/
  const [scheduleList, setScheduleList] = useState([]);
  const [openFormIndex, setOpenFormIndex] = useState(0);

  //   Modal states
  const [scheduleID, setScheduleID] = useState(0);
  const [scheduleINDEX, setScheduleINDEX] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [resumeModal, setResumeModal] = useState('No podrás revertir esta acción.');
  const [titleModal, setTitleModal] = useState('¿Estás seguro que deseas eliminar este horario?');


  // Eliminar
  const handlerModal = (id, index) => {
    setShowModal(true);
    setScheduleID(+id);
    setScheduleINDEX(+index);
  }

  const deleteSchedules = (id) => {
    const ProductSchedule = {
      scheduleID: +scheduleID,
    }

    const dataProductSchedule = JSON.stringify({
      requestContent: { ProductSchedule }
    });

    dispatch(requestDispatch('delete', 'Deleted', dataProductSchedule, ProductSchedule));
    setShowModal(false);
  };

  /***  useEffects ***/
  // Get all data
  useEffect(() => {
    dispatch(schedulesGetAll('all', '{}'));
  }, [scheduleRequest, dispatch]);

  useEffect(() => {
    setScheduleList(scheduleListRedux);
  }, [scheduleListRedux]);

  return (
    <>
      <div className="container">
        <BackRelative url="/categorias" />
        <h1 className="text-center">Horarios</h1>
        <div className="schedules">
          <h3>Crear nuevo horario</h3>
          <div className="schedules__first">
            <div className="m-0">
              {/* Formulario Crear */}
              <FormCreateSchedule
                initialData={initialData}
              />

              {/* Listado */}
              <div className="row schedules__list">
                <div className="col-12">
                  <h3>Horarios creados</h3>
                </div>
                {scheduleList && scheduleList?.length <= 0 ? (
                  <div className="col-12 ptop15">
                    <p>
                      Cuando tengas horarios creados, podrás verlos y editarlos aquí.
                    </p>
                  </div>
                ) : (
                  <div className="col-12 schedules__exists ptop15">
                  <>
                    {scheduleList?.map((schedule, index) => (
                      <FormEditSchedule
                        index={index}
                        schedule={schedule}
                        key={schedule.scheduleID}
                        handlerModal={handlerModal}
                        scheduleID={schedule.scheduleID}
                        initialData={initialData}
                        openFormIndex={openFormIndex}
                        setOpenFormIndex={setOpenFormIndex}
                      />
                    ))}
                  </>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={showModal}
        title={titleModal}
        resume={resumeModal}
        setShow={setShowModal}
        id={scheduleID}
        handleDelete={deleteSchedules}
        // isLoading={loading}
        withContent={false}
      />
    </>
  );
};
