// Return products format

export const prepareProductsSizes = (groupsSize = []) => {
  return groupsSize.map((e) => ({
    sizeID: e.sizeID,
    //microsID: e.microsID,
    sizeAdm: e.sizeAdm,
    sizeApp: e.sizeApp,
    //minimun_selection: e.minimumSelection,
    //maximun_selection: e.maximumSelection,
    //status: e.status,
    //nameWeb: e.nameWeb,
    sizeType: e.sizeType,
    //products: e.productsCount,
  }));
};

export const prepareProductsSizesByGroup = (productSizes = []) => {
  let mealSizes = [];
  let drinkSizes = [];
  productSizes.forEach((productSize, index) => {
    if (productSize?.sizeType?.typeID == 0) {
      drinkSizes = [...drinkSizes, productSize]
    } else {
      mealSizes = [...mealSizes, productSize]
    }
  });

  return [
    {
      type: 'drinkSizes',
      name: 'Para bebidas',
      data: drinkSizes
    },
    {
      type: 'mealSizes',
      name: 'Para comidas',
      data: mealSizes
    }
  ]

  // return { mealSizes, drinkSizes }
  //   {
  //     "sizeID": 1,
  //     "description": "Bebida 12 Onzas",
  //     "sizeAdm": "12",
  //     "sizeApp": "12oz",
  //     "sizeType": {
  //         "typeID": 0,
  //         "description": "Tamaño Bebidas"
  //     }
  // }
};
