import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Accordion, Button, Icon, Input } from 'semantic-ui-react';
import { getAllCategories } from '../../../actions/category';
import {
  initialUpdateGroupReorderCategories,
  startGetAllGroups,
} from '../../../actions/groups';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { LoaderData } from '../../../components/Loader/LoaderData';
import { CategoryByGroup } from './components/CategoryByGroup';

export const Categories = () => {
  // Dispatch
  const dispatch = useDispatch();

  // Get key
  const { key } = useSelector((state) => state.key);

  // Get groups
  // const groups = useSelector((state) => state.categoryGroup);
  const groups = useSelector((state) => state.categoryGroup.list);

  console.log({ groups });

  const { schedules } = useSelector((state) => state.schedule);

  // Get categories
  const listCategories = useSelector((state) => state.category.list);
  console.log({ listCategories });

  // Get all Categories
  useEffect(() => {
    const data = JSON.stringify({ "info": {} });
    dispatch(getAllCategories(data, key));
    dispatch(startGetAllGroups(data, key));
  }, [dispatch, key]);

  // Tab states
  const [tabIndex, setTabIndex] = useState(0);

  // Accordion
  const [activeAccordion, setActiveAccordion] = useState(0);

  const handleAccordion = (index) => {
    const newIndex = activeAccordion === index ? -1 : index;
    setActiveAccordion(newIndex);
  };

  // const [orderCategories, setOrderCategories] = useState(groups);

  const handleOnDrag = (result) => {
    var group = groups.filter(function (categoryGroup) {
      return categoryGroup.categoriesGroupID === +result.destination.droppableId;
    });

    const items = group.map((category) => category.categories);

    if (items[0]) {
      const [reorderedItems] = items[0].splice(result.source.index, 1);
      items[0].splice(result.destination.index, 0, reorderedItems);

      items[0].map((item, index) => {
        items[0][index].order = index;
      });

      var dataGroupUpdate = JSON.stringify({
        requestContent: {
          categoriesGroup: {
            categoriesGroupID: group[0].categoriesGroupID,
            name: group[0].name,
            description: group[0].description,
            categories: items[0],
          }
        }
      });

      const groupUpdate = {
        categoriesGroupID: group[0].categoriesGroupID,
        name: group[0].name,
        description: group[0].description,
        categories: items[0],
      };

      dispatch(
        initialUpdateGroupReorderCategories(dataGroupUpdate, key, groupUpdate)
      );
    } else {
      console.error("No hay categorías en este grupo.");
    }
  };

  // the search result groups

  const [name, setName] = useState('');
  const [foundGroups, setFoundGroups] = useState(groups);
  const filterGroups = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = groups.filter((item) => {
        return item.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundGroups(results);
    } else {
      setFoundGroups(groups);
    }
    setName(keyword);
  };

  useEffect(() => {
    setFoundGroups(groups);
  }, [groups]);

  // the search result categories

  const [nameCategory, setNameCategory] = useState('');
  const [foundCategories, setFoundCategories] = useState(listCategories);
  const filterCategories = (e) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = listCategories.filter((item) => {
        return item.name.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundCategories(results);
    } else {
      setFoundCategories(listCategories);
    }
    setNameCategory(keyword);
  };

  useEffect(() => {
    setFoundCategories(listCategories);
  }, [listCategories]);

  const onSearch = (event) => {
    event.preventDefault();
  };

  return (

    <>

      {listCategories ? (
        <>
          {listCategories.length >= 1 ? (
            <>
              <h1 className="text-center m-0 pbottom30">Categorías</h1>
              <div className="standard-wrap">
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => setTabIndex(index)}
                >
                  <TabList>
                    <Tab>Grupos de categorías</Tab>
                    <Tab>Categorías individuales</Tab>
                  </TabList>
                  {/* Grupos de categorias */}
                  <TabPanel>
                    {groups ? (
                      <>
                        <div className="top-filters">
                          <div className="top-filters__left top-filters__left--small">
                            <form onSubmit={onSearch}>
                              <Input
                                className="search-input"
                                list="categories"
                                placeholder="Buscar grupo de categorías..."
                                name="textSearch"
                                onChange={filterGroups}
                                value={name}
                              />
                              <Button>Buscar</Button>
                            </form>
                          </div>
                          <div className="top-filters__right top-filters__right--large">
                            <Link
                              className="cta cta--secondary-plain cta--tiny margin0"
                              to="/horarios"
                            >
                              Editar horarios
                            </Link>
                            <Link
                              className="cta cta--widthAuto margin0"
                              to="/categorias/crear-grupo"
                            >
                              + Crear grupo de categorías
                            </Link>
                          </div>
                        </div>
                        <div className="accordion-module">
                          {foundGroups ? (
                            <>
                              {foundGroups.length > 0 ? (
                                foundGroups.map((group, index) => (
                                  <Accordion key={index}>
                                    <Accordion.Title
                                      active={activeAccordion === index}
                                      onClick={() => handleAccordion(index)}
                                    >
                                      {group.name}
                                      <span>
                                        Ver más
                                        <Icon name="dropdown" />
                                      </span>
                                    </Accordion.Title>
                                    <Accordion.Content
                                      active={activeAccordion === index}
                                    >
                                      <div className="categories-list">
                                        <DragDropContext
                                          onDragEnd={handleOnDrag}
                                        >
                                          <Droppable
                                            droppableId={'' + group.categoriesGroupID}
                                          >
                                            {(provided) => (
                                              <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                              >
                                                {group.categories?.map(
                                                  (cat, index) => {
                                                    return (
                                                      <CategoryByGroup
                                                        cat={cat}
                                                        key={index}
                                                        index={index}
                                                        image={cat.image}
                                                        name={cat.name}
                                                        status={cat.status}
                                                        productsCount={cat.productsCount}
                                                        categoryID={cat.categoryID}
                                                        order={cat.order}
                                                        condimentGroupID={index}
                                                        schedule={cat.schedule}
                                                        schedules={schedules}
                                                      />
                                                    );
                                                  }
                                                )}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </div>
                                      <div className="text-right">
                                        <Link
                                          to={`/categorias/editar-grupo/${group.categoriesGroupID}`}
                                          className="cta cta--tiny cta--h30 cta--secondary-light margin0"
                                        >
                                          <span className="icon-edit mright5"></span>
                                          Editar grupo
                                        </Link>
                                      </div>
                                    </Accordion.Content>
                                  </Accordion>
                                ))
                              ) : (
                                <h1>Sin resultados!</h1>
                              )}
                            </>
                          ) : (
                            <LoaderData />
                          )}
                        </div>
                      </>
                    ) : (
                      <EmptyState
                        title="Categorías"
                        text={`¡Ya tienes categorías creadas! Ahora puedes agruparlas aquí.`}
                        titleButton="+ Crear grupo de categorías"
                        linkButton="/categorias/crear-grupo"
                        hasSchedules={schedules?.length > 1 ? true : false}
                        titleButtonPlain="Editar horarios"
                        linkButtonPlain="/horarios"
                        titleButtonSolid="+ Crear grupo de categorías"
                        linkButtonSolid="/categorias/crear-grupo"
                      />
                    )}
                  </TabPanel>
                  {/* Categorias */}
                  <TabPanel>
                    <div className="top-filters">
                      <div className="top-filters__left">
                        <form onSubmit={onSearch}>
                          <Input
                            className="search-input"
                            list="categories"
                            placeholder="Buscar categorías..."
                            onChange={filterCategories}
                            value={nameCategory}
                          />
                          <Button>Buscar</Button>
                        </form>
                      </div>
                      <div className="top-filters__right">
                        <Link
                          className="cta cta--secondary-plain cta--tiny margin0"
                          to="/horarios"
                        >
                          Editar horarios 
                        </Link>
                        <Link
                          className="cta cta--tiny margin0"
                          to="/categorias/crear"
                        >
                          + Crear categoría
                        </Link>
                      </div>
                    </div>
                    <div className="categories-list">
                      {foundCategories ? (
                        <>
                          {foundCategories.length >= 1 ? (
                            <>
                              {foundCategories.map((cat, index) => {
                                return (
                                  <div className="category-item" key={index}>
                                    <div className="category-item__left">
                                      <img src={cat.image} />
                                    </div>
                                    <div className="category-item__right">
                                      <h3>{cat.name}</h3>
                                      <p>
                                        <span>
                                          Estado:{' '}
                                          {cat.status ? 'Activo' : 'Inactivo'}
                                        </span>{' '}
                                        |{' '}
                                        <span>
                                          Horario: {cat.schedule.formattedSchedule}{' '}
                                          {/* Horario: {cat.schedule.name}{' '} */}
                                          {/* {schedules?.map(function (item) {
                                            if (item.id === cat.schedule.scheduleID) {
                                              return `${item.name} (${item.from} ${item.to})`;
                                            }
                                          })} */}
                                        </span>
                                      </p>
                                      <span>
                                        {cat.productsCount ? cat.productsCount : '0'}{' '} productos
                                      </span>
                                      <Link
                                        to={`/categorias/editar/${cat.categoryID}`}
                                      >
                                        <span className="icon-edit"></span>
                                      </Link>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <h1>Sin resultados!</h1>
                          )}
                        </>
                      ) : (
                        <LoaderData />
                      )}
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </>
          ) : (
            <>
              <EmptyState
                title="Categorías"
                text={`${schedules?.length > 1
                    ? '¡Ya tienes horarios creados! Ahora puedes crear categorías para verlas aquí.'
                    : 'No hay categorías creadas. Comienza creando un horario para poder asignarle un horario a cada categoría.'
                  } `}
                titleButton="+ Crear horario"
                linkButton="/horarios"
                hasSchedules={schedules?.length > 1 ? true : false}
                titleButtonPlain="Editar horarios"
                linkButtonPlain="/horarios"
                titleButtonSolid="+ Crear categoría"
                linkButtonSolid="/categorias/crear"
              />
            </>
          )}
        </>
      ) : (
        <LoaderData />
      )}
    </>
  );
};
