import { types } from '../types/types';

export const revalidateKey = (key) => {
  return (dispatch) => {
    if (key) {
      dispatch(updateKey(key));
      // console.log('keyToUpdate', key);
      // console.log('sessionId', key)
      localStorage.setItem('sessionId', key);
    }else{
      console.error('key is undefined');
    }
    
  };
};

const updateKey = (key) => {
  return {
    type: types.uiKey,
    payload: key,
  };
};
